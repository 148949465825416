import request from '@/libs/request';

export const GetTags = data => request('MdmAdmin/DataTagService/GetTags', data);
export const CreateTag = data => request('MdmAdmin/DataTagService/CreateTag', data);
export const EditTag = data => request('MdmAdmin/DataTagService/EditTag', data);

// 获取tag详情页数据
export const GetTagDetail = data => request('MdmAdmin/DataTagService/GetTagDetail', data);
export const GetTagValues = data => request('MdmAdmin/DataTagService/GetTagValues', data);
export const GetTagSyncLog = data => request('MdmAdmin/DataTagService/GetTagSyncLog', data);
export const ShelfTag = data => request('MdmAdmin/DataTagService/ShelfTag', data);
export const ExecTagSync = data => request('MdmAdmin/DataTagService/ExecTagSync', data);
export const GetTagInfosByValues = data =>
  request('MdmAdmin/DataTagService/GetTagInfosByValues', data);

// 创建、编辑、删除标签值
export const AddTagValues = data => request('MdmAdmin/DataTagService/AddTagValues', data);
export const EditTagValues = data => request('MdmAdmin/DataTagService/EditTagValues', data);
export const DeleteTagValues = data => request('MdmAdmin/DataTagService/DeleteTagValues', data);
export const GetTagValueDataCount = data =>
  request('MdmAdmin/DataTagService/GetTagValueDataCount', data);

// 获取全部类型细分
export const GetAllOrgTypes = data => request('MdmAdmin/DataService/GetAllOrgTypes', data);

// 删除标签
export const DeleteTag = data => request('MdmAdmin/DataTagService/DeleteTag', data);
