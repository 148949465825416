export default [
  {
    title: '基础信息',
    itemList: [
      { name: '性别', property: 'gender', data: 'gender', edit: false, editType: '' },
      { name: '描述', property: 'description', data: 'description', edit: false, editType: '' },
    ],
  },
  {
    title: '工作信息',
    itemList: [
      { name: '所属机构名', property: 'org_name', data: 'org_name', edit: false, editType: '' },
      { name: '所在科室', property: 'dept_name', data: 'dept_name', edit: false, editType: '' },
      {
        name: '专业职称',
        property: 'professional_title',
        data: 'professional_title',
        edit: false,
        editType: '',
      },
      {
        name: '学术职称',
        property: 'academic_title',
        data: 'academic_title',
        edit: false,
        editType: '',
      },
      {
        name: '行政职务',
        property: 'administrative_title',
        data: 'administrative_title',
        edit: false,
        editType: '',
      },
    ],
  },
  {
    title: '教育信息',
    itemList: [
      {
        name: '学历',
        property: 'education_degree',
        data: 'education_degree',
        edit: false,
        editType: '',
      },
      {
        name: '毕业学校',
        property: 'graduated_school',
        data: 'graduated_school',
        edit: false,
        editType: '',
      },
      {
        name: '毕业时间',
        property: 'graduation_time',
        data: 'graduation_time',
        edit: false,
        editType: '',
      },
    ],
  },
  {
    title: '卫健委信息',
    itemList: [
      {
        name: '卫健委执业证书编码',
        property: 'nhc_license_number',
        data: 'nhc_license_number',
        edit: false,
        editType: '',
      },
      {
        name: '卫健委主要执业机构',
        property: 'nhc_org_name',
        data: 'nhc_org_name',
        edit: false,
        editType: '',
      },
      {
        name: '卫健委医师级别',
        property: 'nhc_professional_level',
        data: 'nhc_professional_level',
        edit: false,
        editType: '',
      },
      {
        name: '卫健委执业类别',
        property: 'nhc_professional_type',
        data: 'nhc_professional_type',
        edit: false,
        editType: '',
      },
      {
        name: '卫健委执业地点',
        property: 'nhc_location',
        data: 'nhc_location',
        edit: false,
        editType: '',
      },
      {
        name: '卫健委执业范围',
        property: 'nhc_scope',
        data: 'nhc_scope',
        edit: false,
        editType: '',
      },
      {
        name: '卫健委发证(批准)机关',
        property: 'nhc_issuing_authority',
        data: 'nhc_issuing_authority',
        edit: false,
        editType: '',
      },
      {
        name: '卫健委多点执业',
        property: 'nhc_multi_sites',
        data: 'nhc_multi_sites',
        edit: false,
        editType: '',
      },
    ],
  },
  {
    title: '地理位置',
    itemList: [
      { name: '省份', property: 'province', data: 'province', edit: false, editType: '' },
      { name: '城市', property: 'city', data: 'city', edit: false, editType: '' },
      { name: '区县', property: 'district', data: 'district', edit: false, editType: '' },
    ],
  },
  {
    title: '清洗备注',
    itemList: [
      { name: '数据来源', property: 'source', data: 'source', edit: false, editType: 'select' },
      { name: '原始租户ID', property: 'tenant_id', data: 'tenant_id', edit: false, editType: '' },
      { name: '原始ID', property: 'data_id', data: 'data_id', edit: false, editType: '' },
      { name: '备注', property: 'comment', data: 'comment', edit: false, editType: '' },
    ],
  },
  {
    title: '系统字段',
    itemList: [
      { name: '创建人', property: 'create_user', data: 'create_user', edit: false, editType: '' },
      { name: '创建时间', property: 'create_time', data: 'create_time', edit: false, editType: '' },
      { name: '更新人', property: 'update_user', data: 'update_user', edit: false, editType: '' },
      { name: '更新时间', property: 'update_time', data: 'update_time', edit: false, editType: '' },
    ],
  },
];
