export default [
  {
    title: '性别',
    property: 'gender',
    value: [
      { code: '1', label: '女' },
      { code: '2', label: '男' },
      { code: '0', label: '未知' },
    ],
  },
  {
    title: 'HCP类型',
    property: 'hcp_type',
    value: [
      { code: 'N', label: '护师(士)' },
      { code: 'P', label: '药师(士)' },
      { code: 'Unknown', label: '未知' },
      { code: 'T', label: '技师(士)' },
      { code: 'D', label: '医师(士)' },
      { code: 'Other', label: '其他技术人员' },
    ],
  },
  {
    title: 'HCP状态',
    property: 'hcp_status',
    value: [
      { code: 'active', label: '有效' },
      { code: 'inactive', label: '无效' },
      { code: 'unknown', label: '未知' },
    ],
  },
  {
    title: '专业职称',
    property: 'professional_title',
    value: [
      { label: '副主任检验师', code: 'T_VCCLE' },
      { label: '美容师', code: 'O_C' },
      { label: '主管药师', code: 'P_PIC' },
      { label: '副主任医师', code: 'D_VCD' },
      { label: '副主任护师', code: 'N_VCN' },
      { label: '主任技师', code: 'T_CT' },
      { label: '主任药师', code: 'P_CP' },
      { label: '副主任技师', code: 'T_VCT' },
      { label: '技士', code: 'T_AT' },
      { label: '药士', code: 'P_AP' },
      { label: '主任护师', code: 'N_CN' },
      { label: '主任检验师', code: 'T_CCLE' },
      { label: '检验师', code: 'T_CLE' },
      { label: '主治医师', code: 'D_ATD' },
      { label: '药师', code: 'P_P' },
      { label: '营养师', code: 'O_DIET' },
      { label: '医士', code: 'D_AD' },
      { label: '统计师', code: 'O_STAT' },
      { label: '其他', code: 'Other' },
      { label: '未知', code: 'Unknown' },
      { label: '副主任药师', code: 'P_VCP' },
      { label: '康复治疗师', code: 'O_TP' },
      { label: '住院医师', code: 'D_RD' },
      { label: '技师', code: 'T_T' },
      { label: '护士', code: 'N_AN' },
      { label: '主管护师', code: 'N_NIC' },
      { label: '主任医师', code: 'D_CD' },
      { label: '主管技师', code: 'T_TIC' },
      { label: '护师', code: 'N_N' },
    ],
  },
  {
    title: '是否存疑',
    property: 'in_doubt',
    value: [
      { code: '1', label: '存疑' },
      { code: '2', label: '不存疑' },
    ],
  },
  {
    title: '数据来源',
    property: 'source',
    value: [
      { label: '默认', code: '0' },
      { label: '腾讯地图', code: '4' },
      { label: '卫健委', code: '7' },
      { label: '百度地图', code: '5' },
      { label: '高德地图', code: '3' },
      { label: '天眼查', code: '2' },
      { label: '企查查', code: '1' },
      { label: '医院', code: '6' },
      { label: 'MedMD管理端创建', code: '8' },
    ],
  },
];
