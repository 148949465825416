export default [
  {
    title: '基础信息',
    itemList: [
      {
        name: '医生姓名',
        property: 'name',
        data: 'name',
        edit: true,
        editType: 'input',
        required: true,
      },
      {
        name: '姓名拼音',
        property: 'pinyin',
        data: 'pinyin',
        edit: true,
        editType: 'input',
        // disable: true,
      },
      {
        name: '性别',
        property: 'gender',
        data: 'gender',
        edit: true,
        editType: 'select',
        required: true,
      },
      {
        name: '所属机构名称',
        property: 'hco_org',
        data: 'hco_org',
        edit: false,
        editType: 'lenovo',
        required: true,
        showType: 'link',
      },
      {
        name: 'HCP类型',
        property: 'hcp_type',
        data: 'D',
        edit: true,
        editType: 'select',
        required: true,
        disable: true,
      },
      {
        name: 'HCP状态',
        property: 'hcp_status',
        data: 'active',
        edit: true,
        editType: 'select',
        required: true,
        disable: true,
      },
    ],
  },
  {
    title: '工作信息',
    itemList: [
      {
        name: '挂牌科室	',
        property: 'listed_dept_name',
        data: 'listed_dept_name',
        edit: true,
        editType: 'input',
        required: true,
      },
      {
        name: '标准科室',
        property: 'standard_dept_all',
        data: 'standard_dept_name',
        edit: true,
        editType: 'departmentCascade',
      },
      {
        name: '学术头衔',
        property: 'academic_title',
        data: 'academic_title',
        edit: true,
        editType: 'select',
      },
      {
        name: '专业职称',
        property: 'professional_title',
        data: 'professional_title',
        edit: true,
        editType: 'select',
      },
      {
        name: '行政职务',
        property: 'administrative_title',
        data: 'administrative_title',
        edit: true,
        editType: 'select',
      },
    ],
  },
  {
    title: '卫健委信息',
    itemList: [
      {
        name: '执业许可证编码',
        property: 'nhc_license_number',
        data: 'nhc_license_number',
        edit: false,
        editType: 'input',
      },
      {
        name: '颁发机构',
        property: 'nhc_issuing_authority',
        data: 'nhc_issuing_authority',
        edit: true,
        editType: 'input',
      },
      {
        name: '医师级别',
        property: 'nhc_professional_level',
        data: 'nhc_professional_level',
        edit: true,
        editType: 'input',
      },
      {
        name: '执业类别',
        property: 'nhc_professional_type',
        data: 'nhc_professional_type',
        edit: true,
        editType: 'input',
      },
      {
        name: '执业地点',
        property: 'nhc_location',
        data: 'nhc_location',
        edit: true,
        editType: 'input',
      },
      { name: '执业范围', property: 'nhc_scope', data: 'nhc_scope', edit: true, editType: 'input' },
      {
        name: '多点执业数量',
        property: 'nhc_multi_sites',
        data: 'nhc_multi_sites',
        edit: false,
        editType: 'number',
      },
      {
        name: '证书颁发日期',
        property: 'nhc_issue_date',
        data: 'nhc_issue_date',
        edit: true,
        editType: 'date',
      },
    ],
  },
  // {
  //   title: '部门归一',
  //   itemList: [
  //     {
  //       name: '未名企鹅编码',
  //       property: 'wmqe_number',
  //       data: 'wmqe_number',
  //       edit: false,
  //       editType: '',
  //     },
  //   ],
  // },
  {
    title: '清洗备注',
    itemList: [
      {
        name: '是否存疑',
        property: 'in_doubt',
        data: 'in_doubt',
        edit: false,
        editType: 'select',
      },
      {
        name: '存疑原因',
        property: 'doubt_reason',
        data: 'doubt_reason',
        edit: false,
        editType: '',
      },
      { name: '数据来源', property: 'source', data: 'source', edit: false, editType: 'select' },
      { name: '备注评论', property: 'comment', data: 'comment', edit: true, editType: 'input' },
      { name: '质量负责人', property: 'qa_persons', data: 'qa_persons', edit: false, editType: '' },
    ],
  },
  {
    title: '系统字段',
    itemList: [
      {
        name: '版本',
        property: 'record_version',
        data: 'record_version',
        edit: false,
        editType: '',
      },
      { name: '创建人', property: 'create_user', data: 'create_user', edit: false, editType: '' },
      { name: '创建时间', property: 'create_time', data: 'create_time', edit: false, editType: '' },
      { name: '更新人', property: 'update_user', data: 'update_user', edit: false, editType: '' },
      { name: '更新时间', property: 'update_time', data: 'update_time', edit: false, editType: '' },
    ],
  },
];
