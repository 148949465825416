export default [
  {
    title: '基础信息',
    itemList: [
      { name: '机构类型', property: 'org_type', data: 'org_type', edit: false, editType: '' },
      { name: '等级等次', property: 'grade', data: 'grade', edit: false, editType: '' },
      { name: '卫健委编码', property: 'nhc_code', data: 'nhc_code', edit: false, editType: '' },
      { name: '描述', property: 'description', data: 'description', edit: false, editType: '' },
      {
        name: '机构别名1',
        property: 'alias_name1',
        data: 'alias_name1',
        edit: false,
        editType: '',
      },
      {
        name: '机构别名2',
        property: 'alias_name2',
        data: 'alias_name2',
        edit: false,
        editType: '',
      },
    ],
  },
  {
    title: '地理位置',
    itemList: [
      { name: '省份', property: 'province', data: 'province', edit: false, editType: '' },
      { name: '城市', property: 'city', data: 'city', edit: false, editType: '' },
      { name: '区县', property: 'district', data: 'district', edit: false, editType: '' },
      { name: '地址', property: 'address', data: 'address', edit: false, editType: '' },
    ],
  },
  {
    title: '清洗备注',
    itemList: [
      { name: '数据来源', property: 'source', data: 'source', edit: false, editType: 'select' },
      { name: '原始租户ID', property: 'tenant_id', data: 'tenant_id', edit: false, editType: '' },
      { name: '原始ID', property: 'data_id', data: 'data_id', edit: false, editType: '' },
      { name: '备注', property: 'comment', data: 'comment', edit: false, editType: '' },
    ],
  },
  {
    title: '系统字段',
    itemList: [
      { name: '创建人', property: 'create_user', data: 'create_user', edit: false, editType: '' },
      { name: '创建时间', property: 'create_time', data: 'create_time', edit: false, editType: '' },
      { name: '更新人', property: 'update_user', data: 'update_user', edit: false, editType: '' },
      { name: '更新时间', property: 'update_time', data: 'update_time', edit: false, editType: '' },
    ],
  },
];
