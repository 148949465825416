<template>
  <div class="datadetail" v-loading="loading">
    <!-- 顶部数据与操作 -->
    <div class="datadetail-head" v-if="!this.$route.query.editDCR">
      <div
        class="datadetail-head-mid"
        v-if="this.$route.query.type !== 'new' && this.$route.query.source !== 'doctor'"
      >
        <p
          v-if="
            this.$route.query.source !== 'mid-org' &&
              this.$route.query.source !== 'mid-doc' &&
              this.$route.query.type === 'edit'
          "
        >
          编辑{{ formData.hco_type }}
        </p>
        <p v-else>{{ this.$route.query.name || '' }}</p>
        <div class="datadetail-head-mid-tag">
          <div
            class="datadetail-title-id"
            v-if="this.$route.query.source !== 'mid-org' && this.$route.query.source !== 'mid-doc'"
          >
            机构编码：{{ formData.hco_code || formData.hcp_code }}
          </div>
          <div
            class="datadetail-title-id"
            v-if="this.$route.query.source === 'mid-org' || this.$route.query.source === 'mid-doc'"
          >
            系统ID：{{ id }}
          </div>
        </div>
      </div>
      <div
        class="datadetail-head-mid"
        v-if="this.$route.query.type !== 'new' && this.$route.query.source === 'doctor'"
      >
        <p>姓名：{{ this.$route.query.name || '' }}</p>
        <div class="datadetail-head-mid-tag">
          <div class="datadetail-title-id">
            人员编码：{{ formData.hco_code || formData.hcp_code }}
          </div>
        </div>
      </div>
      <div class="datadetail-head-tit" v-if="this.$route.query.type === 'new'">
        {{ title }}
      </div>

      <div class="datadetail-head-right">
        <el-button
          class="btn"
          v-if="
            this.$route.query.type === 'preview' &&
              this.$route.query.source === 'doctor' &&
              formData.is_kol !== '0'
          "
          @click="handleToDoctorImage"
        >
          360画像
        </el-button>
        <el-button
          class="btn"
          @click="HandleDrawer"
          v-if="
            this.$route.query.type === 'preview' &&
              this.$route.query.source !== 'mid-doc' &&
              this.$route.query.source !== 'mid-org' &&
              role !== 3
          "
        >
          历史版本
        </el-button>
        <el-button
          class="btn"
          @click="handleEdit"
          v-if="
            this.$route.query.type === 'preview' &&
              this.$route.query.source !== 'mid-doc' &&
              this.$route.query.source !== 'mid-org' &&
              role !== 3
          "
        >
          编辑
        </el-button>
        <el-button
          class="btn"
          @click="handleDel"
          v-if="
            this.$route.query.type === 'preview' &&
              this.$route.query.source !== 'mid-doc' &&
              this.$route.query.source !== 'mid-org' &&
              role !== 3
          "
        >
          删除
        </el-button>

        <!-- 历史版本详情展示 -->
        <div
          v-if="
            this.$route.query.type === 'history' &&
              this.$route.query.source !== 'mid-doc' &&
              this.$route.query.source !== 'mid-org' &&
              role !== 3
          "
          class="history_info"
        >
          当前正在查看：{{ `V.${historyFromData.record_version}.0` }}&nbsp;&nbsp;&nbsp;{{
            historyFromData.create_time
          }}
        </div>
        <el-button
          class="btn"
          @click="handleToCurrentVersion"
          v-if="
            this.$route.query.type === 'history' &&
              this.$route.query.source !== 'mid-doc' &&
              this.$route.query.source !== 'mid-org' &&
              role !== 3
          "
        >
          回到最新版本
        </el-button>
      </div>
    </div>

    <!-- 医生标签展示 -->

    <div
      id="info-tags"
      :style="{ width: screenWidth + 'px' }"
      v-if="this.$route.query.type === 'preview'"
    >
      <el-popover
        class="item"
        v-for="item in tagData"
        :key="item.id"
        placement="bottom"
        trigger="click"
        width="240"
      >
        <div class="detail-item">
          <span class="title">分类：</span>
          <span class="data">{{ item.type2 }}</span>
        </div>
        <div class="detail-item">
          <span class="title">创建人：</span>
          <span class="data">{{ item.create_user }}</span>
        </div>
        <div class="detail-item">
          <span class="title">更新时间：</span>
          <span class="data">{{ item.update_time }}</span>
        </div>
        <div class="detail-item">
          <span class="title">生成方式：</span>
          <span class="data">
            {{
              item.create_way === 1 ? '规则生成' : item.create_way === 2 ? '算法生成' : '人工录入'
            }}
          </span>
        </div>
        <div class="detail-item">
          <span class="title">更新方式：</span>
          <span class="data">{{ item.update_way | formatTagUpdateWay }}</span>
        </div>
        <div>
          <el-button type="text" @click="handleGoTag(item)" v-if="systemEnv === '1'">
            查看标签详情
          </el-button>
        </div>
        <el-button slot="reference" class="tag_btn_show">
          <span class="tag_contents">
            <el-tooltip class="item" effect="dark" placement="top" popper-class="tag_tip">
              <div slot="content">
                {{ item.name }}：{{ item.values.map(v => v.name).join(',') }}
              </div>
              <el-button>{{ item.name }}：{{ item.values.map(v => v.name).join(',') }}</el-button>
            </el-tooltip>
          </span>
        </el-button>
      </el-popover>
      <div v-if="show">
        <div v-if="showBtnZK && showDivZK" class="expansion" @click="restoreTags">展开</div>
        <div v-else class="up" @click="cuttingTags">收起</div>
      </div>
    </div>

    <!-- DCR编辑头部展示 -->
    <div class="datadetail-head-dcr" v-if="this.$route.query.editDCR">
      <div class="back" @click="handleGoBack">
        <div class="back-btn">
          <img src="./img/back.svg" alt="" />
          <span>返回</span>
        </div>
        <div class="title">编辑DCR</div>
      </div>
      <div class="trip">
        <img src="./img/info.svg" alt="" />
        <p>
          当前DCR类型为“{{
            this.$route.query.editDCR
          }}”，您可以对当前DCR的明细进行编辑修正，点击保存后本次DCR将以更新后版本为准。
        </p>
      </div>
    </div>

    <el-dialog
      title="确认删除吗"
      :visible.sync="dialogFormVisible"
      width="400px"
      class="deleteDataModel"
    >
      <span slot="title" :style="{ fontSize: '14px', color: '#1C2028' }">
        <i class="el-icon-warning" :style="{ fontSize: '18px', color: '#FA9600' }" />
        确认删除吗
      </span>
      请填写删除原因
      <el-input v-model="reason"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-cancelBtn" @click="dialogFormVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="handleDelData">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 预览模式 -->
    <el-form
      v-if="this.$route.query.type === 'preview' && !loading && authLoading"
      v-loading="loading"
    >
      <div v-for="(block, indexb) in config" :key="`block${indexb}`">
        <div v-if="block.show !== 'no'" class="datadetail-block">
          <div class="datadetail-block-title">{{ block.title }}</div>

          <!-- 证照信息 -->
          <div
            class="license"
            v-if="
              block.title === '证照信息' &&
                $route.query.type === 'preview' &&
                $route.query.source !== 'doctor' &&
                $route.query.source !== 'mid-org' &&
                $route.query.source !== 'mid-doc'
            "
          >
            <div class="table">
              <el-table
                empty-text="暂无相关信息"
                :data="licenseTable"
                :header-cell-style="{ height: '46px', background: '#F2F3F8' }"
              >
                <el-table-column prop="name" label="证书名称" width="180"></el-table-column>
                <el-table-column prop="license_code" label="证书编号" width="180"></el-table-column>
                <el-table-column prop="certification_date" label="认证日期"></el-table-column>
                <el-table-column prop="expire_date" label="有效期"></el-table-column>
                <el-table-column prop="issuing_authority" label="认证机构"></el-table-column>
              </el-table>
            </div>
          </div>

          <!-- 附带hover后显示编辑入口的组件 完成编辑时会触发submit -->
          <template v-for="(item, indexi) in block.itemList">
            <!-- 机构详情 -->
            <div
              :key="`${block.title}item${indexi}`"
              class="datadetail-block-item-detail"
              v-if="item.property !== 'hco_code' && item.name !== '机构名称'"
            >
              <eyaoDetailInput
                :edit-valid="item.edit"
                :edit-type="item.editType"
                :show-type="item.showType"
                :title="item.name"
                :init-value="formData[item.property]"
                :property="item.property"
                :extra="item.extra"
                :required="item.required"
                :options="options"
                @submit="submit"
                @handleSuperiorOrg="handleSuperiorOrg"
                :auths="`${block.show}`"
              />
            </div>
          </template>
        </div>
      </div>
      <!-- 相关医生板块 -->
      <div
        class="doc-tab"
        v-if="
          this.$route.query.type === 'preview' &&
            (this.$route.query.source === 'hospital' ||
              this.$route.query.source === 'hospital-ji' ||
              this.$route.query.source === 'hospital-pro' ||
              this.$route.query.source === 'hospital-other')
        "
      >
        <div class="top">
          <p class="title">相关医生</p>
          <div class="top-btn">
            <el-button type="primary" class="new-btn" @click="handleNewDoc" v-if="role !== 3">
              新建数据
            </el-button>

            <div class="action-right">
              <el-button icon="el-icon-refresh-right" @click="getDoctorList"></el-button>
            </div>
          </div>
        </div>

        <div class="data-tabel">
          <div v-if="filterList.length > 0" class="datalist-title-filter">
            <div
              v-for="(item, index) in filterList"
              :key="index"
              class="datalist-title-filter-item"
            >
              {{ getLabel(item.name) }}: {{ item.label }}
              <i class="el-icon-close" @click="deleteFilterItem(item.name)"></i>
            </div>
            <el-button
              v-if="filterList.length"
              class="datalist-title-button"
              type="text"
              @click="deleteAllFilter"
            >
              重置
            </el-button>
          </div>
          <eyao-table
            ref="table"
            :table-data="docrotTableData"
            :total-num="doctorNum"
            :loading="doctorListLoading"
            @filter-change="filterChange"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :select-page-size="10"
          >
            <el-table-column
              v-for="column in doctorConfig"
              :key="`${$route.query.type}-${column.property}`"
              :prop="column.property"
              :column-key="column.property"
              :width="column.width"
              :label="column.title"
              :sortable="column.sort"
            >
              <template slot="header" slot-scope="scope">
                <eyao-table-search-header
                  v-if="
                    column.search === 'input' ||
                      column.search === 'cascader' ||
                      column.search === 'select' ||
                      column.search === 'autocomplete'
                  "
                  :form-type="column.search"
                  :property="column.property"
                  :title="column.title"
                  :scope="scope"
                  :data="cascaderData[column.property]"
                  :options="valueOpts[column.property]"
                  :fuzzy-search="apiData"
                  @search="search"
                ></eyao-table-search-header>
                <template v-else>{{ column.title }}</template>
              </template>

              <template slot-scope="scope">
                <!-- name字段hover展示hco_code与hcp_code -->
                <template v-if="column.property === 'name'">
                  <el-popover
                    placement="top-start"
                    width="160"
                    trigger="hover"
                    :content="scope.row.hcp_code"
                  >
                    <span
                      slot="reference"
                      :style="{ color: '#0069FA', cursor: 'pointer' }"
                      @click="gotoDetail(scope.row.id, scope.row.name)"
                    >
                      {{ scope.row[column.property] }}
                    </span>
                  </el-popover>
                </template>

                <!-- 涉及时间字段，format值 -->
                <template v-else-if="column.format">
                  <template>{{ scope.row[column.property] | getFormatTime }}</template>
                </template>
                <template v-else>{{ scope.row[column.property] }}</template>
              </template>
            </el-table-column>

            <!-- 操作列 -->
            <el-table-column width="130" fixed="right" label="操作" v-if="role !== 3">
              <div class="del-doc" slot-scope="scope">
                <el-button type="text" @click="gotoEdit(scope.row.id, scope.row.name)">
                  编辑
                </el-button>
                <el-button type="text" @click="deleteData(scope.row.id)">删除</el-button>
              </div>
            </el-table-column>
            <template v-slot:empty>
              <div class="no_data" v-if="!docrotTableData.length">
                <img
                  src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                  alt="未查询到相关数据"
                />
                未查询到相关数据
              </div>
            </template>
          </eyao-table>
          <!-- <template v-if="!docrotTableData.length">
            <div class="no_data">
              <img
                src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                alt="未查询到相关数据"
              />
              未查询到相关数据
            </div>
          </template> -->
        </div>
      </div>
    </el-form>

    <!-- 历史版本详情查看模式 -->
    <el-form
      v-if="
        this.$route.query.type === 'history' &&
          !loading &&
          authLoading &&
          JSON.stringify(historyFromData) != '{}'
      "
      v-loading="loading"
    >
      <div v-for="(block, indexb) in config" :key="`block${indexb}`">
        <div v-if="block.show !== 'no'" class="datadetail-block">
          <div class="datadetail-block-title">
            {{ block.title }}

            <div
              v-if="
                block.title === '证照信息' &&
                  $route.query.type === 'history' &&
                  formData.license_info !== historyFromData.license_info
              "
              style="margin-left: 10px; display: flex"
            >
              <el-tooltip placement="top">
                <div slot="content">
                  <div>【当前版本】{{ getLicenseKey(JSON.parse(formData.license_info)) }}</div>
                </div>
                <img src="@/components/detail-input/img/history.svg" alt="" />
              </el-tooltip>
            </div>
          </div>

          <!-- 证照信息 -->
          <div
            class="license"
            v-if="
              block.title === '证照信息' &&
                $route.query.type === 'history' &&
                $route.query.source !== 'doctor' &&
                $route.query.source !== 'mid-org' &&
                $route.query.source !== 'mid-doc'
            "
          >
            <div class="table">
              <el-table
                empty-text="暂无相关信息"
                :data="licenseTable"
                :header-cell-style="{ height: '46px', background: '#F2F3F8' }"
              >
                <el-table-column prop="name" label="证书名称" width="180"></el-table-column>
                <el-table-column prop="license_code" label="证书编号" width="180"></el-table-column>
                <el-table-column prop="certification_date" label="认证日期"></el-table-column>
                <el-table-column prop="expire_date" label="有效期"></el-table-column>
                <el-table-column prop="issuing_authority" label="认证机构"></el-table-column>
              </el-table>
            </div>
          </div>

          <!-- 附带hover后显示编辑入口的组件 完成编辑时会触发submit -->
          <template v-for="(item, indexi) in block.itemList">
            <!-- 机构详情 -->
            <div
              :key="`${block.title}item${indexi}`"
              class="datadetail-block-item-detail"
              v-if="
                item.property !== 'hco_code' &&
                  item.name !== '机构名称' &&
                  item.name !== '更新人' &&
                  item.name !== '更新时间'
              "
            >
              <eyaoDetailInput
                :current-data="formData[item.property]"
                :history-key="contrastHistory"
                :edit-valid="item.edit"
                :edit-type="item.editType"
                :show-type="item.showType"
                :title="item.name"
                :init-value="historyFromData[item.property]"
                :property="item.property"
                :extra="item.extra"
                :required="item.required"
                :options="options"
                @submit="submit"
                @handleSuperiorOrg="handleSuperiorOrg"
                :auths="`${block.show}`"
              />
            </div>
          </template>
        </div>
      </div>

      <div
        class="doc-tab"
        v-if="
          this.$route.query.type === 'history' &&
            (this.$route.query.source === 'hospital' ||
              this.$route.query.source === 'hospital-ji' ||
              this.$route.query.source === 'hospital-pro' ||
              this.$route.query.source === 'hospital-other')
        "
      >
        <div class="top">
          <p class="title">相关医生</p>
          <div class="top-btn">
            <el-button type="text"></el-button>

            <div class="action-right">
              <el-button icon="el-icon-refresh-right" @click="getDoctorList"></el-button>
            </div>
          </div>
        </div>

        <div class="data-tabel">
          <div v-if="filterList.length > 0" class="datalist-title-filter">
            <div
              v-for="(item, index) in filterList"
              :key="index"
              class="datalist-title-filter-item"
            >
              {{ getLabel(item.name) }}: {{ item.label }}
              <i class="el-icon-close" @click="deleteFilterItem(item.name)"></i>
            </div>
            <el-button
              v-if="filterList.length"
              class="datalist-title-button"
              type="text"
              @click="deleteAllFilter"
            >
              重置
            </el-button>
          </div>
          <eyao-table
            ref="table"
            :table-data="docrotTableData"
            :total-num="doctorNum"
            :loading="doctorListLoading"
            @filter-change="filterChange"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :select-page-size="10"
          >
            <el-table-column
              v-for="column in doctorConfig"
              :key="`${$route.query.type}-${column.property}`"
              :prop="column.property"
              :column-key="column.property"
              :width="column.width"
              :label="column.title"
              :sortable="column.sort"
            >
              <template slot="header" slot-scope="scope">
                <eyao-table-search-header
                  v-if="
                    column.search === 'input' ||
                      column.search === 'cascader' ||
                      column.search === 'select' ||
                      column.search === 'autocomplete'
                  "
                  :form-type="column.search"
                  :property="column.property"
                  :title="column.title"
                  :scope="scope"
                  :data="cascaderData[column.property]"
                  :options="valueOpts[column.property]"
                  :fuzzy-search="apiData"
                  @search="search"
                ></eyao-table-search-header>
                <template v-else>{{ column.title }}</template>
              </template>

              <template slot-scope="scope">
                <!-- name字段hover展示hco_code与hcp_code -->
                <template v-if="column.property === 'name'">
                  <el-popover
                    placement="top-start"
                    width="160"
                    trigger="hover"
                    :content="scope.row.hcp_code"
                  >
                    <span
                      slot="reference"
                      :style="{ color: '#0069FA', cursor: 'pointer' }"
                      @click="gotoDetail(scope.row.id, scope.row.name)"
                    >
                      {{ scope.row[column.property] }}
                    </span>
                  </el-popover>
                </template>

                <!-- 涉及时间字段，format值 -->
                <template v-else-if="column.format">
                  <template>{{ scope.row[column.property] | getFormatTime }}</template>
                </template>
                <template v-else>{{ scope.row[column.property] }}</template>
              </template>
            </el-table-column>

            <!-- 操作列 -->
            <el-table-column width="130" fixed="right" label="操作" v-if="role !== 3">
              <div class="del-doc" slot-scope="scope">
                <el-button type="text" @click="gotoEdit(scope.row.id, scope.row.name)">
                  编辑
                </el-button>
                <el-button type="text" @click="deleteData(scope.row.id)">删除</el-button>
              </div>
            </el-table-column>
            <template v-slot:empty>
              <div class="no_data" v-if="!docrotTableData.length">
                <img
                  src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                  alt="未查询到相关数据"
                />
                未查询到相关数据
              </div>
            </template>
          </eyao-table>
        </div>
      </div>
    </el-form>

    <!-- DCR编辑模式 -->
    <el-form
      label-position="right"
      label-width="84px"
      v-if="this.$route.query.editDCR && JSON.stringify(formData) != '{}'"
      :model="formData"
      :rules="rules"
      ref="form"
      v-loading="loading"
    >
      <div v-for="(block, indexb) in config" :key="`block${indexb}`">
        <div class="datadetail-block" v-if="block.show !== 'no' && block.title !== '系统字段'">
          <div class="datadetail-block-title">
            {{ block.title }}
            <div v-if="block.title === '证照信息' && licenseIsUpdate" style="margin-left: 10px">
              <el-tooltip placement="top" style="max-width: 260px">
                <div slot="content">
                  <div>
                    【原始值】{{ getLicenseKey(dcrInitLicense) }}
                    <div class="data_detail_trip_left"></div>
                    【更新值】{{ getLicenseKey(dcrUpdaeLicense) }}
                    <div class="data_detail_trip_left"></div>
                    【最终值】{{ getLicenseKey(dcrFinaLicense) }}
                  </div>
                </div>
                <img src="./img/trip.svg" alt="" />
              </el-tooltip>
            </div>
          </div>

          <!-- 证照信息 -->
          <div class="license" v-if="block.title === '证照信息'">
            <div class="license-table">
              <el-table
                ref="table"
                :data="editLicenseItem"
                :header-row-style="{ height: '46px', color: '#667082' }"
                :header-cell-style="{
                  background: '#F2F3F8',
                  'box-shadow': 'inset 0px -1px 0px #E5EAF2',
                  padding: 0,
                }"
                :row-style="{ height: '66px' }"
                :cell-style="{ padding: '0px' }"
              >
                <el-table-column
                  align="center"
                  v-for="column in licenseConfig"
                  :key="column.property"
                  :prop="column.property"
                  :label="column.title"
                  :min-width="column.width"
                >
                  <template #default="scope">
                    <template v-if="scope.row.edit && column.property === 'name'">
                      <el-select v-model="scope.row.name" placeholder="请选择">
                        <el-option
                          v-for="item in licenseNameOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </template>
                    <template v-else-if="scope.row.edit && column.property === 'license_code'">
                      <el-input
                        class="license-input"
                        placeholder="请输入"
                        v-model="scope.row.license_code"
                      ></el-input>
                    </template>
                    <template
                      v-else-if="scope.row.edit && column.property === 'certification_date'"
                    >
                      <el-date-picker
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        class="license-date"
                        placeholder="选择日期"
                        v-model="scope.row.certification_date"
                      ></el-date-picker>
                    </template>
                    <template v-else-if="scope.row.edit && column.property === 'expire_date'">
                      <el-date-picker
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        type="date"
                        class="license-date"
                        placeholder="选择日期"
                        v-model="scope.row.expire_date"
                      ></el-date-picker>
                    </template>
                    <template v-else-if="scope.row.edit && column.property === 'issuing_authority'">
                      <el-input
                        class="license-input"
                        placeholder="请输入"
                        v-model="scope.row.issuing_authority"
                      ></el-input>
                    </template>
                    <template v-else>{{ scope.row[column.property] }}</template>
                  </template>
                </el-table-column>
                <!-- 操作列 -->
                <el-table-column label="操作" class-name="operate" width="220">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      @click="handleEditLicense(scope.row, scope.$index)"
                      v-if="!scope.row.edit"
                    >
                      编辑
                    </el-button>
                    <el-button
                      type="text"
                      @click="handleSaveLicense(scope.row, scope.$index)"
                      v-if="scope.row.edit"
                    >
                      保存
                    </el-button>
                    <el-button
                      type="text"
                      v-if="!scope.row.edit"
                      @click="handleDeletLicense(scope.row, scope.$index)"
                    >
                      删除
                    </el-button>
                    <el-button
                      type="text"
                      @click="handleCancelLicense(scope.row, scope.$index)"
                      v-if="scope.row.edit"
                    >
                      取消
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <template>
                <div class="add-license-item" @click="handleAddLicense">
                  <i class="el-icon-plus"></i>
                  <span>添加证书</span>
                </div>
              </template>
            </div>
          </div>

          <template v-for="(item, indexi) in block.itemList">
            <div
              :class="
                item.editType === 'textarea'
                  ? 'datadetail-block-item-textarea'
                  : 'datadetail-block-item'
              "
              :key="`${block.title}item${indexi}`"
              v-if="
                item.property !== 'source' &&
                  item.property !== 'qa_persons' &&
                  item.property !== 'hco_code'
              "
            >
              <el-form-item
                :label="item.name"
                class="datadetail-item-title"
                :required="item.required"
              ></el-form-item>
              <el-form-item
                class="datadetail-form-item"
                v-if="item.editType === 'cascade'"
                :prop="item.property"
              >
                <el-cascader
                  :disabled="block.show === 'read'"
                  class="datadetail-item-input"
                  :props="cascaderData.addressProps"
                  v-model="formData[item.property]"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                class="datadetail-form-item"
                v-if="item.editType === 'departmentCascade'"
                :prop="item.property"
              >
                <el-cascader
                  :disabled="block.show === 'read'"
                  class="datadetail-item-input"
                  :props="cascaderData.standard_dept_name"
                  v-model="formData[item.property]"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                class="datadetail-form-item"
                v-if="item.editType === 'orgCascade'"
                :prop="item.property"
              >
                <el-cascader
                  :disabled="block.show === 'read'"
                  v-if="switchShow"
                  class="datadetail-item-input"
                  :props="orgTypeProps"
                  v-model="formData[item.property]"
                ></el-cascader>
              </el-form-item>
              <el-form-item v-if="item.editType === 'tag'" class="tag">
                <el-tag
                  class="tags"
                  :key="i"
                  v-for="(tag, i) in formData[item.property]"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, item.property)"
                >
                  {{ tag }}
                </el-tag>
                <el-input
                  :disabled="block.show === 'read'"
                  class="input-new-tag"
                  v-if="inputVisible"
                  v-model.trim="alias"
                  ref="saveTagInput"
                  size="small"
                  @keyup.enter.native="handleInputConfirm(item.property)"
                  @blur="handleInputConfirm(item.property)"
                ></el-input>
                <el-button
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="showInput"
                  :disabled="block.show === 'read'"
                >
                  添加
                </el-button>
              </el-form-item>
              <!-- 曾用名 -->
              <el-form-item v-if="item.editType === 'formerNameTag'" class="tag">
                <el-tag
                  class="tags"
                  :key="i"
                  v-for="(tag, i) in formData[item.property]"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, item.property)"
                >
                  {{ tag }}
                </el-tag>
                <el-input
                  :disabled="block.show === 'read'"
                  class="input-new-tag"
                  v-if="inputVisible1"
                  v-model.trim="formerName"
                  ref="saveTagInput1"
                  size="small"
                  @keyup.enter.native="handleInputConfirm1(item.property)"
                  @blur="handleInputConfirm1(item.property)"
                ></el-input>
                <el-button
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="showInput1"
                  :disabled="block.show === 'read'"
                >
                  添加
                </el-button>
              </el-form-item>

              <el-form-item
                v-if="item.editType === 'input' || (item.editType === '' && item.extra !== 'map')"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-input
                  v-if="item.property === 'doubt_reason'"
                  :disabled="item.disable || block.show === 'read' || formData.in_doubt === 2"
                  class="datadetail-item-input"
                  v-model.trim="formData[item.property]"
                  placeholder="请输入内容"
                ></el-input>
                <el-input
                  v-else
                  :disabled="item.disable || block.show === 'read'"
                  class="datadetail-item-input"
                  v-model.trim="formData[item.property]"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>

              <el-form-item
                v-if="item.editType === 'textarea'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-input
                  :disabled="block.show === 'read'"
                  type="textarea"
                  class="datadetail-item-input"
                  v-model.trim="formData[item.property]"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>

              <el-form-item
                v-if="item.editType === 'number'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-input-number
                  :disabled="block.show === 'read'"
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  controls-position="right"
                  :min="0"
                ></el-input-number>
              </el-form-item>
              <el-form-item v-if="item.extra === 'map'" class="datadetail-form-item">
                <el-input
                  :disabled="block.show === 'read'"
                  class="datadetail-item-input"
                  v-model.trim="formData[item.property]"
                  placeholder="请输入内容"
                ></el-input>
                <i style="color: blue" class="el-icon-location-outline" @click="gotoMap"></i>
              </el-form-item>
              <el-form-item
                v-if="item.editType === 'lenovo'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-select
                  :disabled="block.show === 'read'"
                  clearable
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  v-if="item.property === 'parent_org'"
                  filterable
                  remote
                  :remote-method="parentHcoRemoteMethod"
                  :loading="remoteLoading2"
                  placeholder="请输入关键词"
                >
                  <el-option
                    v-for="(val, index) in options[item.property]"
                    :key="index"
                    :label="val.label"
                    :value="val.code"
                  ></el-option>
                </el-select>

                <el-select
                  :disabled="block.show === 'read'"
                  clearable
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  v-if="item.property === 'hco_org'"
                  filterable
                  remote
                  :remote-method="remoteMethod"
                  :loading="remoteLoading"
                  placeholder="请输入关键词"
                >
                  <el-option
                    v-for="(val, index) in options[item.property]"
                    :key="index"
                    :label="val.label"
                    :value="val.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="item.editType === 'select'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-select
                  :disabled="block.show === 'read'"
                  clearable
                  filterable
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(opitem, index) in options[item.property]"
                    :key="index"
                    :label="opitem.label"
                    :value="opitem.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                class="datadetail-form-item"
                :prop="item.property"
                v-if="item.editType === 'date'"
              >
                <el-date-picker
                  :disabled="block.show === 'read'"
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <!-- dcr更新值展示 -->
              <el-form-item
                v-if="item.editType === 'select' && isUpdate.includes(item.property)"
                class="dcr_trip"
              >
                <el-tooltip placement="top">
                  <div slot="content">
                    <div>
                      【原始值】{{ dcrInitData[item.property] | format(options[item.property]) }}
                      <span class="data_detail_trip_left"></span>
                      【更新值】{{ dcrUpdateData[item.property] | format(options[item.property]) }}
                      <span class="data_detail_trip_left"></span>
                      【最终值】{{ dcrFinalData[item.property] | format(options[item.property]) }}
                    </div>
                  </div>
                  <img src="./img/trip.svg" alt="" />
                </el-tooltip>
              </el-form-item>
              <el-form-item
                v-if="
                  (item.editType === 'input' ||
                    item.editType === 'textarea' ||
                    item.editType === 'number') &&
                    isUpdate.includes(item.property)
                "
                :class="item.property === 'position' ? 'dcr_trip_wz' : 'dcr_trip'"
              >
                <el-tooltip placement="top">
                  <div slot="content">
                    <div>
                      【原始值】{{
                        dcrInitData[item.property] !== '' ? dcrInitData[item.property] : '-'
                      }}
                      <span class="data_detail_trip_left"></span>
                      【更新值】{{
                        dcrUpdateData[item.property] !== '' ? dcrUpdateData[item.property] : '-'
                      }}
                      <span class="data_detail_trip_left"></span>
                      【最终值】{{
                        dcrFinalData[item.property] !== '' ? dcrFinalData[item.property] : '-'
                      }}
                    </div>
                  </div>
                  <img src="./img/trip.svg" alt="" />
                </el-tooltip>
              </el-form-item>

              <el-form-item
                v-if="
                  (item.editType === 'tag' || item.editType === 'formerNameTag') &&
                    isUpdate.includes(item.property)
                "
                class="dcr_trip"
              >
                <el-tooltip placement="top">
                  <div slot="content">
                    <div>
                      【原始值】{{
                        dcrInitData[item.property] ? dcrInitData[item.property].join(',') : '-'
                      }}
                      <span class="data_detail_trip_left"></span>
                      【更新值】{{
                        dcrUpdateData[item.property] ? dcrUpdateData[item.property].join(',') : '-'
                      }}
                      <span class="data_detail_trip_left"></span>
                      【最终值】{{
                        dcrFinalData[item.property] ? dcrFinalData[item.property].join(',') : '-'
                      }}
                    </div>
                  </div>
                  <img src="./img/trip.svg" alt="" />
                </el-tooltip>
              </el-form-item>

              <el-form-item
                v-if="item.editType === 'lenovo' && isUpdate.includes(item.property)"
                class="dcr_trip"
              >
                <el-tooltip placement="top">
                  <div slot="content">
                    <div>
                      【原始值】{{
                        dcrInitData[item.property] !== '' ? dcrInitData[item.property] : '-'
                      }}
                      <span class="data_detail_trip_left"></span>
                      【更新值】{{
                        dcrUpdateData[item.property] !== '' ? dcrUpdateData[item.property] : '-'
                      }}
                      <span class="data_detail_trip_left"></span>
                      【最终值】{{
                        dcrFinalData[item.property] ? dcrFinalData[item.property] : '-'
                      }}
                    </div>
                  </div>
                  <img src="./img/trip.svg" alt="" />
                </el-tooltip>
              </el-form-item>

              <el-form-item
                v-if="item.editType === 'orgCascade' && isUpdate.includes(item.property)"
                class="dcr_trip"
              >
                <el-tooltip placement="top">
                  <div slot="content">
                    <div>
                      【原始值】{{
                        dcrInitData[item.property]
                          ? dcrInitData[item.property]
                              .map(item => {
                                return item.split(',')[0];
                              })
                              .join('/')
                          : '-'
                      }}
                      <span class="data_detail_trip_left"></span>
                      【更新值】{{
                        dcrUpdateData[item.property]
                          ? dcrUpdateData[item.property]
                              .map(item => {
                                return item.split(',')[0];
                              })
                              .join('/')
                          : '-'
                      }}
                      <span class="data_detail_trip_left"></span>
                      【最终值】{{
                        dcrFinalData[item.property]
                          ? dcrFinalData[item.property]
                              .map(item => {
                                return item.split(',')[0];
                              })
                              .join('/')
                          : '-'
                      }}
                    </div>
                  </div>
                  <img src="./img/trip.svg" alt="" />
                </el-tooltip>
              </el-form-item>
              <el-form-item
                v-if="
                  (item.editType === 'cascade' || item.editType === 'departmentCascade') &&
                    isUpdate.includes(item.property)
                "
                class="dcr_trip"
              >
                <el-tooltip placement="top">
                  <div slot="content">
                    <div>
                      【原始值】{{
                        dcrInitData[item.property]
                          ? dcrInitData[item.property]
                              .map(item => {
                                return item.split(',')[1];
                              })
                              .join('/')
                          : '-'
                      }}
                      <span class="data_detail_trip_left"></span>
                      【更新值】{{
                        dcrUpdateData[item.property]
                          ? dcrUpdateData[item.property]
                              .map(item => {
                                return item.split(',')[1];
                              })
                              .join('/')
                          : '-'
                      }}
                      <span class="data_detail_trip_left"></span>
                      【最终值】{{
                        dcrFinalData[item.property]
                          ? dcrFinalData[item.property]
                              .map(item => {
                                return item.split(',')[1];
                              })
                              .join('/')
                          : '-'
                      }}
                    </div>
                  </div>
                  <img src="./img/trip.svg" alt="" />
                </el-tooltip>
              </el-form-item>
            </div>
          </template>
        </div>
      </div>
    </el-form>

    <!-- 编辑模式 -->
    <el-form
      label-position="right"
      label-width="84px"
      v-if="this.$route.query.type === 'edit' && JSON.stringify(formData) != '{}'"
      :model="formData"
      :rules="rules"
      ref="form"
      v-loading="loading"
    >
      <div v-for="(block, indexb) in config" :key="`block${indexb}`">
        <div class="datadetail-block" v-if="block.show !== 'no'">
          <div class="datadetail-block-title">{{ block.title }}</div>

          <!-- 证照信息 -->
          <div class="license" v-if="block.title === '证照信息'">
            <div class="license-table">
              <el-table
                ref="table"
                :data="editLicenseItem"
                :header-row-style="{ height: '46px', color: '#667082' }"
                :header-cell-style="{
                  background: '#F2F3F8',
                  'box-shadow': 'inset 0px -1px 0px #E5EAF2',
                  padding: 0,
                }"
                :row-style="{ height: '66px' }"
                :cell-style="{ padding: '0px' }"
              >
                <el-table-column
                  align="center"
                  v-for="column in licenseConfig"
                  :key="column.property"
                  :prop="column.property"
                  :label="column.title"
                  :min-width="column.width"
                >
                  <template #default="scope">
                    <template v-if="scope.row.edit && column.property === 'name'">
                      <el-select v-model="scope.row.name" placeholder="请选择">
                        <el-option
                          v-for="item in licenseNameOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </template>
                    <template v-else-if="scope.row.edit && column.property === 'license_code'">
                      <el-input
                        class="license-input"
                        placeholder="请输入"
                        v-model="scope.row.license_code"
                      ></el-input>
                    </template>
                    <template
                      v-else-if="scope.row.edit && column.property === 'certification_date'"
                    >
                      <el-date-picker
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        class="license-date"
                        placeholder="选择日期"
                        v-model="scope.row.certification_date"
                      ></el-date-picker>
                    </template>
                    <template v-else-if="scope.row.edit && column.property === 'expire_date'">
                      <el-date-picker
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        type="date"
                        class="license-date"
                        placeholder="选择日期"
                        v-model="scope.row.expire_date"
                      ></el-date-picker>
                    </template>
                    <template v-else-if="scope.row.edit && column.property === 'issuing_authority'">
                      <el-input
                        class="license-input"
                        placeholder="请输入"
                        v-model="scope.row.issuing_authority"
                      ></el-input>
                    </template>
                    <template v-else>{{ scope.row[column.property] }}</template>
                  </template>
                </el-table-column>
                <!-- 操作列 -->
                <el-table-column label="操作" class-name="operate" width="220">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      @click="handleEditLicense(scope.row, scope.$index)"
                      v-if="!scope.row.edit"
                    >
                      编辑
                    </el-button>
                    <el-button
                      type="text"
                      @click="handleSaveLicense(scope.row, scope.$index)"
                      v-if="scope.row.edit"
                    >
                      保存
                    </el-button>
                    <el-button
                      type="text"
                      v-if="!scope.row.edit"
                      @click="handleDeletLicense(scope.row, scope.$index)"
                    >
                      删除
                    </el-button>
                    <el-button
                      type="text"
                      @click="handleCancelLicense(scope.row, scope.$index)"
                      v-if="scope.row.edit"
                    >
                      取消
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <template>
                <div class="add-license-item" @click="handleAddLicense">
                  <i class="el-icon-plus"></i>
                  <span>添加证书</span>
                </div>
              </template>
            </div>
          </div>

          <template v-for="(item, indexi) in block.itemList">
            <div
              :class="
                item.editType === 'textarea'
                  ? 'datadetail-block-item-textarea'
                  : 'datadetail-block-item'
              "
              :key="`${block.title}item${indexi}`"
              v-if="
                item.property !== 'source' &&
                  item.property !== 'qa_persons' &&
                  item.property !== 'nhc_multi_sites' &&
                  item.property !== 'hco_code'
              "
            >
              <el-form-item
                :label="item.name"
                class="datadetail-item-title"
                :required="item.required"
              ></el-form-item>
              <el-form-item
                class="datadetail-form-item"
                v-if="item.editType === 'cascade'"
                :prop="item.property"
              >
                <el-cascader
                  :disabled="block.show === 'read'"
                  class="datadetail-item-input"
                  :props="cascaderData.addressProps"
                  v-model="formData[item.property]"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                class="datadetail-form-item"
                v-if="item.editType === 'departmentCascade'"
                :prop="item.property"
              >
                <el-cascader
                  :disabled="block.show === 'read'"
                  class="datadetail-item-input"
                  :props="cascaderData.standard_dept_name"
                  v-model="formData[item.property]"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                class="datadetail-form-item"
                v-if="item.editType === 'orgCascade'"
                :prop="item.property"
              >
                <el-cascader
                  :disabled="block.show === 'read'"
                  v-if="switchShow"
                  class="datadetail-item-input"
                  :props="orgTypeProps"
                  v-model="formData[item.property]"
                ></el-cascader>
              </el-form-item>
              <el-form-item v-if="item.editType === 'tag'" class="tag">
                <el-tag
                  class="tags"
                  :key="i"
                  v-for="(tag, i) in formData[item.property]"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, item.property)"
                >
                  {{ tag }}
                </el-tag>
                <el-input
                  :disabled="block.show === 'read'"
                  class="input-new-tag"
                  v-if="inputVisible"
                  v-model.trim="alias"
                  ref="saveTagInput"
                  size="small"
                  @keyup.enter.native="handleInputConfirm(item.property)"
                  @blur="handleInputConfirm(item.property)"
                ></el-input>
                <el-button
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="showInput"
                  :disabled="block.show === 'read'"
                >
                  添加
                </el-button>
              </el-form-item>
              <!-- 曾用名 -->
              <el-form-item v-if="item.editType === 'formerNameTag'" class="tag">
                <el-tag
                  class="tags"
                  :key="i"
                  v-for="(tag, i) in formData[item.property]"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, item.property)"
                >
                  {{ tag }}
                </el-tag>
                <el-input
                  :disabled="block.show === 'read'"
                  class="input-new-tag"
                  v-if="inputVisible1"
                  v-model.trim="formerName"
                  ref="saveTagInput1"
                  size="small"
                  @keyup.enter.native="handleInputConfirm1(item.property)"
                  @blur="handleInputConfirm1(item.property)"
                ></el-input>
                <el-button
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="showInput1"
                  :disabled="block.show === 'read'"
                >
                  添加
                </el-button>
              </el-form-item>

              <el-form-item
                v-if="item.editType === 'input' || (item.editType === '' && item.extra !== 'map')"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-input
                  v-if="item.property === 'doubt_reason'"
                  :disabled="item.disable || block.show === 'read' || formData.in_doubt === 2"
                  class="datadetail-item-input"
                  v-model.trim="formData[item.property]"
                  placeholder="请输入内容"
                ></el-input>
                <el-input
                  v-else
                  :disabled="item.disable || block.show === 'read'"
                  class="datadetail-item-input"
                  v-model.trim="formData[item.property]"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>

              <el-form-item
                v-if="item.editType === 'textarea'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-input
                  :disabled="block.show === 'read'"
                  type="textarea"
                  class="datadetail-item-input"
                  v-model.trim="formData[item.property]"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>

              <el-form-item
                v-if="item.editType === 'number'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-input-number
                  :disabled="block.show === 'read'"
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  controls-position="right"
                  :min="0"
                ></el-input-number>
              </el-form-item>
              <el-form-item v-if="item.extra === 'map'" class="datadetail-form-item">
                <el-input
                  :disabled="block.show === 'read'"
                  class="datadetail-item-input"
                  v-model.trim="formData[item.property]"
                  placeholder="请输入内容"
                ></el-input>
                <i style="color: blue" class="el-icon-location-outline" @click="gotoMap"></i>
              </el-form-item>
              <el-form-item
                v-if="item.editType === 'lenovo'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-select
                  :disabled="block.show === 'read'"
                  clearable
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  v-if="item.property === 'parent_org'"
                  filterable
                  remote
                  :remote-method="parentHcoRemoteMethod"
                  :loading="remoteLoading2"
                  placeholder="请输入关键词"
                >
                  <el-option
                    v-for="(val, index) in options[item.property]"
                    :key="index"
                    :label="val.label"
                    :value="val.code"
                  ></el-option>
                </el-select>

                <el-select
                  :disabled="block.show === 'read'"
                  clearable
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  v-if="item.property === 'hco_org'"
                  filterable
                  remote
                  :remote-method="remoteMethod"
                  :loading="remoteLoading"
                  placeholder="请输入关键词"
                >
                  <el-option
                    v-for="(val, index) in options[item.property]"
                    :key="index"
                    :label="val.label"
                    :value="val.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="item.editType === 'select'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-select
                  :disabled="block.show === 'read'"
                  clearable
                  filterable
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(opitem, index) in options[item.property]"
                    :key="index"
                    :label="opitem.label"
                    :value="opitem.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                class="datadetail-form-item"
                :prop="item.property"
                v-if="item.editType === 'date'"
              >
                <el-date-picker
                  :disabled="block.show === 'read'"
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </div>
          </template>
        </div>
      </div>
    </el-form>

    <!-- 新建模式 -->
    <el-form
      label-position="right"
      label-width="84px"
      v-if="this.$route.query.type === 'new'"
      :model="formData"
      :rules="rules"
      ref="form"
      v-loading="loading"
    >
      <div v-for="(block, indexb) in config" :key="`block${indexb}`">
        <div
          class="datadetail-block"
          v-if="block.title !== '系统字段' && block.title !== '清洗备注'"
        >
          <div class="datadetail-block-title">{{ block.title }}</div>

          <!-- 证照信息 -->
          <div
            class="license"
            v-if="
              block.title === '证照信息' &&
                $route.query.type === 'new' &&
                $route.query.source !== 'doctor' &&
                $route.query.source !== 'mid-org' &&
                $route.query.source !== 'mid-doc'
            "
          >
            <div class="license-table">
              <el-table
                ref="table"
                :data="editLicenseItem"
                :header-row-style="{ height: '46px', color: '#667082' }"
                :header-cell-style="{
                  background: '#F2F3F8',
                  'box-shadow': 'inset 0px -1px 0px #E5EAF2',
                  padding: 0,
                }"
                :row-style="{ height: '66px' }"
                :cell-style="{ padding: '0px' }"
              >
                <el-table-column
                  align="center"
                  v-for="column in licenseConfig"
                  :key="column.property"
                  :prop="column.property"
                  :label="column.title"
                  :min-width="column.width"
                >
                  <template #default="scope">
                    <template v-if="scope.row.edit && column.property === 'name'">
                      <el-select v-model="scope.row.name" placeholder="请选择">
                        <el-option
                          v-for="item in licenseNameOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </template>
                    <template v-else-if="scope.row.edit && column.property === 'license_code'">
                      <el-input
                        class="license-input"
                        placeholder="请输入"
                        v-model="scope.row.license_code"
                      ></el-input>
                    </template>
                    <template
                      v-else-if="scope.row.edit && column.property === 'certification_date'"
                    >
                      <el-date-picker
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        class="license-date"
                        placeholder="选择日期"
                        v-model="scope.row.certification_date"
                      ></el-date-picker>
                    </template>
                    <template v-else-if="scope.row.edit && column.property === 'expire_date'">
                      <el-date-picker
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        type="date"
                        class="license-date"
                        placeholder="选择日期"
                        v-model="scope.row.expire_date"
                      ></el-date-picker>
                    </template>
                    <template v-else-if="scope.row.edit && column.property === 'issuing_authority'">
                      <el-input
                        class="license-input"
                        placeholder="请输入"
                        v-model="scope.row.issuing_authority"
                      ></el-input>
                    </template>
                    <template v-else>{{ scope.row[column.property] }}</template>
                  </template>
                </el-table-column>
                <!-- 操作列 -->
                <el-table-column label="操作" class-name="operate" width="220">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      @click="handleEditLicense(scope.row, scope.$index)"
                      v-if="!scope.row.edit"
                    >
                      编辑
                    </el-button>
                    <el-button
                      type="text"
                      @click="handleSaveLicense(scope.row, scope.$index)"
                      v-if="scope.row.edit"
                    >
                      保存
                    </el-button>
                    <el-button
                      type="text"
                      v-if="!scope.row.edit"
                      @click="handleDeletLicense(scope.row, scope.$index)"
                    >
                      删除
                    </el-button>
                    <el-button
                      type="text"
                      @click="handleCancelLicense(scope.row, scope.$index)"
                      v-if="scope.row.edit"
                    >
                      取消
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <template>
                <div class="add-license-item" @click="handleAddLicense">
                  <i class="el-icon-plus"></i>
                  <span>添加证书</span>
                </div>
              </template>
            </div>
          </div>

          <template v-for="(item, indexi) in block.itemList">
            <div
              v-if="item.property !== 'hco_code' && item.property !== 'nhc_multi_sites'"
              :class="
                item.editType === 'textarea'
                  ? 'datadetail-block-item-textarea'
                  : 'datadetail-block-item'
              "
              :key="`${block.title}item${indexi}`"
            >
              <el-form-item
                :label="item.name"
                class="datadetail-item-title"
                :required="item.required"
              ></el-form-item>
              <el-form-item
                class="datadetail-form-item"
                v-if="item.editType === 'cascade'"
                :prop="item.property"
              >
                <el-cascader
                  class="datadetail-item-input"
                  :props="cascaderData.addressProps"
                  v-model="formData[item.property]"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                class="datadetail-form-item"
                v-if="item.editType === 'departmentCascade'"
                :prop="item.property"
              >
                <el-cascader
                  class="datadetail-item-input"
                  :props="cascaderData.standard_dept_name"
                  v-model="formData[item.property]"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                class="datadetail-form-item"
                v-if="item.editType === 'orgCascade'"
                :prop="item.property"
              >
                <el-cascader
                  v-if="switchShow"
                  class="datadetail-item-input"
                  :props="orgTypeProps"
                  v-model="formData[item.property]"
                ></el-cascader>
              </el-form-item>
              <el-form-item v-if="item.editType === 'tag'" class="tag">
                <el-tag
                  class="tags"
                  :key="i"
                  v-for="(tag, i) in formData[item.property]"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, item.property)"
                >
                  {{ tag }}
                </el-tag>
                <el-input
                  class="input-new-tag"
                  v-if="inputVisible"
                  v-model.trim="alias"
                  ref="saveTagInput"
                  size="small"
                  @keyup.enter.native="handleInputConfirm(item.property)"
                  @blur="handleInputConfirm(item.property)"
                ></el-input>
                <el-button v-else class="button-new-tag" size="small" @click="showInput">
                  添加
                </el-button>
              </el-form-item>
              <!-- 曾用名 -->
              <el-form-item v-if="item.editType === 'formerNameTag'" class="tag">
                <el-tag
                  class="tags"
                  :key="i"
                  v-for="(tag, i) in formData[item.property]"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, item.property)"
                >
                  {{ tag }}
                </el-tag>
                <el-input
                  class="input-new-tag"
                  v-if="inputVisible1"
                  v-model.trim="formerName"
                  ref="saveTagInput1"
                  size="small"
                  @keyup.enter.native="handleInputConfirm1(item.property)"
                  @blur="handleInputConfirm1(item.property)"
                ></el-input>
                <el-button v-else class="button-new-tag" size="small" @click="showInput1">
                  添加
                </el-button>
              </el-form-item>
              <el-form-item
                v-if="item.editType === 'input' || (item.editType === '' && item.extra !== 'map')"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-input
                  :disabled="item.disable"
                  class="datadetail-item-input"
                  v-model.trim="formData[item.property]"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>

              <el-form-item
                v-if="item.editType === 'textarea'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-input
                  type="textarea"
                  class="datadetail-item-input"
                  v-model.trim="formData[item.property]"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>

              <el-form-item
                v-if="item.editType === 'number'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-input-number
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  controls-position="right"
                  :min="0"
                ></el-input-number>
              </el-form-item>
              <el-form-item v-if="item.extra === 'map'" class="datadetail-form-item">
                <el-input
                  class="datadetail-item-input"
                  v-model.trim="formData[item.property]"
                  placeholder="请输入内容"
                ></el-input>
                <i style="color: blue" class="el-icon-location-outline" @click="gotoMap"></i>
              </el-form-item>
              <el-form-item
                v-if="item.editType === 'lenovo'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-select
                  clearable
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  v-if="item.property === 'parent_org'"
                  filterable
                  remote
                  :remote-method="parentHcoRemoteMethod"
                  :loading="remoteLoading2"
                  placeholder="请输入关键词"
                >
                  <el-option
                    v-for="(val, index) in options[item.property]"
                    :key="index"
                    :label="val.label"
                    :value="val.code"
                  ></el-option>
                </el-select>

                <el-select
                  clearable
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  v-if="item.property === 'hco_org'"
                  filterable
                  remote
                  :remote-method="remoteMethod"
                  :loading="remoteLoading"
                  placeholder="请输入关键词"
                >
                  <el-option
                    v-for="val in options[item.property]"
                    :key="val.code"
                    :label="val.label"
                    :value="val.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- 普通选择器 -->
              <el-form-item
                v-if="item.editType === 'select'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-select
                  clearable
                  filterable
                  :disabled="item.disable"
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(opitem, index) in options[item.property]"
                    :key="index"
                    :label="opitem.label"
                    :value="opitem.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                class="datadetail-form-item"
                :prop="item.property"
                v-if="item.editType === 'date'"
              >
                <el-date-picker
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </div>
          </template>
        </div>
      </div>
    </el-form>
    <!-- 底部保存按钮栏 -->
    <div
      v-if="this.$route.query.type !== 'preview' && this.$route.query.type !== 'history'"
      class="datadetail-end"
    >
      <el-button
        v-if="!this.$route.query.editDCR"
        class="datadetail-end-button1"
        type="primary"
        @click="test"
      >
        保存
      </el-button>
      <el-button
        v-if="!this.$route.query.editDCR"
        class="datadetail-end-button2"
        @click="handleBack"
      >
        取消
      </el-button>

      <el-button
        v-if="this.$route.query.editDCR"
        class="datadetail-end-button3"
        type="primary"
        @click="test"
      >
        保存当前DCR处理
      </el-button>
      <el-button
        v-if="this.$route.query.editDCR"
        class="datadetail-end-button2"
        @click="handleGoBack"
      >
        取消
      </el-button>
    </div>
    <!-- 右侧操作日志按钮 -->
    <el-drawer
      title="历史版本"
      :visible.sync="drawer"
      direction="rtl"
      size="920px"
      class="history_version"
    >
      <div class="export_btn">
        <el-button :disabled="!selectHistoryVersion.length" @click="handleExport">导出</el-button>
      </div>
      <div class="table">
        <el-table
          :row-key="rowKey"
          :data="historyData"
          :header-cell-style="{
            background: '#F2F3F8',
            'box-shadow': 'inset 0px -1px 0px #E5EAF2',
            padding: 0,
          }"
          :header-row-style="{ height: '38px', color: ' #667082' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" :reserve-selection="true" />
          <el-table-column prop="version" label="版本">
            <template slot-scope="scope">
              <div
                class="curren_version"
                v-if="Number(formData.record_version) === Number(scope.row.version)"
              >
                {{ `当前(V.${scope.row.version})` }}
              </div>
              <div @click="handleToHistory(scope.row)" class="version" v-else>
                {{ `(V.${scope.row.version})` }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="ope_time" label="日期">
            <template slot-scope="scope">
              <div class="date">{{ scope.row.ope_time }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="user_name" label="更新人">
            <template slot-scope="scope">
              <div class="user_name">{{ scope.row.user_name }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="content" label="操作明细">
            <template slot-scope="scope">
              <div class="user_name">{{ scope.row.content }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div class="btn">
                <el-button
                  type="text"
                  v-if="Number(formData.record_version) !== Number(scope.row.version)"
                  @click="handleToHistory(scope.row)"
                >
                  历史版本
                </el-button>
                <el-button
                  type="text"
                  @click="handleToLog(scope.row)"
                  :disabled="
                    historyData.length === 1 &&
                      Number(formData.record_version) === Number(scope.row.version) &&
                      scope.row.content === ''
                  "
                >
                  操作明细
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>

    <!-- 重复数据弹窗提示 -->
    <el-dialog
      :visible.sync="repeatVisible"
      width="400px"
      :before-close="handleCancel"
      :show-close="false"
    >
      <div class="dialog-tit">
        <i class="el-icon-warning"></i>
        <p>数据重复</p>
      </div>
      <div v-if="this.$route.query.source !== 'doctor'" class="data-name">
        提交信息与以下数据的医院名称和省重复 或 医院名称和省市重复 或 组织机构代码重复
      </div>
      <div v-else class="data-name">
        提交信息与以下数据的医生姓名+所属机构名称重复 或 执业许可证编号重复
      </div>
      <div class="data-name-content">
        <span
          class="content-link"
          type="text"
          @click="handleGoRepeat(item)"
          v-for="(item, index) in repeatData"
          :key="index"
        >
          {{ item.name || item.id }}
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleCancel">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 疑似重复数据弹窗提示 -->
    <el-dialog
      :visible.sync="repeatVisible2"
      width="30%"
      right
      :before-close="handleCancel"
      :show-close="false"
    >
      <div class="dialog-tit">
        <i class="el-icon-warning"></i>
        <p>数据可能重复</p>
      </div>
      <div v-if="this.$route.query.source !== 'doctor'" class="data-name">
        提交信息与以下数据的医院名称和省疑似重复 或 医院名称和省市疑似重复 或
        组织机构代码疑似重复，请确认是否要保存
      </div>
      <div v-else class="data-name">
        提交信息与以下数据的医生姓名+所属机构名称疑似重复 或
        执业许可证编号疑似重复，请确认是否要保存
      </div>
      <div class="data-name-content">
        <span
          class="content-link"
          type="text"
          @click="handleGoRepeat(item)"
          v-for="(item, index) in repeatData"
          :key="index"
        >
          {{ item.name || item.id }}
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSub">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除弹窗 -->
    <el-dialog
      title="确认删除吗"
      :visible.sync="showDeleteDialog"
      width="400px"
      class="deleteDataModel"
    >
      <span slot="title" :style="{ fontSize: '14px', color: '#1C2028' }">
        <i class="el-icon-warning" :style="{ fontSize: '18px', color: '#FA9600' }" />
        确认删除吗
      </span>
      请填写删除原因
      <el-input v-model="deleteReason"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-cancelBtn" @click="showDeleteDialog = false">
          取 消
        </el-button>
        <el-button type="primary" @click="deleteConfirm">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 数据导出model -->
    <el-dialog
      title="数据导出"
      :visible.sync="showDataExportDialog"
      width="680px"
      top="20vh"
      class="dataExport-warp"
      :before-close="handleCloseDialog"
    >
      <div>
        <div class="tit_type">
          <div class="tit_type_left">导出数据类型</div>
          <div class="tit_type_right">
            {{ REQUEST_TITLE_TYPE[this.$route.query.source] }}
          </div>
        </div>

        <div class="content_select">
          <div class="content_select_left">选择导出字段</div>
          <div class="content_select_right">
            <div class="transfer-field-warp">
              <el-transfer
                ref="exportFieldTransfer"
                v-model="exportRightFields"
                :data="allExportFields"
                :titles="['可选字段', '导出字段']"
                target-order="push"
                @change="changeExportField"
                @right-check-change="exportCheckedFields"
              ></el-transfer>

              <div class="transfer-field-move">
                <el-button :disabled="checkedFields.length === 0" @click="handleExportFieldUp">
                  <i class="el-icon-top"></i>
                </el-button>
                <el-button :disabled="checkedFields.length === 0" @click="handleExportFieldDown">
                  <i class="el-icon-bottom"></i>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-cancelBtn" @click="handleCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          :disabled="exportRightFields.length === 0"
          @click="handleExprotVersion"
        >
          导 出
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import store from '@/store';
import eyaoDetailInput from '@/components/detail-input';
import { REQUEST_TITLE_TYPE, DICT_TYPE } from '@/utils/constant';
import hospitalDataConfig from './config/hospital';
import dealerDataConfig from './config/dealer';
import doctorDataConfig from './config/doctor';
import shopDataConfig from './config/shop';
import midDocDataConfig from './config/mid-doc';
import midOrgDataConfig from './config/mid-org';
import { dateFormat, getAuthorization } from '@/utils/util';
import {
  GetAdminDistrict,
  GetDictOptionList,
  CreateMainDataDetail,
  GetMainDataDetail,
  EditMainDataDetail,
  DeleteMainDataDetail,
  GetOrgTypeList,
  GetStagingDataDetail,
  SearchMainDataByType,
  GetStandardDeptList,
  DataReport,
  GetFieldPermissions,
  GetDataHistory,
  GetDataHistoryDetail,
} from '@/api/data';
import { GetDataOperationLogList } from '@/api/log';
import pinyin from 'easy-pinyin';
import eyaoTable from '@/components/table';
import doctorConfig from './tableConfig/doctor';
import eyaoTableSearchHeader from '@/components/table-search-header';
import doctorLabelConfig from './labelConfig/doctor';
import { GetTagInfosByValues } from '@/api/tag';
import { QueryDcrDetails, ModifyDcrDetails, CreateDcr } from '@/api/dcr';
import { jsonToXlsx } from '@/utils/xlsx.js';
import _ from 'lodash';
import { GetConfig } from '@/api/dcr-config';
import licenseConfig from './config/license';
export default {
  components: {
    eyaoDetailInput,
    eyaoTable,
    eyaoTableSearchHeader,
  },
  filters: {
    formatTagUpdateWay(val) {
      if (val === 1) {
        return '手动更新';
      }
      if (val === 2) {
        return '定时更新';
      }
      return '';
    },
    getFormatTime(value) {
      if (value && !value.startsWith('0')) {
        return dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
      }
      return '';
    },
    format(val, options = []) {
      if (val !== '') {
        for (const item of options) {
          if (Number(item.code) === Number(val) || item.code === val) {
            return item.label;
          }
        }
      }
      return '-';
    },
  },
  data() {
    // 验证邮编
    const checkPostcode = (rule, value, callback) => {
      if (value === '') {
        callback();
      } else {
        const reg = /^[0-9]{6}$/;
        if (!reg.test(value)) {
          callback(new Error('请输入六位数字'));
        } else {
          callback();
        }
      }
    };
    // 验证主页
    const checkWebsite = (rule, value, callback) => {
      if (value === '') {
        callback();
      } else {
        const reg = /^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/;
        if (!reg.test(value)) {
          callback(new Error('输入的地址不合法'));
        } else {
          callback();
        }
      }
    };
    // 验证邮箱
    const checkEmail1 = (rule, value, callback) => {
      if (value === '') {
        callback();
      } else {
        const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
        if (!reg.test(value)) {
          callback(new Error('输入的邮箱不合法'));
        } else {
          callback();
        }
      }
    };
    return {
      // 证照信息配置
      licenseConfig: [],
      newLicenseList: [],
      editLicenseItem: [],
      //  {
      //     name: '',
      //     license_code: '',
      //     certification_date: '',
      //     expire_date: '',
      //     issuing_authority: '',
      //   },
      licenseTitle: '',
      licenseNameOptions: [
        {
          label: '医疗机构执业许可证',
          value: '医疗机构执业许可证',
        },
        {
          label: '经营许可证',
          value: '经营许可证',
        },
      ],
      licenseVisible: false,
      dcrInitLicense: [], // dcr证照初始值
      dcrUpdaeLicense: [], // dcr证照变更值
      dcrFinaLicense: [], // dcr证照最终值
      editLicenseData: [], // 存储编辑证照信息时的初始值
      licenseIsUpdate: false,

      // DCR配置信息
      isOpenDcr: false,
      dcrRole: 1,
      third: false,

      contrastHistory: [], // 历史版本与当前版本数据对比字段
      historyFromData: {}, // 历史版本详情数据
      REQUEST_TITLE_TYPE,
      checkedFields: [],
      exportRightFields: [],
      allExportFields: [],
      showDataExportDialog: false,
      selectHistoryVersion: [],
      historyData: [],
      licenseTable: [],
      screenWidth: '',
      show: false,
      showBtnZK: false,
      showDivZK: false,
      depData: {},
      dcrInitData: {}, // DCR初始数据
      dcrUpdateData: {}, // DCR更新数据
      dcrFinalData: {}, // DCR最终数据
      dcrContent: [], // DCR参数数组
      isUpdate: [], // 存储DCR更新字段
      // dcrCheck:{}, // 存放最终修改值的字段名

      tagDataOld: [],
      tagData: [],
      authLoading: false,
      readWrites: [], // 读写权限字段
      read: [], // 只读权限字段
      pageDataSize: 10,
      currentDataPage: 1,
      deleteDialogTarget: '',
      showDeleteDialog: false,
      deleteReason: '',
      doctorListLoading: false,
      configObj: {},
      filterList: [],
      filterObject: {}, // 现有表格筛选条件的存储对象 key为属性名 value为值
      doctorLabelConfig,
      propertyName: [
        'type_name',
        'hospital_level',
        'ownership',
        'is_medical_insurance',
        'business_status',
        'in_doubt',
        'source',
        'gender',
        'hcp_type',
        'hcp_status',
        'standard_dept_name',
        'professional_title',
        'org_type',
      ],
      valueOpts: {},
      doctorConfig,
      doctorNum: 0, // 医生列表数量
      docrotTableData: [], // 医生列表
      switchShow: true,
      drawer: false,
      remoteLoading2: false,
      remoteLoading: false,
      reason: '',
      dialogFormVisible: false,
      repeatData: [],
      repeatVisible: false,
      repeatVisible2: false,
      loading: false,
      prerules: {},
      inputVisible: false,
      inputVisible1: false,
      inputValue: '',
      inputValue1: '',

      // 操作模式 有编辑edit 展示previe 新建new三种
      // type: '',
      // 需要进行操作的数据id
      id: '',
      // 页面上的数据显示配置 todo：需要根据不同种类的数据更换不同的配置
      config: null,
      // 表单数据 通过读取config使用vue.set单独进行初始化
      formData: {},
      // 表单校验对象 读取config时初始化 用来做非空判定
      rules: {
        postcode: [
          {
            validator: checkPostcode,
            trigger: 'blur',
          },
        ],
        website: [
          {
            validator: checkWebsite,
            trigger: 'blur',
          },
        ],
        email1: [
          {
            validator: checkEmail1,
            trigger: 'blur',
          },
        ],
      },
      // 示例用下拉列表
      options: {
        gender: [],
        hco_org: [],
        hcp_type: [],
        hcp_status: [],
        // standard_dept_name: [],
        professional_title: [],
        // nhc_multi_sites: [
        //   { label: '否', code: 0 },
        //   { label: '是', code: 1 },
        // ],

        hospital_profit_type: [],

        firstType: '',

        parent_org: [],
        hospital_grade: [],
        hospital_level: [],
        ownership: [],
        is_medical_insurance: [],
        business_status: [],
        hco_type: [],
        in_doubt: [],
        source: [],
        org_type: [],

        academic_title: [],
        administrative_title: [],
      },
      // 储存第一次进入页面获取到的二级分类
      firstType: {},
      // 数据操作日志的示例
      activities: [],
      // 是否显示数据操作日志
      logShow: false,
      cascaderData: {
        addressProps: {
          lazy: true,
          checkStrictly: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            const nodes = [];
            const req = {
              code: level === 0 ? '' : node.value.split(',')[0],
              level: level.toString(),
            };
            if (level > 4) {
              return;
            }
            GetAdminDistrict(req).then(res => {
              res.district_list.map(item => {
                const obj = {
                  value: `${item.code},${item.name}`,
                  label: item.name,
                  leaf: !item.level,
                };
                nodes.push(obj);
              });
              // resolve 节点返回
              resolve(nodes);
            });
          },
        },
        standard_dept_name: {
          lazy: true,
          checkStrictly: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            const nodes = [];
            const req = {
              code: level === 0 ? '' : node.value.split(',')[0],
              level: level.toString(),
            };
            if (level > 2) {
              return;
            }
            GetStandardDeptList(req).then(res => {
              res.deps.map(item => {
                const obj = {
                  value: `${item.code},${item.name}`,
                  label: item.name,
                  leaf: !item.level,
                };
                nodes.push(obj);
              });
              // resolve 节点返回
              resolve(nodes);
            });
          },
        },
      },
      initial: {}, // 编辑页进入时的数据，用于比对改变
      content: [], // 存放编辑改变字段内容
      detailEdit: [], // 在详情页面编辑保存的数据
      creatData: [], // 新建数据存储
      type2: '',
    };
  },
  computed: {
    liceseKey() {
      const obj = {};
      licenseConfig.forEach(item => {
        obj[item.property] = item.title;
      });
      return obj;
    },
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }

      return newRole;
    },
    // 机构类型细分
    orgTypeProps() {
      const first = this.options.firstType;
      if (!first) {
        return {};
      }
      this.orgTypeShow();
      return {
        lazy: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          const nodes = [];
          const req = {
            parend_ids: level === 0 ? [first] : [node.value.split(',')[1]],
            level: level + 3,
          };
          console.log('参数', req);
          if (level > 2) {
            return;
          }
          GetOrgTypeList(req).then(res => {
            res.datas.map(item => {
              const obj = {
                value: `${item.type_name},${item.type_id}`,
                label: item.type_name,
                leaf: !item.is_leaf,
              };
              nodes.push(obj);
            });
            resolve(nodes);
          });
        },
      };
    },

    // 是否中转库数据 影响是否提供编辑与新建操作
    isMid() {
      return this.$route.query.source === 'mid-org' || this.$route.query.source === 'mid-doc';
    },

    title() {
      return `新建${REQUEST_TITLE_TYPE[this.$route.query.source]}`;
    },
    alias: {
      get() {
        return this.inputValue;
      },
      set(val) {
        if (val.indexOf(',') > -1 || val.indexOf('，') > -1) {
          this.$message({
            message: '名称存在非法字符',
            type: 'warning',
          });
          return;
        }
        this.inputValue = val;
      },
    },
    formerName: {
      get() {
        return this.inputValue1;
      },
      set(val) {
        if (val.indexOf(',') > -1 || val.indexOf('，') > -1) {
          this.$message({
            message: '名称存在非法字符',
            type: 'warning',
          });
          return;
        }
        this.inputValue1 = val;
      },
    },
    systemEnv() {
      // systemEnv为1表示管理端，2表示客户端
      return localStorage.getItem('systemEnv');
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'data-detail') {
        this.init();
      }
    },
    'formData.name': {
      handler(newval, oldval) {
        if (newval !== oldval && this.$route.query.source === 'doctor' && oldval != undefined) {
          this.formData.pinyin = pinyin(newval);
        }
      },
      deep: true,
    },
    'formData.in_doubt': {
      handler(newval, oldval) {
        console.log(newval, oldval);
        if (newval === 2) {
          this.formData.doubt_reason = '';
          // if (this.$route.query.source === 'doctor') {
          //   this.formData.doubt_reason = '';
          // }
        }
      },
    },
  },
  async created() {
    this.getConfig();
    if (
      (this.$route.query.type === 'preview' || this.$route.query.type === 'history') &&
      (this.$route.query.source === 'hospital' ||
        this.$route.query.source === 'hospital-ji' ||
        this.$route.query.source === 'hospital-pro' ||
        this.$route.query.source === 'hospital-other')
    ) {
      // this.getDoctorList();
      this.filterInit();
      this.getOptions();
      this.dealConfig();
    }

    if (
      this.$route.query.source !== 'doctor' &&
      this.$route.query.source !== 'mid-doc' &&
      this.$route.query.source !== 'mid-org'
    ) {
      this.licenseConfig = licenseConfig;
    }

    this.id = this.$route.query.id || '';
    if (this.$route.query.source === 'doctor') {
      this.config = doctorDataConfig;
      await this.getAuthority('t_hcp');
    }
    if (this.$route.query.source === 'hospital') {
      this.config = hospitalDataConfig;
      await this.getAuthority('t_hco');
    }
    if (this.$route.query.source === 'hospital-ji') {
      this.config = hospitalDataConfig;
      await this.getAuthority('t_hco');
    }
    if (this.$route.query.source === 'hospital-pro') {
      this.config = hospitalDataConfig;
      await this.getAuthority('t_hco');
    }
    if (this.$route.query.source === 'hospital-other') {
      this.config = hospitalDataConfig;
      await this.getAuthority('t_hco');
    }
    if (this.$route.query.source === 'dealer') {
      this.config = dealerDataConfig;
      await this.getAuthority('t_hco');
    }
    if (this.$route.query.source === 'manufacturer') {
      this.config = dealerDataConfig;
      await this.getAuthority('t_hco');
    }
    if (this.$route.query.source === 'medical-machine') {
      this.config = dealerDataConfig;
      await this.getAuthority('t_hco');
    }
    if (this.$route.query.source === 'other') {
      this.config = dealerDataConfig;
      await this.getAuthority('t_hco');
    }
    if (this.$route.query.source === 'shop') {
      this.config = shopDataConfig;
      await this.getAuthority('t_hco');
    }
    if (this.$route.query.source === 'mid-doc') {
      this.config = midDocDataConfig;
      // await this.getAuthority('t_hcp');
      this.authLoading = true;
    }
    if (this.$route.query.source === 'mid-org') {
      this.config = midOrgDataConfig;
      // await this.getAuthority('t_hco');
      this.authLoading = true;
    }

    // 是否存疑
    GetDictOptionList({ dict_api_name: 'in_doubt', dict_type: '0' }).then(res => {
      this.options.in_doubt = res.dict_list.map(item => {
        return { label: item.label, code: Number(item.code) };
      });
    });
    // 数据来源
    GetDictOptionList({ dict_api_name: 'source', dict_type: '4' }).then(res => {
      // console.log('数据来源', res);
      this.options.source = res.dict_list.map(item => {
        return { label: item.label, code: item.code };
      });
    });

    if (this.$route.query.source === 'doctor') {
      // 获取学术头衔
      GetDictOptionList({ dict_api_name: 'academic_title', dict_type: '1' }).then(res => {
        console.log('学术头衔', res.dict_list);
        this.options.academic_title = res.dict_list;
      });
      // 获取行政职务
      GetDictOptionList({ dict_api_name: 'administrative_title ', dict_type: '1' }).then(res => {
        console.log('行政职务', res.dict_list);
        this.options.administrative_title = res.dict_list;
      });
      // 获取医生性别
      GetDictOptionList({ dict_api_name: 'gender', dict_type: '1' }).then(res => {
        if (this.$route.query.editDCR) {
          this.options.gender = res.dict_list.map(item => {
            return { label: item.label, code: String(item.code) };
          });
        } else {
          this.options.gender = res.dict_list.map(item => {
            return { label: item.label, code: Number(item.code) };
          });
        }
      });
      // 医生HCP类型
      GetDictOptionList({ dict_api_name: 'hcp_type', dict_type: '1' }).then(res => {
        this.options.hcp_type = res.dict_list;
      });
      // 医生HCP状态
      GetDictOptionList({ dict_api_name: 'hcp_status', dict_type: '1' }).then(res => {
        this.options.hcp_status = res.dict_list;
      });
      // 医生专业职称
      GetDictOptionList({ dict_api_name: 'professional_title', dict_type: '1' }).then(res => {
        // console.log(res.dict_list);
        this.options.professional_title = res.dict_list.map(item => {
          return { label: item.label, code: item.code };
        });
      });
    } else {
      // 医院营利性质
      GetDictOptionList({ dict_api_name: 'hospital_profit_type', dict_type: '0' }).then(res => {
        this.options.hospital_profit_type = res.dict_list;
      });
      // 医院等次
      GetDictOptionList({ dict_api_name: 'hospital_level', dict_type: '0' }).then(res => {
        this.options.hospital_level = res.dict_list;
      });
      // 所有性质
      GetDictOptionList({ dict_api_name: 'ownership', dict_type: '0' }).then(res => {
        this.options.ownership = res.dict_list;
      });
      // 是否医保
      GetDictOptionList({ dict_api_name: 'is_medical_insurance', dict_type: '0' }).then(res => {
        // console.log('是否医保', res);
        if (this.$route.query.editDCR) {
          this.options.is_medical_insurance = res.dict_list.map(item => {
            return { label: item.label, code: item.code };
          });
        } else {
          this.options.is_medical_insurance = res.dict_list.map(item => {
            return { label: item.label, code: Number(item.code) };
          });
        }
      });
      // 经营状态
      GetDictOptionList({ dict_api_name: 'business_status', dict_type: '0' }).then(res => {
        this.options.business_status = res.dict_list;
      });

      // 获取第二级机构类型
      await this.getTypeName();
    }
    if (this.$route.query.source === 'mid-doc' || this.$route.query.source === 'mid-org') {
      // 获取医生性别
      GetDictOptionList({ dict_api_name: 'gender', dict_type: '1' }).then(res => {
        this.options.gender = res.dict_list.map(item => {
          return { label: item.label, code: Number(item.code) };
        });
      });
    }
    if (this.$route.query.source === 'mid-org') {
      // 机构类型
      GetDictOptionList({ dict_api_name: 'hco_type', dict_type: '2' }).then(res => {
        console.log('机构类型', res.dict_list);
        this.options.org_type = res.dict_list.map(item => {
          return { label: item.label, code: Number(item.code) };
        });
      });
    }
    if (this.$route.query.source === 'mid-doc') {
      // 机构类型
      GetDictOptionList({ dict_api_name: 'hco_type', dict_type: '3' }).then(res => {
        console.log('机构类型', res.dict_list);
        this.options.org_type = res.dict_list.map(item => {
          return { label: item.label, code: Number(item.code) };
        });
      });
    }
    // console.log('配置', this.config);
    console.log(this.formData);
  },
  mounted() {
    this.screenWidth = document.body.clientWidth - 300;

    this.init();
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth - 300;
        this.tagData = JSON.parse(JSON.stringify(this.tagDataOld));
        this.cuttingTags();
      })();
    };
  },
  methods: {
    getLicenseKey(val) {
      console.log(val);
      if (val.length) {
        const str = [];
        val.forEach(item => {
          const str2 = [];
          for (const key in item) {
            if (key !== 'id') {
              str2.push(`(${this.liceseKey[key]}：${item[key]})`);
            }
          }
          str.push(`{${str2.join(',')}` + '}');
        });
        return str.join(',');
      }
      return '-';
    },
    // 删除
    handleDeletLicense(row, index) {
      console.log(row, index);
      this.$confirm('您确认要删除当前证照信息？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.editLicenseItem.splice(index, 1);
          this.newLicenseList.splice(index, 1);
          console.log(this.editLicenseItem, this.newLicenseList);
        })
        .catch(() => {});
    },
    // 取消编辑
    handleCancelLicense(row, index) {
      console.log(row, index);
      if (this.newLicenseList[index]) {
        this.editLicenseItem[index] = this.newLicenseList[index];
        row.edit = false;
        this.editLicenseItem[index].edit = false;
        this.newLicenseList[index].edit = false;
      } else {
        this.editLicenseItem.splice(index, 1);
      }
    },
    // 编辑已存在的证照信息
    handleEditLicense(row, index) {
      console.log(row, index);
      this.editLicenseItem[index].edit = true;
    },
    // 保存编辑的证照信息
    handleSaveLicense(row, index) {
      console.log(row, index);
      console.log(this.editLicenseItem[index], this.newLicenseList[index]);
      if (
        JSON.stringify(this.editLicenseItem[index]) === JSON.stringify(this.newLicenseList[index])
      ) {
        row.edit = false;
        this.editLicenseItem[index].edit = false;
        this.newLicenseList[index].edit = false;
      } else if (
        Object.values(row).every(item => item !== '') &&
        Object.values(row).every(item => item !== null)
      ) {
        if (this.newLicenseList.length) {
          for (let i = 0; i < this.newLicenseList.length; i++) {
            if (
              row.name === this.newLicenseList[i].name &&
              row.license_code === this.newLicenseList[i].license_code &&
              row.expire_date === this.newLicenseList[i].expire_date &&
              i !== index
            ) {
              this.$message.error('该证书信息已存在，请勿重复登记');
              return false;
            }
          }
          if (!this.newLicenseList[index]) {
            row.edit = false;
            this.newLicenseList.push({ ...row });
            console.log(this.editLicenseItem, this.newLicenseList);
          } else {
            this.newLicenseList[index] = JSON.parse(JSON.stringify(this.editLicenseItem[index]));
            this.editLicenseItem[index].edit = false;
            this.newLicenseList[index].edit = false;
            console.log(this.editLicenseItem, this.newLicenseList);
          }
        } else {
          row.edit = false;
          this.newLicenseList.push({ ...row });
          console.log(this.editLicenseItem, this.newLicenseList);
        }
      } else {
        this.$message.error('所有字段均不可为空,请检查后再次尝试保存');
      }
    },
    // 添加证照信息
    handleAddLicense() {
      // this.licenseTitle = '添加证照信息'
      // this.licenseVisible = true
      this.editLicenseItem.push({
        name: '',
        license_code: '',
        certification_date: '',
        expire_date: '',
        issuing_authority: '',
        edit: true,
      });
    },
    // 获取DCR配置
    getConfig() {
      GetConfig({})
        .then(res => {
          console.log(res);
          this.isOpenDcr = res.config.if_open_dcr;
          this.third = res.config.if_optv;
          this.dcrRole = res.config.dcr_role;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    // 返回
    handleGoBack() {
      this.$router.go(-1);
    },
    // 跳转到标签的详情页
    handleGoTag(details) {
      const query = {
        id: details.id,
      };
      const routerData = this.$router.resolve({
        path: '/dashboard/tag-manage-detail',
        query,
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },
    // 获取字段权限
    async getAuthority(name) {
      const readWrite = await GetFieldPermissions({ index_name: name, operation: 'read/write' });
      const arr = [];
      if (readWrite.field_array.length) {
        readWrite.field_array.forEach(item => {
          if (item.field_set_name) {
            arr.push(item.field_set_name);
          }
        });
        this.readWrites = [...new Set(arr)];
        console.log('去重后字段组名', this.readWrites);
      }
      const write = await GetFieldPermissions({ index_name: name, operation: 'read' });
      const arr2 = [];
      if (write.field_array.length) {
        write.field_array.forEach(item => {
          if (item.field_set_name) {
            arr2.push(item.field_set_name);
          }
        });
        this.read = [...new Set(arr2)];
        console.log('去重后只读字段组名', this.read);
      }

      if (this.readWrites.length) {
        this.config.forEach(item => {
          if (this.readWrites.includes(item.title)) {
            item.show = 'read/write';
          }
        });
      }
      if (this.read.length) {
        this.config.forEach(item => {
          if (this.read.includes(item.title)) {
            item.show = 'read';
          }
        });
      }
      this.config.forEach(item => {
        if (![...this.readWrites, ...this.read].includes(item.title)) {
          item.show = 'no';
        }
        if (item.title === '证照信息') {
          item.show = 'read/write';
        }
      });

      this.authLoading = true;
      console.log('最终所有权限', this.config);
    },

    handleCurrentChange(val) {
      console.log(val);
      this.currentDataPage = val;
      this.getDoctorList();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageDataSize = val;
      this.getDoctorList();
    },
    deleteConfirm() {
      if (!this.deleteReason) {
        this.$message.error('请填写删除原因');
      } else {
        // const params = {
        //   obj_name: 't_hcp',
        //   data_id: this.deleteDialogTarget,
        //   reason: this.deleteReason,
        // };
        // DeleteMainDataDetail(params).then(() => {
        //   this.$message.success('删除成功');
        //   setTimeout(() => {
        //     this.getDoctorList();
        //   }, 1000);
        // });
        if (!this.isOpenDcr || (this.isOpenDcr && this.role === 1 && this.dcrRole === 2)) {
          const params = {
            obj_name: 't_hcp',
            data_id: this.deleteDialogTarget,
            reason: this.deleteReason,
          };
          DeleteMainDataDetail(params).then(() => {
            this.$message.success('删除成功');
            setTimeout(() => {
              this.getDoctorList();
            }, 1000);
          });
        } else {
          const deptName =
            this.docrotTableData[0].standard_dept_name !== ''
              ? this.docrotTableData[0].standard_dept_name.split('/')
              : '';

          const req = {
            data_id: this.deleteDialogTarget,
            dcr_type: 3,
            dcr_data_type: 1,
            data_code: this.docrotTableData[0].hcp_code,
            name: this.docrotTableData[0].name,
            hcp_hco_name: this.docrotTableData[0].hco_name,
            hcp_city_name: this.docrotTableData[0].nhc_location,
            hcp_dept_name: deptName !== '' ? deptName[deptName.length - 1] : '',
            data_detail_type: '医生',
            delete_reason: this.deleteReason,
          };
          CreateDcr(req)
            .then(() => {
              this.$message.success('删除请求提交成功');
            })
            .catch(err => {
              this.$message.error(err.message);
            });

          this.showDeleteDialog = false;
          this.deleteDialogTarget = '';
        }
        // const deptName =
        //   this.docrotTableData[0].standard_dept_name !== ''
        //     ? this.docrotTableData[0].standard_dept_name.split('/')
        //     : '';

        // const req = {
        //   data_id: this.deleteDialogTarget,
        //   dcr_type: 3,
        //   dcr_data_type: 1,
        //   data_code: this.docrotTableData[0].hcp_code,
        //   name: this.docrotTableData[0].name,
        //   hcp_hco_name: this.docrotTableData[0].hco_name,
        //   hcp_city_name: this.docrotTableData[0].nhc_location,
        //   hcp_dept_name: deptName !== '' ? deptName[deptName.length - 1] : '',
        //   data_detail_type: '医生',
        //   delete_reason: this.deleteReason,
        // };
        // CreateDcr(req)
        //   .then(() => {
        //     this.$message.success('删除请求提交成功');
        //   })
        //   .catch(err => {
        //     this.$message.error(err.message);
        //   });

        // this.showDeleteDialog = false;
        // this.deleteDialogTarget = '';
      }
    },
    deleteData(id) {
      this.deleteDialogTarget = id;
      this.showDeleteDialog = true;
    },
    gotoEdit(id, name) {
      const routerData = this.$router.resolve({
        path: '/dashboard/data-detail',
        query: { id, type: 'edit', source: 'doctor', name },
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },
    gotoDetail(id, name) {
      const routerData = this.$router.resolve({
        path: '/dashboard/data-detail',
        query: { id, type: 'preview', source: 'doctor', name },
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },
    dealConfig() {
      this.doctorConfig.forEach(item => {
        this.configObj[item.property] = item;

        // 处理批量编辑的字段名称
        if (item.edit) {
          this.keyOpts.push({
            value: item.property,
            label: item.title,
            search: item.search,
          });
        }
      });
    },
    getSearchOption() {
      console.log(this.filterObject, 'search filterObject');
      const optKeys = {};
      const specialKey = ['admin_division', 'type_name', 'standard_dept_name'];
      Object.keys(this.filterObject).forEach(ele => {
        if (JSON.stringify(this.filterObject[ele]) !== '{}') {
          if (!specialKey.includes(ele) && ele !== 'keyword') {
            optKeys[ele] = this.filterObject[ele];
          } else {
            const obj = this.filterObject[ele];
            Object.keys(obj).forEach(e => {
              if (e !== 'labelList') {
                optKeys[e] = obj[e];
              }
            });
          }
        }
      });
      const searchOption = [
        {
          field_name: 'hco_id',
          data_name: 't_hcp',
          type: 2,
          key: this.$route.query.id,
        },
      ];
      if (JSON.stringify(optKeys) !== '{}') {
        Object.keys(optKeys).forEach(key => {
          searchOption.push({
            field_name: key, // 字段名称
            data_name: 't_hcp', // 表名
            type: Number(optKeys[key].type), // 筛选类型； 1-包含，2-等于
            key: optKeys[key].value,
          });
        });
      }
      console.log(searchOption, 'searchOption');
      return searchOption;
    },
    // 筛选信息初始化
    filterInit() {
      this.filterObject = this.getStorage()[this.$route.query.type] || {};
      this.updateFilterList();
      this.getDoctorList();
    },
    setStorage() {
      const filterData = this.getStorage();
      filterData[this.$route.query.type] = this.filterObject;
      localStorage.setItem('data-filter', JSON.stringify(filterData));
    },
    getStorage() {
      const str = localStorage.getItem('data-filter');
      return str ? JSON.parse(str) : {};
    },
    updateFilterList() {
      const list = [];
      const { filterObject } = this;
      Object.keys(filterObject).forEach(key => {
        if (JSON.stringify(filterObject[key]) !== '{}') {
          if (key === 'admin_division') {
            list.push({ name: key, label: filterObject.admin_division.labelList });
          } else if (key === 'type_name') {
            list.push({ name: key, label: filterObject.type_name.labelList });
          } else if (key === 'standard_dept_name') {
            list.push({ name: key, label: filterObject.standard_dept_name.labelList });
          } else if (filterObject[key].label) {
            list.push({ name: key, label: filterObject[key].label });
          }
        }
      });
      this.filterList = list;
      console.log(this.filterList, '筛选条件list');
      this.setStorage();
    },
    // 每次通过table组件原生调整筛选时更新筛选条件信息
    filterChange(filter) {
      Object.keys(filter).forEach(key => {
        this.filterObject[key] = filter[key];
      });
      this.updateFilterList();
      this.getDoctorList();
    },

    // 筛选条件被手动删除时调用table方法更新table内筛选信息
    deleteFilterItem(key) {
      this.filterObject[key] = {};
      this.$refs.table.clearFilter([key]);
      this.updateFilterList();
      this.getDoctorList();
    },

    deleteAllFilter() {
      localStorage.removeItem('data-filter');
      this.filterList = [];
      this.filterObject = {};
      this.$refs.table.clearFilter();
      setTimeout(() => {
        this.getDoctorList();
      });
    },
    getLabel(property) {
      console.log(property, 'property');
      if (property === 'keyword') return '关键字';
      if (property === 'admin_division') return '行政区划';
      return this.configObj[property].title;
    },

    search(property, val, formType, label) {
      console.log(property, val, formType, label);

      let reqType = 2;
      const propertyArr = ['professional_title', 'hcp_type', 'type_name'];

      if (formType === 'cascader' || formType === 'select') {
        if (propertyArr.includes(property)) {
          reqType = 1;
        }
        if (property === 'admin_division') {
          const area = ['province_name', 'city_name', 'district_name', 'town_name', 'village_name'];
          const labelList = label.join('/');
          const adminDivision = {};
          const areaObj = {};
          val.forEach((ele, index) => {
            areaObj[area[index]] = { label: label[index], value: label[index], formType, type: 1 };
          });
          console.log(areaObj);
          adminDivision.admin_division = areaObj;
          adminDivision.admin_division.labelList = labelList;
          this.filterChange(adminDivision);
        } else if (property === 'standard_dept_name') {
          const standardName = [
            'standard_dept_name1',
            'standard_dept_name2',
            'standard_dept_name3',
          ];
          const labelList = label.join('/');
          const standardDept = {};
          const obj = {};
          val.forEach((ele, index) => {
            obj[standardName[index]] = {
              label: label[index],
              value: label[index],
              formType,
              type: 1,
            };
          });
          standardDept.standard_dept_name = obj;
          standardDept.standard_dept_name.labelList = labelList;
          console.log(standardDept);
          this.filterChange(standardDept);
        } else if (property === 'type_name') {
          const typeNameArr = ['hco_type_name3', 'hco_type_name4', 'hco_type_name5'];
          const labelList = label.join('/');
          const typeNames = {};
          const obj = {};
          val.forEach((ele, index) => {
            obj[typeNameArr[index]] = {
              label: label[index],
              value: label[index],
              formType,
              type: 1,
            };
          });
          typeNames.type_name = obj;
          typeNames.type_name.labelList = labelList;
          console.log(typeNames);
          this.filterChange(typeNames);
        } else {
          this.filterChange({ [property]: { label, value: val, formType, type: reqType } });
        }
      } else if (formType === 'input' || formType === 'autocomplete') {
        this.filterChange({ [property]: { label: val, value: val, type: 1 } });
      }
    },
    apiData(queryString, property) {
      // 接口通过获取自动补全列表， 后期可以修改为通过关键词获取模糊查询列表
      console.log('queryString', queryString);
      const params = {
        dict_api_name: property,
        dict_type: 'doctor',
      };
      const createFilter = queryString => {
        return restaurant => {
          return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
        };
      };
      return GetDictOptionList(params).then(res => {
        console.log(res, 'GetDictOptionList');
        if (res) {
          const restaurants = res.map(item => ({
            value: item.label,
          }));
          const results = queryString ? restaurants.filter(createFilter(queryString)) : restaurants;
          return results;
        }
      });
    },
    // 获取列表的候选值
    getOptions() {
      const optionsApi = [];
      const opts = {};
      this.doctorConfig.forEach(item => {
        if (item.search === 'select') {
          let params = {
            dict_api_name: item.property,
            dict_type: DICT_TYPE.doctor,
          };
          if (item.property === 'source') {
            params = {
              dict_api_name: 'source',
              dict_type: '4',
            };
          }
          if (item.property === 'org_type' && this.$route.query.type === 'mid-org') return;
          if (item.property === 'gender' && this.$route.query.type === 'mid-doc') return;

          if (item.property === 'type_name') return;
          optionsApi.push(
            GetDictOptionList(params).then(res => {
              opts[item.property] = res.dict_list.map(opt => {
                return {
                  text: opt.label,
                  label: opt.label,
                  value: opt.code,
                };
              });
              return true;
            }),
          );
        }
      });
      Promise.all(optionsApi).then(() => {
        Object.keys(opts).forEach(key => {
          this.$set(this.valueOpts, key, opts[key]);
        });
        console.log('打印下拉配置', this.valueOpts);
      });
    },
    // 获取医生列表
    getDoctorList() {
      this.doctorListLoading = true;
      const searchOption = this.getSearchOption();
      const req = {
        search_fields: [
          'name',
          'pinyin',
          'gender',
          'hco_name',
          'hcp_type',
          'hcp_status',
          'listed_dept_name',
          'standard_dept_name',
          'professional_title',
          'nhc_professional_level',
          'nhc_professional_type',
          'nhc_location',
          'nhc_scope',
          'in_doubt',
          'source',
          'qa_persons',
          'create_user',
          'create_time',
          'update_user',
          'update_time',
          'standard_dept1',
          'standard_dept2',
          'standard_dept3',
          'standard_dept_name1',
          'standard_dept_name2',
          'standard_dept_name3',
          'id',
          'hcp_code',
          'import_ids',
        ],
        table_name: 't_hcp',
        data: '医生',
        index: this.currentDataPage,
        keyword: '',
        main_data: 't_hcp',
        page_size: this.pageDataSize,
        SearchOptions: searchOption,
      };
      SearchMainDataByType(req)
        .then(res => {
          console.log('医生', res);
          const data = res.data.map(item => {
            return JSON.parse(item);
          });
          this.dealStandardData(data);
          this.dealDataLabel(data);
          this.docrotTableData = data;
          this.doctorNum = res.total_num;
          console.log('格式化后', this.docrotTableData);
          setTimeout(() => {
            this.doctorListLoading = false;
          }, 500);
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    dealStandardData(dataList) {
      const arr = [];
      dataList.forEach(ele => {
        if (ele.standard_dept_name.indexOf(',') > -1) {
          ele.standard_dept_name = ele.standard_dept_name.replace(/,/g, '/');
        }
        const standardName1 = ele.standard_dept_name1 ? ele.standard_dept_name1 : '';
        const standardName2 = ele.standard_dept_name2 ? ele.standard_dept_name2 : '';
        const standardName3 = ele.standard_dept_name3 ? ele.standard_dept_name3 : '';
        arr.push({
          id: ele.id,
          standard_dept1: ele.standard_dept1,
          standard_dept2: ele.standard_dept2,
          standard_dept3: ele.standard_dept3,
          standard_dept_name1: standardName1,
          standard_dept_name2: standardName2,
          standard_dept_name3: standardName3,
        });
        const standardName = `${standardName1}/${standardName2}/${standardName3}`;
        ele.standard_dept_name = this.delEndStr(standardName);
      });
      this.standardDeptData = arr;
    },
    // 递归删除'/'
    delEndStr(str) {
      if (str.endsWith('/')) {
        return this.delEndStr(str.substr(0, str.length - 1));
      }
      return str;
    },
    // 处理列表中下拉菜单的label值
    dealDataLabel(list) {
      const propertyArr = ['standard_dept_name', 'type_name'];
      const reg = /^[0-9a-zA-Z]/;
      list.forEach(ele => {
        Object.keys(ele).forEach(key => {
          if ((this.isMid && key === 'professional_title') || propertyArr.includes(key)) {
            return;
          }
          if (this.propertyName.includes(key)) {
            // console.log(key, ele[key]);
            // 向下拉取数据，会导致之前转化为label的数据消失，是因为转了文字后，这里不能识别。
            // 解决方法：判断是否是数字或者字母开头，纯文字不管（已经转为label）
            if (reg.test(ele[key])) {
              ele[key] = this.getCodeLabel(key, ele[key]);
            }
          }
        });
      });
    },

    getCodeLabel(key, value) {
      const valObj = this.doctorLabelConfig.filter(item => item.property === key);
      let valArr = [];
      if (JSON.stringify(valObj) !== '{}') {
        valArr = valObj[0].value;
      }
      let res = '';
      if (valArr.length > 0) {
        const arr = valArr.filter(item => item.code == value);
        if (arr.length > 0) {
          res = arr[0].label;
        }
      }
      return res;
    },

    // 上面为列表方法 ↑

    orgTypeShow() {
      this.switchShow = false;
      this.$nextTick(() => {
        this.switchShow = true;
      });
    },
    // 获取机构类型名
    async getTypeName() {
      const res = await GetOrgTypeList({ level: 2 });
      this.options.type_name_clump = res.datas;
      this.options.type_name_clump.forEach(item => {
        if (item.type_name === REQUEST_TITLE_TYPE[this.$route.query.source]) {
          this.options.firstType = item.type_id;
          this.firstType = item;
        }
      });
    },
    // 上级机构名称模糊查询
    parentHcoRemoteMethod(query) {
      if (query !== '') {
        this.remoteLoading2 = true;
        const org = {
          hospital: '医院',
          'hospital-ji': '基层医疗卫生机构',
          'hospital-pro': '专业公共医疗卫生机构',
          'hospital-other': '其他医疗卫生机构',
          dealer: '经销商',
          shop: '药店',
        };
        const req = {
          SearchOptions: [
            {
              data_name: 't_hco',
              field_name: 'name',
              type: 1,
              key: query,
            },
            {
              data_name: 't_hco',
              field_name: 'id',
              type: 7,
              key: this.$route.query.id,
            },
          ],
          data: org[this.$route.query.source],
          index: 1,
          main_data: 't_hco',
          page_size: 20,
          search_fields: ['id', 'name'],
        };
        SearchMainDataByType(req).then(res => {
          this.options.parent_org = res.data.map(item => {
            return {
              label: JSON.parse(item).name,
              code: `${JSON.parse(item).name},${JSON.parse(item).id}`,
            };
          });
          this.remoteLoading2 = false;
        });
      }
    },
    // 医生所属机构名称模糊搜索
    remoteMethod(query) {
      if (query !== '') {
        this.remoteLoading = true;
        const req = {
          SearchOptions: [
            {
              data_name: 't_hco',
              field_name: 'name',
              type: 1,
              key: query,
            },
          ],
          index: 1,
          main_data: 't_hco',
          page_size: 20,
          search_fields: ['id', 'name'],
        };
        SearchMainDataByType(req).then(res => {
          this.options.hco_org = res.data.map(item => {
            return {
              label: JSON.parse(item).name,
              code: `${JSON.parse(item).name},${JSON.parse(item).id}`,
            };
          });
          this.remoteLoading = false;
        });
      }
    },
    handleInputConfirm(property) {
      const { inputValue } = this;
      if (inputValue) {
        this.formData[property].push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput[0].$refs.input.focus();
      });
    },
    handleInputConfirm1(property) {
      const { inputValue1 } = this;
      if (inputValue1) {
        this.formData[property].push(inputValue1);
      }
      this.inputVisible1 = false;
      this.inputValue1 = '';
    },
    showInput1() {
      this.inputVisible1 = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput1[0].$refs.input.focus();
      });
    },
    handleClose(tag, property) {
      console.log(property);
      this.formData[property].splice(this.formData[property].indexOf(tag), 1);
    },
    // 初始化
    init() {
      const { type, id, source, editDCR } = this.$route.query;
      if (type !== 'new' && !editDCR) {
        if (type === undefined || id === undefined || source === undefined) {
          this.$message.error('未指定数据详情');
          this.$router.push('home');
        }
      }

      // const { breadcrumb } = store.state.user;

      // let breadname = '';
      // switch (type) {
      //   case 'new':
      //     breadname = `新建${REQUEST_TITLE_TYPE[source]}`;
      //     break;
      //   case 'edit':
      //     breadname = `编辑${REQUEST_TITLE_TYPE[source]}`;
      //     break;
      //   case 'preview':
      //     breadname = `${REQUEST_TITLE_TYPE[source]}详情`;
      //     break;
      //   default:
      //     this.$message.error('非法操作类型');
      //     this.$router.push('home');
      //     return;
      // }
      // breadcrumb.push({
      //   name: breadname,
      //   path: '/dashboard/data-list',
      //   query: { type, id },
      // });

      // store.commit('user/setBreadcrumb', breadcrumb);

      // DCR进入
      if (this.$route.query.editDCR === '新建') {
        this.loading = true;
        this.config.forEach(block => {
          block.itemList.forEach(item => {
            if (item.editType === 'tag' || item.editType === 'formerNameTag') {
              this.$set(this.dcrInitData, item.property, []);
            } else if (item.property === 'hco_type') {
              this.$set(
                this.dcrInitData,
                item.property,
                REQUEST_TITLE_TYPE[this.$route.query.source],
              );
            } else if (item.property === 'hcp_status' || item.property === 'hcp_type') {
              this.$set(this.dcrInitData, item.property, item.data);
            } else {
              this.$set(this.dcrInitData, item.property, '');
            }
            // 配置校验规则
            if (item.required) {
              this.rules[item.property] = [
                { required: true, message: `${item.name}不可为空`, trigger: 'blur' },
              ];
            }
          });
        });
        // console.log('dcr初始数据', this.dcrInitData);
        // this.initial = JSON.parse(JSON.stringify(this.dcrInitData));
        QueryDcrDetails({ id: this.$route.query.dcrId, index: 0, page_size: 0 }).then(res => {
          if (res.data.length) {
            const resObj = {};
            const finalObj = {};
            const admindivision = [];
            const standardDeptAll = [];
            const typeNameClump = [];
            const finadmindivision = [];
            const finstandardDeptAll = [];
            const fintypeNameClump = [];
            res.data.forEach(item => {
              resObj[item.field_name] = item.after_value;
              if (item.is_modify_final === 1) {
                finalObj[item.field_name] = item.final_value;
              }
            });
            console.log('初始的', resObj);
            console.log('最终的', finalObj);
            if (finalObj.license_info && finalObj.license_info !== '') {
              this.editLicenseItem = JSON.parse(finalObj.license_info).map(item => {
                return { ...item, edit: false };
              });
              this.newLicenseList = JSON.parse(JSON.stringify(this.editLicenseItem));
              this.dcrInitLicense = JSON.parse(JSON.stringify(this.editLicenseItem));
            } else {
              if (resObj.license_info && resObj.license_info !== '') {
                this.editLicenseItem = JSON.parse(resObj.license_info).map(item => {
                  return { ...item, edit: false };
                });
                this.newLicenseList = JSON.parse(JSON.stringify(this.editLicenseItem));
                this.dcrInitLicense = JSON.parse(JSON.stringify(this.editLicenseItem));
              }
            }

            // // 处理证照信息数据
            // if (resObj.license_info !== '') {
            //   this.editLicenseItem = JSON.parse(resObj.license_info).map((item) => {
            //     return { ...item, edit: false };
            //   });
            //   this.newLicenseList = JSON.parse(JSON.stringify(this.editLicenseItem));
            //   this.dcrInitLicense = JSON.parse(JSON.stringify(this.editLicenseItem));
            // }

            for (const [item, value] of Object.entries(resObj)) {
              for (const val in this.dcrInitData) {
                if (item === val) {
                  if (item === 'create_time') {
                    this.dcrInitData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                  } else if (item === 'update_time') {
                    this.dcrInitData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                  } else if (item === 'nhc_issue_date') {
                    this.dcrInitData[val] = dateFormat(new Date(value), 'YYYY-MM-dd');
                  } else if (item === 'establishment_date') {
                    this.dcrInitData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                  } else if (item === 'graduation_time') {
                    this.dcrInitData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                  } else {
                    this.dcrInitData[val] = value;
                  }
                }
              }
            }
            for (const [item, value] of Object.entries(resObj)) {
              if (item === 'hco_name') {
                this.dcrInitData.hco_org = value;
                this.dcrInitData.hco_name = value;
              }
              if (item === 'hco_id') {
                this.dcrInitData.hco_id = value;
              }
              if (item === 'parent_hco') {
                this.dcrInitData.parent_org = value;
                this.dcrInitData.parent_hco = value;
              }
              if (item === 'parent_id') {
                this.dcrInitData.parent_id = value;
              }
              if (item === 'hco_type_name2') {
                this.dcrInitData.hco_type = value;
              }
            }

            if (
              resObj.province_code &&
              resObj.province_name &&
              resObj.province_code !== '' &&
              resObj.province_name !== ''
            ) {
              admindivision.push(`${resObj.province_code},${resObj.province_name}`);

              if (
                resObj.city_code &&
                resObj.city_name &&
                resObj.city_code !== '' &&
                resObj.city_name !== ''
              ) {
                admindivision.push(`${resObj.city_code},${resObj.city_name}`);
              }
              if (
                resObj.district_code &&
                resObj.district_name &&
                resObj.district_code !== '' &&
                resObj.district_name !== ''
              ) {
                admindivision.push(`${resObj.district_code},${resObj.district_name}`);
              }
              if (
                resObj.town_code &&
                resObj.town_name &&
                resObj.town_code !== '' &&
                resObj.town_name !== ''
              ) {
                admindivision.push(`${resObj.town_code},${resObj.town_name}`);
              }
              if (
                resObj.village_code &&
                resObj.village_name &&
                resObj.village_code !== '' &&
                resObj.village_name !== ''
              ) {
                admindivision.push(`${resObj.village_code},${resObj.village_name}`);
              }
              this.dcrInitData.admin_division = admindivision;
            }

            if (resObj.longitude && resObj.longitude !== '') {
              this.dcrInitData.position = `${resObj.longitude},${resObj.latitude}`;
            }

            if (resObj.alias_name && resObj.alias_name !== '') {
              this.dcrInitData.alias_name = resObj.alias_name.split(',');
            }
            if (resObj.former_name && resObj.former_name !== '') {
              this.dcrInitData.former_name = resObj.former_name.split(',');
            }

            if (
              resObj.standard_dept1 &&
              resObj.standard_dept_name1 &&
              resObj.standard_dept1 !== '' &&
              resObj.standard_dept_name1 !== ''
            ) {
              standardDeptAll.push(`${resObj.standard_dept1},${resObj.standard_dept_name1}`);

              if (
                resObj.standard_dept2 &&
                resObj.standard_dept_name2 &&
                resObj.standard_dept2 !== '' &&
                resObj.standard_dept_name2 !== ''
              ) {
                standardDeptAll.push(`${resObj.standard_dept2},${resObj.standard_dept_name2}`);
              }
              if (
                resObj.standard_dept3 &&
                resObj.standard_dept_name3 &&
                resObj.standard_dept3 !== '' &&
                resObj.standard_dept_name3 !== ''
              ) {
                standardDeptAll.push(`${resObj.standard_dept3},${resObj.standard_dept_name3}`);
              }
              this.dcrInitData.standard_dept_all = standardDeptAll;
            }

            if (
              resObj.hco_type3 &&
              resObj.hco_type_name3 &&
              resObj.hco_type3 !== '' &&
              resObj.hco_type_name3 !== ''
            ) {
              typeNameClump.push(`${resObj.hco_type_name3},${resObj.hco_type3}`);

              if (
                resObj.hco_type4 &&
                resObj.hco_type_name4 &&
                resObj.hco_type4 !== '' &&
                resObj.hco_type_name4 !== ''
              ) {
                typeNameClump.push(`${resObj.hco_type_name4},${resObj.hco_type4}`);
              }
              if (
                resObj.hco_type5 &&
                resObj.hco_type_name5 &&
                resObj.hco_type5 !== '' &&
                resObj.hco_type_name5 !== ''
              ) {
                typeNameClump.push(`${resObj.hco_type_name5},${resObj.hco_type5}`);
              }
              this.dcrInitData.type_name_clump = typeNameClump;
            }

            this.dcrFinalData = JSON.parse(JSON.stringify(this.dcrInitData));
            // 最终值

            if (JSON.stringify(finalObj) != '{}') {
              for (const [item, value] of Object.entries(finalObj)) {
                for (const val in this.dcrFinalData) {
                  if (item === val) {
                    if (item === 'create_time') {
                      this.dcrFinalData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                    } else if (item === 'update_time') {
                      this.dcrFinalData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                    } else if (item === 'nhc_issue_date') {
                      this.dcrFinalData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                    } else if (item === 'establishment_date') {
                      this.dcrFinalData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                    } else if (item === 'graduation_time') {
                      this.dcrFinalData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                    } else {
                      this.dcrFinalData[val] = value;
                    }
                  }
                }
              }
              for (const [item, value] of Object.entries(finalObj)) {
                if (item === 'hco_name') {
                  this.dcrFinalData.hco_org = value;
                  this.dcrFinalData.hco_name = value;
                }
                if (item === 'hco_id') {
                  this.dcrFinalData.hco_id = value;
                }
                if (item === 'parent_hco') {
                  this.dcrFinalData.parent_org = value;
                  this.dcrFinalData.parent_hco = value;
                }
                if (item === 'parent_id') {
                  this.dcrFinalData.parent_id = value;
                }
                if (item === 'hco_type_name2') {
                  this.dcrFinalData.hco_type = value;
                }
              }

              if (
                finalObj.province_code &&
                finalObj.province_name &&
                finalObj.province_code !== '' &&
                finalObj.province_name !== ''
              ) {
                finadmindivision.push(`${finalObj.province_code},${finalObj.province_name}`);

                if (
                  finalObj.city_code &&
                  finalObj.city_name &&
                  finalObj.city_code !== '' &&
                  finalObj.city_name !== ''
                ) {
                  finadmindivision.push(`${finalObj.city_code},${finalObj.city_name}`);
                }
                if (
                  finalObj.district_code &&
                  finalObj.district_name &&
                  finalObj.district_code !== '' &&
                  finalObj.district_name !== ''
                ) {
                  finadmindivision.push(`${finalObj.district_code},${finalObj.district_name}`);
                }
                if (
                  finalObj.town_code &&
                  finalObj.town_name &&
                  finalObj.town_code !== '' &&
                  finalObj.town_name !== ''
                ) {
                  finadmindivision.push(`${finalObj.town_code},${finalObj.town_name}`);
                }
                if (
                  finalObj.village_code &&
                  finalObj.village_name &&
                  finalObj.village_code !== '' &&
                  finalObj.village_name !== ''
                ) {
                  finadmindivision.push(`${finalObj.village_code},${finalObj.village_name}`);
                }
                this.dcrFinalData.admin_division = finadmindivision;
              }

              if (finalObj.longitude && finalObj.longitude !== '') {
                this.dcrFinalData.position = `${finalObj.longitude},${finalObj.latitude}`;
              }

              if (finalObj.alias_name && finalObj.alias_name !== '') {
                this.dcrFinalData.alias_name = finalObj.alias_name.split(',');
              }
              if (finalObj.former_name && finalObj.former_name !== '') {
                this.dcrFinalData.former_name = finalObj.former_name.split(',');
              }

              if (
                finalObj.standard_dept1 &&
                finalObj.standard_dept_name1 &&
                finalObj.standard_dept1 !== '' &&
                finalObj.standard_dept_name1 !== ''
              ) {
                finstandardDeptAll.push(
                  `${finalObj.standard_dept1},${finalObj.standard_dept_name1}`,
                );

                if (
                  finalObj.standard_dept2 &&
                  finalObj.standard_dept_name2 &&
                  finalObj.standard_dept2 !== '' &&
                  finalObj.standard_dept_name2 !== ''
                ) {
                  finstandardDeptAll.push(
                    `${finalObj.standard_dept2},${finalObj.standard_dept_name2}`,
                  );
                }
                if (
                  finalObj.standard_dept3 &&
                  finalObj.standard_dept_name3 &&
                  finalObj.standard_dept3 !== '' &&
                  finalObj.standard_dept_name3 !== ''
                ) {
                  finstandardDeptAll.push(
                    `${finalObj.standard_dept3},${finalObj.standard_dept_name3}`,
                  );
                }
                this.dcrFinalData.standard_dept_all = finstandardDeptAll;
              }

              if (
                finalObj.hco_type3 &&
                finalObj.hco_type_name3 &&
                finalObj.hco_type3 !== '' &&
                finalObj.hco_type_name3 !== ''
              ) {
                fintypeNameClump.push(`${finalObj.hco_type_name3},${finalObj.hco_type3}`);

                if (
                  finalObj.hco_type4 &&
                  finalObj.hco_type_name4 &&
                  finalObj.hco_type4 !== '' &&
                  finalObj.hco_type_name4 !== ''
                ) {
                  fintypeNameClump.push(`${finalObj.hco_type_name4},${finalObj.hco_type4}`);
                }
                if (
                  finalObj.hco_type5 &&
                  finalObj.hco_type_name5 &&
                  finalObj.hco_type5 !== '' &&
                  finalObj.hco_type_name5 !== ''
                ) {
                  fintypeNameClump.push(`${finalObj.hco_type_name5},${finalObj.hco_type5}`);
                }
                this.dcrFinalData.type_name_clump = fintypeNameClump;
              }
            }

            this.initial = JSON.parse(JSON.stringify(this.dcrFinalData));
            this.formData = JSON.parse(JSON.stringify(this.dcrFinalData));
            console.log('新增类型的', this.formData);
            this.loading = false;
          }
        });
      }
      if (this.$route.query.editDCR === '修改') {
        this.loading = true;
        // 首先获取数据的详情用于初始渲染
        const arr = [];
        this.config.forEach(block => {
          block.itemList.forEach(item => {
            arr.push(item.property);
          });
        });
        arr.push('license_info');
        arr.push('province_code');
        arr.push('city_code');
        arr.push('district_code');
        arr.push('town_code');
        arr.push('village_code');

        arr.push('province_name');
        arr.push('city_name');
        arr.push('district_name');
        arr.push('town_name');
        arr.push('village_name');

        arr.push('longitude');
        arr.push('latitude');

        arr.push('parent_id');
        arr.push('parent_hco');

        arr.push('hco_type1');
        arr.push('hco_type_name1');
        arr.push('hco_type2');
        arr.push('hco_type_name2');
        arr.push('hco_type3');
        arr.push('hco_type_name3');
        arr.push('hco_type4');
        arr.push('hco_type_name4');
        arr.push('hco_type5');
        arr.push('hco_type_name5');

        if (this.$route.query.source === 'doctor') {
          arr.push('hco_id');
          arr.push('hco_name');
          arr.push('standard_dept_id');
          arr.push('standard_dept_name');
          arr.push('standard_dept1');
          arr.push('standard_dept_name1');
          arr.push('standard_dept2');
          arr.push('standard_dept_name2');
          arr.push('standard_dept3');
          arr.push('standard_dept_name3');

          arr.push('hcp_code');
        }
        const req = {
          obj_name: this.$route.query.source !== 'doctor' ? 't_hco' : 't_hcp',
          data_id: this.$route.query.dataId,
          fields: arr,
        };
        GetMainDataDetail(req).then(res => {
          const data = JSON.parse(res.data_detail);
          data.is_medical_insurance = String(data.is_medical_insurance);

          data.create_time = dateFormat(new Date(data.create_time), 'YYYY-MM-dd HH:mm:SS');
          data.update_time = dateFormat(new Date(data.update_time), 'YYYY-MM-dd HH:mm:SS');
          if (data.graduation_time !== '') {
            data.graduation_time = dateFormat(
              new Date(data.graduation_time),
              'YYYY-MM-dd HH:mm:SS',
            );
          }
          const admind = [];
          if (data.province_code !== '' && data.province_name !== '') {
            admind.push(`${data.province_code},${data.province_name}`);
          }
          if (data.city_code !== '' && data.city_name !== '') {
            admind.push(`${data.city_code},${data.city_name}`);
          }
          if (data.district_code !== '' && data.district_name !== '') {
            admind.push(`${data.district_code},${data.district_name}`);
          }
          if (data.town_code !== '' && data.town_name !== '') {
            admind.push(`${data.town_code},${data.town_name}`);
          }
          if (data.village_code !== '' && data.village_name !== '') {
            admind.push(`${data.village_code},${data.village_name}`);
          }
          data.admin_division = admind;
          if (data.longitude !== '') {
            data.position = `${data.longitude},${data.latitude}`;
          }
          if (data.longitude === '') {
            data.position = '';
          }
          if (data.alias_name?.indexOf(',') !== -1) {
            data.alias_name = data.alias_name?.split(',');
          } else if (data.alias_name === '') {
            data.alias_name = [];
          } else {
            data.alias_name = [data.alias_name];
          }
          if (data.former_name?.indexOf(',') !== -1) {
            data.former_name = data.former_name?.split(',');
          } else if (data.former_name === '') {
            data.former_name = [];
          } else {
            data.former_name = [data.former_name];
          }
          data.hco_org = data.hco_name;
          data.parent_org = data.parent_hco;

          if (
            data.establishment_date === '0001-01-01T00:00:00Z' ||
            data.establishment_date === null
          ) {
            data.establishment_date = '';
          }
          if (data.establishment_date !== '') {
            data.establishment_date = dateFormat(new Date(data.establishment_date), 'YYYY-MM-dd');
          }
          if (data.nhc_issue_date === '0001-01-01T00:00:00Z') {
            data.nhc_issue_date = '';
          }
          if (data.nhc_issue_date !== '') {
            data.nhc_issue_date = dateFormat(new Date(data.nhc_issue_date), 'YYYY-MM-dd');
          }
          if (this.$route.query.source === 'doctor') {
            const standardDeptAll = [];
            if (data.standard_dept1 !== '' && data.standard_dept_name1 !== '') {
              standardDeptAll.push(`${data.standard_dept1},${data.standard_dept_name1}`);
            }
            if (data.standard_dept2 !== '' && data.standard_dept_name2 !== '') {
              standardDeptAll.push(`${data.standard_dept2},${data.standard_dept_name2}`);
            }
            if (data.standard_dept3 !== '' && data.standard_dept_name3 !== '') {
              standardDeptAll.push(`${data.standard_dept3},${data.standard_dept_name3}`);
            }
            data.standard_dept_all = standardDeptAll;
          }
          // 二级分类
          data.hco_type = data.hco_type_name2;
          // 机构类型名
          const typeNameClump = [];
          if (data.hco_type3 !== '' && data.hco_type_name3 !== '') {
            typeNameClump.push(`${data.hco_type_name3},${data.hco_type3}`);
          }
          if (data.hco_type4 !== '' && data.hco_type_name4 !== '') {
            typeNameClump.push(`${data.hco_type_name4},${data.hco_type4}`);
          }
          if (data.hco_type5 !== '' && data.hco_type_name5 !== '') {
            typeNameClump.push(`${data.hco_type_name5},${data.hco_type5}`);
          }
          data.type_name_clump = typeNameClump;
          // console.log('data',data);
          this.depData = JSON.parse(JSON.stringify(data));
          console.log('此时初始', this.depData);
          if (this.depData.license_info !== '') {
            this.dcrInitLicense = JSON.parse(this.depData.license_info);
          }

          QueryDcrDetails({ id: this.$route.query.dcrId, index: 0, page_size: 0 }).then(res => {
            if (res.data.length) {
              this.dcrInitData = JSON.parse(JSON.stringify(this.depData));
              // console.log('在这里的',this.dcrInitData);
              // const resObj = {};
              const updateObj = {};
              const finalObj = {};
              // const admindivision = [];
              // const standardDeptAll = [];
              // const typeNameClump = [];
              const updateadmindivision = [];
              const updatestandardDeptAll = [];
              const updatetypeNameClump = [];
              const finadmindivision = [];
              const finstandardDeptAll = [];
              const fintypeNameClump = [];
              const finadmindivision1 = [];
              const finstandardDeptAll1 = [];
              const fintypeNameClump1 = [];
              res.data.forEach(item => {
                // resObj[item.field_name] = item.before_value;
                updateObj[item.field_name] = item.after_value;
                if (item.is_modify_final === 1) {
                  finalObj[item.field_name] = item.final_value;
                }
              });

              this.dcrUpdateData = JSON.parse(JSON.stringify(this.dcrInitData));
              console.log(updateObj);

              // 处理证照信息
              if (finalObj.license_info) {
                if (finalObj.license_info !== '') {
                  this.dcrFinaLicense = JSON.parse(finalObj.license_info);
                  const dep = JSON.parse(finalObj.license_info).map(item => {
                    return {
                      ...item,
                      edit: false,
                    };
                  });
                  this.editLicenseItem = JSON.parse(JSON.stringify(dep));
                  this.newLicenseList = JSON.parse(JSON.stringify(this.editLicenseItem));
                } else {
                  this.dcrFinaLicense = [];
                  this.editLicenseItem = [];
                  this.newLicenseList = [];
                }
              } else {
                if (updateObj.license_info) {
                  if (updateObj.license_info !== '') {
                    this.dcrUpdaeLicense = JSON.parse(updateObj.license_info);
                    const dep = JSON.parse(updateObj.license_info).map(item => {
                      return {
                        ...item,
                        edit: false,
                      };
                    });
                    this.editLicenseItem = JSON.parse(JSON.stringify(dep));
                    this.newLicenseList = JSON.parse(JSON.stringify(this.editLicenseItem));
                  } else {
                    this.dcrUpdaeLicense = [];
                    this.editLicenseItem = [];
                    this.newLicenseList = [];
                  }
                }
              }

              console.log(this.dcrInitLicense, this.dcrUpdaeLicense, this.dcrFinaLicense);
              if (JSON.stringify(this.dcrInitLicense) !== JSON.stringify(this.dcrUpdaeLicense)) {
                this.licenseIsUpdate = true;
              }

              for (const [item, value] of Object.entries(updateObj)) {
                for (const val in this.dcrUpdateData) {
                  if (item === val) {
                    if (item === 'create_time') {
                      this.dcrUpdateData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                    } else if (item === 'update_time') {
                      this.dcrUpdateData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                    } else if (item === 'nhc_issue_date') {
                      this.dcrUpdateData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                    } else if (item === 'establishment_date') {
                      this.dcrUpdateData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                    } else if (item === 'graduation_time') {
                      this.dcrUpdateData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                    } else {
                      this.dcrUpdateData[val] = value;
                    }
                  }
                }
              }
              for (const [item, value] of Object.entries(updateObj)) {
                if (item === 'hco_name') {
                  this.dcrUpdateData.hco_org = value;
                  this.dcrUpdateData.hco_name = value;
                }
                if (item === 'hco_id') {
                  this.dcrUpdateData.hco_id = value;
                }
                if (item === 'parent_hco') {
                  this.dcrUpdateData.parent_org = value;
                  this.dcrUpdateData.parent_hco = value;
                }
                if (item === 'parent_id') {
                  this.dcrUpdateData.parent_id = value;
                }
                if (item === 'hco_type_name2') {
                  this.dcrUpdateData.hco_type = value;
                }
              }

              if (
                updateObj.province_code &&
                updateObj.province_name &&
                updateObj.province_code !== '' &&
                updateObj.province_name !== ''
              ) {
                updateadmindivision.push(`${updateObj.province_code},${updateObj.province_name}`);

                if (
                  updateObj.city_code &&
                  updateObj.city_name &&
                  updateObj.city_code !== '' &&
                  updateObj.city_name !== ''
                ) {
                  updateadmindivision.push(`${updateObj.city_code},${updateObj.city_name}`);
                }
                if (
                  updateObj.district_code &&
                  updateObj.district_name &&
                  updateObj.district_code !== '' &&
                  updateObj.district_name !== ''
                ) {
                  updateadmindivision.push(`${updateObj.district_code},${updateObj.district_name}`);
                }
                if (
                  updateObj.town_code &&
                  updateObj.town_name &&
                  updateObj.town_code !== '' &&
                  updateObj.town_name !== ''
                ) {
                  updateadmindivision.push(`${updateObj.town_code},${updateObj.town_name}`);
                }
                if (
                  updateObj.village_code &&
                  updateObj.village_name &&
                  updateObj.village_code !== '' &&
                  updateObj.village_name !== ''
                ) {
                  updateadmindivision.push(`${updateObj.village_code},${updateObj.village_name}`);
                }
                this.dcrUpdateData.admin_division = updateadmindivision;
              }

              if (updateObj.longitude && updateObj.longitude !== '') {
                this.dcrUpdateData.position = `${updateObj.longitude},${updateObj.latitude}`;
              }

              if (updateObj.alias_name && updateObj.alias_name !== '') {
                this.dcrUpdateData.alias_name = updateObj.alias_name.split(',');
              }
              if (updateObj.former_name && updateObj.former_name !== '') {
                this.dcrUpdateData.former_name = updateObj.former_name.split(',');
              }

              if (
                updateObj.standard_dept1 &&
                updateObj.standard_dept_name1 &&
                updateObj.standard_dept1 !== '' &&
                updateObj.standard_dept_name1 !== ''
              ) {
                updatestandardDeptAll.push(
                  `${updateObj.standard_dept1},${updateObj.standard_dept_name1}`,
                );

                if (
                  updateObj.standard_dept2 &&
                  updateObj.standard_dept_name2 &&
                  updateObj.standard_dept2 !== '' &&
                  updateObj.standard_dept_name2 !== ''
                ) {
                  updatestandardDeptAll.push(
                    `${updateObj.standard_dept2},${updateObj.standard_dept_name2}`,
                  );
                }
                if (
                  updateObj.standard_dept3 &&
                  updateObj.standard_dept_name3 &&
                  updateObj.standard_dept3 !== '' &&
                  updateObj.standard_dept_name3 !== ''
                ) {
                  updatestandardDeptAll.push(
                    `${updateObj.standard_dept3},${updateObj.standard_dept_name3}`,
                  );
                }
                this.dcrUpdateData.standard_dept_all = updatestandardDeptAll;
              }

              if (
                updateObj.hco_type3 &&
                updateObj.hco_type_name3 &&
                updateObj.hco_type3 !== '' &&
                updateObj.hco_type_name3 !== ''
              ) {
                updatetypeNameClump.push(`${updateObj.hco_type_name3},${updateObj.hco_type3}`);

                if (
                  updateObj.hco_type4 &&
                  updateObj.hco_type_name4 &&
                  updateObj.hco_type4 !== '' &&
                  updateObj.hco_type_name4 !== ''
                ) {
                  updatetypeNameClump.push(`${updateObj.hco_type_name4},${updateObj.hco_type4}`);
                }
                if (
                  updateObj.hco_type5 &&
                  updateObj.hco_type_name5 &&
                  updateObj.hco_type5 !== '' &&
                  updateObj.hco_type_name5 !== ''
                ) {
                  updatetypeNameClump.push(`${updateObj.hco_type_name5},${updateObj.hco_type5}`);
                }
                this.dcrUpdateData.type_name_clump = updatetypeNameClump; // 拿到更新数据
              }
              console.log('更新比较后', this.dcrUpdateData);
              this.initial = JSON.parse(JSON.stringify(this.dcrUpdateData));
              // 对比出哪些字段是更新过的
              for (const [item, value] of Object.entries(this.dcrInitData)) {
                for (const [ite, val] of Object.entries(this.dcrUpdateData)) {
                  if (typeof val === 'object') {
                    if (item === ite && JSON.stringify(value) !== JSON.stringify(val)) {
                      this.isUpdate.push(item);
                    }
                  }
                  if (typeof val === 'string') {
                    if (item === ite && value !== val) {
                      this.isUpdate.push(item);
                    }
                  }
                }
              }
              console.log('更新的', this.isUpdate);

              if (JSON.stringify(finalObj != '{}')) {
                for (const [item, value] of Object.entries(finalObj)) {
                  for (const val in this.initial) {
                    if (item === val) {
                      if (item === 'create_time') {
                        this.initial[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                      } else if (item === 'update_time') {
                        this.initial[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                      } else if (item === 'nhc_issue_date') {
                        this.initial[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                      } else if (item === 'establishment_date') {
                        this.initial[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                      } else if (item === 'graduation_time') {
                        this.initial[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                      } else {
                        this.initial[val] = value;
                      }
                    }
                  }
                }
                for (const [item, value] of Object.entries(finalObj)) {
                  if (item === 'hco_name') {
                    this.initial.hco_org = value;
                    this.initial.hco_name = value;
                  }
                  if (item === 'hco_id') {
                    this.initial.hco_id = value;
                  }
                  if (item === 'parent_hco') {
                    this.initial.parent_org = value;
                    this.initial.parent_hco = value;
                  }
                  if (item === 'parent_id') {
                    this.initial.parent_id = value;
                  }
                  if (item === 'hco_type_name2') {
                    this.initial.hco_type = value;
                  }
                }

                if (
                  finalObj.province_code &&
                  finalObj.province_name &&
                  finalObj.province_code !== '' &&
                  finalObj.province_name !== ''
                ) {
                  finadmindivision.push(`${finalObj.province_code},${finalObj.province_name}`);

                  if (
                    finalObj.city_code &&
                    finalObj.city_name &&
                    finalObj.city_code !== '' &&
                    finalObj.city_name !== ''
                  ) {
                    finadmindivision.push(`${finalObj.city_code},${finalObj.city_name}`);
                  }
                  if (
                    finalObj.district_code &&
                    finalObj.district_name &&
                    finalObj.district_code !== '' &&
                    finalObj.district_name !== ''
                  ) {
                    finadmindivision.push(`${finalObj.district_code},${finalObj.district_name}`);
                  }
                  if (
                    finalObj.town_code &&
                    finalObj.town_name &&
                    finalObj.town_code !== '' &&
                    finalObj.town_name !== ''
                  ) {
                    finadmindivision.push(`${finalObj.town_code},${finalObj.town_name}`);
                  }
                  if (
                    finalObj.village_code &&
                    finalObj.village_name &&
                    finalObj.village_code !== '' &&
                    finalObj.village_name !== ''
                  ) {
                    finadmindivision.push(`${finalObj.village_code},${finalObj.village_name}`);
                  }
                  this.initial.admin_division = finadmindivision;
                }

                if (finalObj.longitude && finalObj.longitude !== '') {
                  this.initial.position = `${finalObj.longitude},${finalObj.latitude}`;
                }

                if (finalObj.alias_name && finalObj.alias_name !== '') {
                  this.initial.alias_name = finalObj.alias_name.split(',');
                }
                if (finalObj.former_name && finalObj.former_name !== '') {
                  this.initial.former_name = finalObj.former_name.split(',');
                }

                if (
                  finalObj.standard_dept1 &&
                  finalObj.standard_dept_name1 &&
                  finalObj.standard_dept1 !== '' &&
                  finalObj.standard_dept_name1 !== ''
                ) {
                  finstandardDeptAll.push(
                    `${finalObj.standard_dept1},${finalObj.standard_dept_name1}`,
                  );

                  if (
                    finalObj.standard_dept2 &&
                    finalObj.standard_dept_name2 &&
                    finalObj.standard_dept2 !== '' &&
                    finalObj.standard_dept_name2 !== ''
                  ) {
                    finstandardDeptAll.push(
                      `${finalObj.standard_dept2},${finalObj.standard_dept_name2}`,
                    );
                  }
                  if (
                    finalObj.standard_dept3 &&
                    finalObj.standard_dept_name3 &&
                    finalObj.standard_dept3 !== '' &&
                    finalObj.standard_dept_name3 !== ''
                  ) {
                    finstandardDeptAll.push(
                      `${finalObj.standard_dept3},${finalObj.standard_dept_name3}`,
                    );
                  }
                  this.initial.standard_dept_all = finstandardDeptAll;
                }

                if (
                  finalObj.hco_type3 &&
                  finalObj.hco_type_name3 &&
                  finalObj.hco_type3 !== '' &&
                  finalObj.hco_type_name3 !== ''
                ) {
                  fintypeNameClump.push(`${finalObj.hco_type_name3},${finalObj.hco_type3}`);

                  if (
                    finalObj.hco_type4 &&
                    finalObj.hco_type_name4 &&
                    finalObj.hco_type4 !== '' &&
                    finalObj.hco_type_name4 !== ''
                  ) {
                    fintypeNameClump.push(`${finalObj.hco_type_name4},${finalObj.hco_type4}`);
                  }
                  if (
                    finalObj.hco_type5 &&
                    finalObj.hco_type_name5 &&
                    finalObj.hco_type5 !== '' &&
                    finalObj.hco_type_name5 !== ''
                  ) {
                    fintypeNameClump.push(`${finalObj.hco_type_name5},${finalObj.hco_type5}`);
                  }
                  this.initial.type_name_clump = fintypeNameClump; // 拿到更新数据
                }

                // 拿到最终值用于感叹号展示
                for (const [item, value] of Object.entries(finalObj)) {
                  for (const val in this.dcrFinalData) {
                    if (item === val) {
                      if (item === 'create_time') {
                        this.dcrFinalData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                      } else if (item === 'update_time') {
                        this.dcrFinalData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                      } else if (item === 'nhc_issue_date') {
                        this.dcrFinalData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                      } else if (item === 'establishment_date') {
                        this.dcrFinalData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                      } else if (item === 'graduation_time') {
                        this.dcrFinalData[val] = dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
                      } else {
                        this.dcrFinalData[val] = value;
                      }
                    }
                  }
                }
                for (const [item, value] of Object.entries(finalObj)) {
                  if (item === 'hco_name') {
                    this.dcrFinalData.hco_org = value;
                    this.dcrFinalData.hco_name = value;
                  }
                  if (item === 'hco_id') {
                    this.dcrFinalData.hco_id = value;
                  }
                  if (item === 'parent_hco') {
                    this.dcrFinalData.parent_org = value;
                    this.dcrFinalData.parent_hco = value;
                  }
                  if (item === 'parent_id') {
                    this.dcrFinalData.parent_id = value;
                  }
                  if (item === 'hco_type_name2') {
                    this.dcrFinalData.hco_type = value;
                  }
                }

                if (
                  finalObj.province_code &&
                  finalObj.province_name &&
                  finalObj.province_code !== '' &&
                  finalObj.province_name !== ''
                ) {
                  finadmindivision1.push(`${finalObj.province_code},${finalObj.province_name}`);
                  if (
                    finalObj.city_code &&
                    finalObj.city_name &&
                    finalObj.city_code !== '' &&
                    finalObj.city_name !== ''
                  ) {
                    finadmindivision1.push(`${finalObj.city_code},${finalObj.city_name}`);
                  }
                  if (
                    finalObj.district_code &&
                    finalObj.district_name &&
                    finalObj.district_code !== '' &&
                    finalObj.district_name !== ''
                  ) {
                    finadmindivision1.push(`${finalObj.district_code},${finalObj.district_name}`);
                  }
                  if (
                    finalObj.town_code &&
                    finalObj.town_name &&
                    finalObj.town_code !== '' &&
                    finalObj.town_name !== ''
                  ) {
                    finadmindivision1.push(`${finalObj.town_code},${finalObj.town_name}`);
                  }
                  if (
                    finalObj.village_code &&
                    finalObj.village_name &&
                    finalObj.village_code !== '' &&
                    finalObj.village_name !== ''
                  ) {
                    finadmindivision1.push(`${finalObj.village_code},${finalObj.village_name}`);
                  }
                  this.dcrFinalData.admin_division = finadmindivision1;
                }

                if (finalObj.longitude && finalObj.longitude !== '') {
                  this.dcrFinalData.position = `${finalObj.longitude},${finalObj.latitude}`;
                }

                if (finalObj.alias_name && finalObj.alias_name !== '') {
                  this.dcrFinalData.alias_name = finalObj.alias_name.split(',');
                }
                if (finalObj.former_name && finalObj.former_name !== '') {
                  this.dcrFinalData.former_name = finalObj.former_name.split(',');
                }

                if (
                  finalObj.standard_dept1 &&
                  finalObj.standard_dept_name1 &&
                  finalObj.standard_dept1 !== '' &&
                  finalObj.standard_dept_name1 !== ''
                ) {
                  finstandardDeptAll1.push(
                    `${finalObj.standard_dept1},${finalObj.standard_dept_name1}`,
                  );
                  if (
                    finalObj.standard_dept2 &&
                    finalObj.standard_dept_name2 &&
                    finalObj.standard_dept2 !== '' &&
                    finalObj.standard_dept_name2 !== ''
                  ) {
                    finstandardDeptAll1.push(
                      `${finalObj.standard_dept2},${finalObj.standard_dept_name2}`,
                    );
                  }
                  if (
                    finalObj.standard_dept3 &&
                    finalObj.standard_dept_name3 &&
                    finalObj.standard_dept3 !== '' &&
                    finalObj.standard_dept_name3 !== ''
                  ) {
                    finstandardDeptAll1.push(
                      `${finalObj.standard_dept3},${finalObj.standard_dept_name3}`,
                    );
                  }
                  this.dcrFinalData.standard_dept_all = finstandardDeptAll1;
                }

                if (
                  finalObj.hco_type3 &&
                  finalObj.hco_type_name3 &&
                  finalObj.hco_type3 !== '' &&
                  finalObj.hco_type_name3 !== ''
                ) {
                  fintypeNameClump1.push(`${finalObj.hco_type_name3},${finalObj.hco_type3}`);

                  if (
                    finalObj.hco_type4 &&
                    finalObj.hco_type_name4 &&
                    finalObj.hco_type4 !== '' &&
                    finalObj.hco_type_name4 !== ''
                  ) {
                    fintypeNameClump1.push(`${finalObj.hco_type_name4},${finalObj.hco_type4}`);
                  }
                  if (
                    finalObj.hco_type5 &&
                    finalObj.hco_type_name5 &&
                    finalObj.hco_type5 !== '' &&
                    finalObj.hco_type_name5 !== ''
                  ) {
                    fintypeNameClump1.push(`${finalObj.hco_type_name5},${finalObj.hco_type5}`);
                  }
                  this.dcrFinalData.type_name_clump = fintypeNameClump1; // 拿到更新数据
                }
              }
              this.formData = JSON.parse(JSON.stringify(this.initial));
              console.log('最终的', this.formData);
              this.formData.gender = String(this.formData.gender);
              this.loading = false;
            }
          });
        });
      }

      if (this.$route.query.type === 'edit') {
        this.loading = true;
        const arr = [];
        this.config.forEach(block => {
          block.itemList.forEach(item => {
            arr.push(item.property);
            // 根据配置补充data
            // this.$set(this.formData, item.property, JSON.parse(JSON.stringify(item.data)));
            // 配置校验规则
            if (item.required) {
              this.rules[item.property] = [
                { required: true, message: `${item.name}不可为空`, trigger: 'blur' },
              ];
            }
          });
        });
        arr.push('license_info');
        arr.push('province_code');
        arr.push('city_code');
        arr.push('district_code');
        arr.push('town_code');
        arr.push('village_code');

        arr.push('province_name');
        arr.push('city_name');
        arr.push('district_name');
        arr.push('town_name');
        arr.push('village_name');

        arr.push('longitude');
        arr.push('latitude');

        arr.push('parent_id');
        arr.push('parent_hco');

        // arr.push('type_name');
        // arr.push('type_id');

        arr.push('hco_type1');
        arr.push('hco_type_name1');
        arr.push('hco_type2');
        arr.push('hco_type_name2');
        arr.push('hco_type3');
        arr.push('hco_type_name3');
        arr.push('hco_type4');
        arr.push('hco_type_name4');
        arr.push('hco_type5');
        arr.push('hco_type_name5');

        if (this.$route.query.source === 'doctor') {
          arr.push('hco_id');
          arr.push('hco_name');
          arr.push('standard_dept_id');
          arr.push('standard_dept_name');
          arr.push('standard_dept1');
          arr.push('standard_dept_name1');
          arr.push('standard_dept2');
          arr.push('standard_dept_name2');
          arr.push('standard_dept3');
          arr.push('standard_dept_name3');

          arr.push('hcp_code');
        }
        const req = {
          obj_name: this.$route.query.source !== 'doctor' ? 't_hco' : 't_hcp',
          data_id: this.$route.query.id,
          fields: arr,
        };
        GetMainDataDetail(req)
          .then(res => {
            const reportReq = {
              behavior_type: 1,
              target_content: this.$route.query.id,
              target_content_type: REQUEST_TITLE_TYPE[this.$route.query.source],
              target_content_name: this.$route.query.name,
            };
            DataReport(reportReq)
              .then(res => {
                console.log(res);
              })
              .catch(err => {
                this.$message.error(err.message);
              });
            const data = JSON.parse(res.data_detail);

            data.create_time = dateFormat(new Date(data.create_time), 'YYYY-MM-dd HH:mm:SS');
            data.update_time = dateFormat(new Date(data.update_time), 'YYYY-MM-dd HH:mm:SS');
            if (data.graduation_time !== '') {
              data.graduation_time = dateFormat(
                new Date(data.graduation_time),
                'YYYY-MM-dd HH:mm:SS',
              );
            }
            const admindivision = [];
            if (data.province_code !== '' && data.province_name !== '') {
              admindivision.push(`${data.province_code},${data.province_name}`);
            }
            if (data.city_code !== '' && data.city_name !== '') {
              admindivision.push(`${data.city_code},${data.city_name}`);
            }
            if (data.district_code !== '' && data.district_name !== '') {
              admindivision.push(`${data.district_code},${data.district_name}`);
            }
            if (data.town_code !== '' && data.town_name !== '') {
              admindivision.push(`${data.town_code},${data.town_name}`);
            }
            if (data.village_code !== '' && data.village_name !== '') {
              admindivision.push(`${data.village_code},${data.village_name}`);
            }
            data.admin_division = admindivision;
            if (data.longitude !== '') {
              data.position = `${data.longitude},${data.latitude}`;
            }
            if (data.longitude === '') {
              data.position = '';
            }
            if (data.alias_name?.indexOf(',') !== -1) {
              data.alias_name = data.alias_name?.split(',').filter(item => item !== '');
            } else if (data.alias_name === '') {
              data.alias_name = [];
            } else {
              data.alias_name = [data.alias_name].filter(item => item !== '');
            }
            if (data.former_name?.indexOf(',') !== -1) {
              data.former_name = data.former_name?.split(',').filter(item => item !== '');
            } else if (data.former_name === '') {
              data.former_name = [];
            } else {
              data.former_name = [data.former_name].filter(item => item !== '');
            }
            data.hco_org = data.hco_name;
            data.parent_org = data.parent_hco;

            if (
              data.establishment_date === '0001-01-01T00:00:00Z' ||
              data.establishment_date === null
            ) {
              data.establishment_date = '';
            }
            if (data.establishment_date !== '') {
              data.establishment_date = dateFormat(new Date(data.establishment_date), 'YYYY-MM-dd');
            }
            if (data.nhc_issue_date === '0001-01-01T00:00:00Z') {
              data.nhc_issue_date = '';
            }
            if (data.nhc_issue_date !== '') {
              data.nhc_issue_date = dateFormat(new Date(data.nhc_issue_date), 'YYYY-MM-dd');
            }
            if (this.$route.query.source === 'doctor') {
              const standardDeptAll = [];
              if (data.standard_dept1 !== '' && data.standard_dept_name1 !== '') {
                standardDeptAll.push(`${data.standard_dept1},${data.standard_dept_name1}`);
              }
              if (data.standard_dept2 !== '' && data.standard_dept_name2 !== '') {
                standardDeptAll.push(`${data.standard_dept2},${data.standard_dept_name2}`);
              }
              if (data.standard_dept3 !== '' && data.standard_dept_name3 !== '') {
                standardDeptAll.push(`${data.standard_dept3},${data.standard_dept_name3}`);
              }
              data.standard_dept_all = standardDeptAll;
            }
            // data.source = `${data.source}`;
            // 二级分类
            data.hco_type = data.hco_type_name2;
            // 机构类型名
            const typeNameClump = [];
            if (data.hco_type3 !== '' && data.hco_type_name3 !== '') {
              typeNameClump.push(`${data.hco_type_name3},${data.hco_type3}`);
            }
            if (data.hco_type4 !== '' && data.hco_type_name4 !== '') {
              typeNameClump.push(`${data.hco_type_name4},${data.hco_type4}`);
            }
            if (data.hco_type5 !== '' && data.hco_type_name5 !== '') {
              typeNameClump.push(`${data.hco_type_name5},${data.hco_type5}`);
            }
            data.type_name_clump = typeNameClump;
            this.formData = data;
            // this.remoteMethod(this.formData.hco_org);
            this.initial = JSON.parse(JSON.stringify(data));
            console.log('编辑', this.formData);

            // 证照信息
            if (this.formData.license_info && this.formData.license_info !== '') {
              this.editLicenseItem = JSON.parse(this.formData.license_info).map(item => {
                return { ...item, edit: false };
              });
              this.newLicenseList = JSON.parse(JSON.stringify(this.editLicenseItem));
              this.editLicenseData = JSON.parse(JSON.stringify(this.editLicenseItem));
            }
            this.loading = false;
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      }
      if (this.$route.query.type === 'preview' || this.$route.query.type === 'history') {
        this.allExportFields = [];
        this.loading = true;

        const arr = [];
        this.config.forEach(block => {
          block.itemList.forEach(item => {
            arr.push(item.property);
            if (item.name !== '版本') {
              this.allExportFields.push({ label: item.name, key: item.name });
            }
          });
        });

        if (this.$route.query.source !== 'doctor') {
          arr.push('license_info');
        }
        arr.push('daas_code');

        arr.push('tag_value_ids'); // 标签值id查询

        arr.push('province_code');
        arr.push('city_code');
        arr.push('district_code');
        arr.push('town_code');
        arr.push('village_code');

        arr.push('province_name');
        arr.push('city_name');
        arr.push('district_name');
        arr.push('town_name');
        arr.push('village_name');

        arr.push('longitude');
        arr.push('latitude');

        arr.push('parent_id');
        arr.push('parent_hco');

        // arr.push('type_name');
        // arr.push('type_id');

        arr.push('hco_type1');
        arr.push('hco_type_name1');
        arr.push('hco_type2');
        arr.push('hco_type_name2');
        arr.push('hco_type3');
        arr.push('hco_type_name3');
        arr.push('hco_type4');
        arr.push('hco_type_name4');
        arr.push('hco_type5');
        arr.push('hco_type_name5');
        if (this.$route.query.source === 'doctor') {
          arr.push('is_kol');

          arr.push('hco_name');
          arr.push('hco_id');
          arr.push('standard_dept_id');
          arr.push('standard_dept_name');
          arr.push('standard_dept1');
          arr.push('standard_dept_name1');
          arr.push('standard_dept2');
          arr.push('standard_dept_name2');
          arr.push('standard_dept3');
          arr.push('standard_dept_name3');

          arr.push('hcp_code');
        }
        const req = {
          obj_name: this.$route.query.source !== 'doctor' ? 't_hco' : 't_hcp',
          data_id: this.$route.query.id,
          fields: arr,
        };
        // 判断是否为中转数据详情
        if (this.$route.query.source === 'mid-doc' || this.$route.query.source === 'mid-org') {
          const req = {
            obj_name:
              this.$route.query.source === 'mid-org' ? 't_org_directory' : 't_hcp_directory',
            data_id: this.$route.query.id,
            fields: arr,
          };
          GetStagingDataDetail(req)
            .then(res => {
              const data = JSON.parse(res.data_detail);

              if (data.tag_value_ids !== '' && data.tag_value_ids) {
                // 获取标签
                const req = {
                  tag_value_ids: data.tag_value_ids.split(','),
                };
                GetTagInfosByValues(req)
                  .then(res => {
                    console.log('所有标签', res);
                    this.tagDataOld = res.tags.filter(item => {
                      return item.attribute === '衍生';
                    });
                    this.tagData = JSON.parse(JSON.stringify(this.tagDataOld));
                    if (this.tagDataOld.length) {
                      this.cuttingTags();
                    }
                  })
                  .catch(err => {
                    this.$message.error(err.message);
                  });
              }

              data.create_time = dateFormat(new Date(data.create_time), 'YYYY-MM-dd HH:mm:SS');
              data.update_time = dateFormat(new Date(data.update_time), 'YYYY-MM-dd HH:mm:SS');
              if (data.graduation_time !== '') {
                data.graduation_time = dateFormat(
                  new Date(data.graduation_time),
                  'YYYY-MM-dd HH:mm:SS',
                );
              }
              const admindivision = [];
              if (data.province_code !== '' && data.province_name !== '') {
                admindivision.push(`${data.province_code},${data.province_name}`);
              }
              if (data.city_code !== '' && data.city_name !== '') {
                admindivision.push(`${data.city_code},${data.city_name}`);
              }
              if (data.district_code !== '' && data.district_name !== '') {
                admindivision.push(`${data.district_code},${data.district_name}`);
              }
              if (data.town_code !== '' && data.town_name !== '') {
                admindivision.push(`${data.town_code},${data.town_name}`);
              }
              if (data.village_code !== '' && data.village_name !== '') {
                admindivision.push(`${data.village_code},${data.village_name}`);
              }

              data.admin_division = admindivision;
              if (data.alias_name?.indexOf(',') !== -1) {
                data.alias_name = data.alias_name?.split(',');
              } else if (data.alias_name === '') {
                data.alias_name = [];
              } else {
                data.alias_name = [data.alias_name];
              }
              if (data.former_name?.indexOf(',') !== -1) {
                data.former_name = data.former_name?.split(',');
              } else if (data.former_name === '') {
                data.former_name = [];
              } else {
                data.former_name = [data.former_name];
              }
              // data.hco_type = data.hco_type?.split(',');
              if (data.longitude !== '') {
                data.position = `${data.longitude},${data.latitude}`;
              }
              if (data.longitude === '') {
                data.position = '';
              }
              if (
                data.establishment_date === '0001-01-01T00:00:00Z' ||
                data.establishment_date === null
              ) {
                data.establishment_date = '';
              }
              if (data.establishment_date !== '') {
                data.establishment_date = dateFormat(
                  new Date(data.establishment_date),
                  'YYYY-MM-dd',
                );
              }
              if (data.nhc_issue_date === '0001-01-01T00:00:00Z') {
                data.nhc_issue_date = '';
              }
              if (data.nhc_issue_date !== '') {
                data.nhc_issue_date = dateFormat(new Date(data.nhc_issue_date), 'YYYY-MM-dd');
              }
              if (this.$route.query.source === 'doctor') {
                const standardDeptAll = [];
                if (data.standard_dept1 !== '' && data.standard_dept_name1 !== '') {
                  standardDeptAll.push(`${data.standard_dept1},${data.standard_dept_name1}`);
                }
                if (data.standard_dept2 !== '' && data.standard_dept_name2 !== '') {
                  standardDeptAll.push(`${data.standard_dept2},${data.standard_dept_name2}`);
                }
                if (data.standard_dept3 !== '' && data.standard_dept_name3 !== '') {
                  standardDeptAll.push(`${data.standard_dept3},${data.standard_dept_name3}`);
                }
                data.standard_dept_all = standardDeptAll;
              }
              data.source = `${data.source}`;
              // 二级分类
              data.hco_type = data.hco_type_name2;
              // 机构类型名
              const typeNameClump = [];
              if (data.hco_type3 !== '' && data.hco_type_name3 !== '') {
                typeNameClump.push(`${data.hco_type_name3},${data.hco_type3}`);
              }
              if (data.hco_type4 !== '' && data.hco_type_name4 !== '') {
                typeNameClump.push(`${data.hco_type_name4},${data.hco_type4}`);
              }
              if (data.hco_type5 !== '' && data.hco_type_name5 !== '') {
                typeNameClump.push(`${data.hco_type_name5},${data.hco_type5}`);
              }
              data.type_name_clump = typeNameClump;
              this.formData = data;
              this.initial = JSON.parse(JSON.stringify(data));
              console.log('中转详情', this.formData);
              this.loading = false;
            })
            .catch(err => {
              this.$message.error(err.message);
            });
        } else {
          GetMainDataDetail(req)
            .then(res => {
              // console.log('类型啊', REQUEST_TITLE_TYPE[this.$route.query.source]);
              if (this.$route.query.type === 'preview') {
                const reportReq = {
                  behavior_type: 1,
                  target_content: this.$route.query.id,
                  target_content_type: REQUEST_TITLE_TYPE[this.$route.query.source],
                  target_content_name: this.$route.query.name,
                };
                DataReport(reportReq)
                  .then(() => {})
                  .catch(err => {
                    this.$message.error(err.message);
                  });
              }
              const data = JSON.parse(res.data_detail);

              if (
                data.tag_value_ids !== '' &&
                data.tag_value_ids &&
                this.$route.query.type === 'preview'
              ) {
                // 获取标签
                const req = {
                  tag_value_ids: data.tag_value_ids.split(','),
                };
                GetTagInfosByValues(req)
                  .then(res => {
                    console.log('所有标签', res);
                    this.tagDataOld = res.tags.filter(item => {
                      return item.attribute === '衍生';
                    });
                    this.tagData = JSON.parse(JSON.stringify(this.tagDataOld));
                    if (this.tagDataOld.length) {
                      this.cuttingTags();
                    }
                  })
                  .catch(err => {
                    this.$message.error(err.message);
                  });
              }
              if (data.license_info !== '' && data.license_info) {
                this.licenseTable = JSON.parse(data.license_info);
              }
              data.create_time = dateFormat(new Date(data.create_time), 'YYYY-MM-dd HH:mm:SS');
              data.update_time = dateFormat(new Date(data.update_time), 'YYYY-MM-dd HH:mm:SS');
              if (data.graduation_time !== '') {
                data.graduation_time = dateFormat(
                  new Date(data.graduation_time),
                  'YYYY-MM-dd HH:mm:SS',
                );
              }
              const admindivision = [];
              if (data.province_code !== '' && data.province_name !== '') {
                admindivision.push(`${data.province_code},${data.province_name}`);
              }
              if (data.city_code !== '' && data.city_name !== '') {
                admindivision.push(`${data.city_code},${data.city_name}`);
              }
              if (data.district_code !== '' && data.district_name !== '') {
                admindivision.push(`${data.district_code},${data.district_name}`);
              }
              if (data.town_code !== '' && data.town_name !== '') {
                admindivision.push(`${data.town_code},${data.town_name}`);
              }
              if (data.village_code !== '' && data.village_name !== '') {
                admindivision.push(`${data.village_code},${data.village_name}`);
              }

              data.admin_division = admindivision;
              if (data.alias_name?.indexOf(',') !== -1) {
                data.alias_name = data.alias_name?.split(',').filter(item => item !== '');
              } else if (data.alias_name === '') {
                data.alias_name = [];
              } else {
                data.alias_name = [data.alias_name].filter(item => item !== '');
              }
              if (data.former_name?.indexOf(',') !== -1) {
                data.former_name = data.former_name?.split(',').filter(item => item !== '');
              } else if (data.former_name === '') {
                data.former_name = [];
              } else {
                data.former_name = [data.former_name].filter(item => item !== '');
              }
              // data.hco_type = data.hco_type?.split(',');
              if (data.longitude !== '') {
                data.position = `${data.longitude},${data.latitude}`;
              }
              if (data.longitude === '') {
                data.position = '';
              }
              data.hco_org = data.hco_name;
              data.parent_org = data.parent_hco;

              if (
                data.establishment_date === '0001-01-01T00:00:00Z' ||
                data.establishment_date === null
              ) {
                data.establishment_date = '';
              }
              if (data.establishment_date !== '') {
                data.establishment_date = dateFormat(
                  new Date(data.establishment_date),
                  'YYYY-MM-dd',
                );
              }
              if (data.nhc_issue_date === '0001-01-01T00:00:00Z') {
                data.nhc_issue_date = '';
              }
              if (data.nhc_issue_date !== '') {
                data.nhc_issue_date = dateFormat(new Date(data.nhc_issue_date), 'YYYY-MM-dd');
              }
              if (this.$route.query.source === 'doctor') {
                const standardDeptAll = [];
                if (data.standard_dept1 !== '' && data.standard_dept_name1 !== '') {
                  standardDeptAll.push(`${data.standard_dept1},${data.standard_dept_name1}`);
                }
                if (data.standard_dept2 !== '' && data.standard_dept_name2 !== '') {
                  standardDeptAll.push(`${data.standard_dept2},${data.standard_dept_name2}`);
                }
                if (data.standard_dept3 !== '' && data.standard_dept_name3 !== '') {
                  standardDeptAll.push(`${data.standard_dept3},${data.standard_dept_name3}`);
                }
                data.standard_dept_all = standardDeptAll;
              }
              data.source = `${data.source}`;
              // 二级分类
              data.hco_type = data.hco_type_name2;
              // 机构类型名
              const typeNameClump = [];
              if (data.hco_type3 !== '' && data.hco_type_name3 !== '') {
                typeNameClump.push(`${data.hco_type_name3},${data.hco_type3}`);
              }
              if (data.hco_type4 !== '' && data.hco_type_name4 !== '') {
                typeNameClump.push(`${data.hco_type_name4},${data.hco_type4}`);
              }
              if (data.hco_type5 !== '' && data.hco_type_name5 !== '') {
                typeNameClump.push(`${data.hco_type_name5},${data.hco_type5}`);
              }
              data.type_name_clump = typeNameClump;
              this.formData = data;
              console.log('主库详情', this.formData);
              this.initial = JSON.parse(JSON.stringify(data));
              // this.loading = false;
              if (this.$route.query.type === 'history') {
                console.log('参数', arr);
                const request = {
                  main_data: this.$route.query.source === 'doctor' ? 't_hcp' : 't_hco',
                  data_id: this.$route.query.id,
                  record_version: this.$route.query.version,
                  fields: arr,
                };
                GetDataHistoryDetail(request)
                  .then(res => {
                    console.log('历史版本数据详情', res);
                    const data = JSON.parse(res.data_detail);
                    if (data.license_info !== '' && data.license_info) {
                      this.licenseTable = JSON.parse(data.license_info);
                    }
                    data.create_time = dateFormat(
                      new Date(data.create_time),
                      'YYYY-MM-dd HH:mm:SS',
                    );
                    data.update_time = dateFormat(
                      new Date(data.update_time),
                      'YYYY-MM-dd HH:mm:SS',
                    );
                    if (data.graduation_time !== '') {
                      data.graduation_time = dateFormat(
                        new Date(data.graduation_time),
                        'YYYY-MM-dd HH:mm:SS',
                      );
                    }
                    const admindivision = [];
                    if (data.province_code !== '' && data.province_name !== '') {
                      admindivision.push(`${data.province_code},${data.province_name}`);
                    }
                    if (data.city_code !== '' && data.city_name !== '') {
                      admindivision.push(`${data.city_code},${data.city_name}`);
                    }
                    if (data.district_code !== '' && data.district_name !== '') {
                      admindivision.push(`${data.district_code},${data.district_name}`);
                    }
                    if (data.town_code !== '' && data.town_name !== '') {
                      admindivision.push(`${data.town_code},${data.town_name}`);
                    }
                    if (data.village_code !== '' && data.village_name !== '') {
                      admindivision.push(`${data.village_code},${data.village_name}`);
                    }

                    data.admin_division = admindivision;
                    if (data.alias_name?.indexOf(',') !== -1) {
                      data.alias_name = data.alias_name?.split(',').filter(item => item !== '');
                    } else if (data.alias_name === '') {
                      data.alias_name = [];
                    } else {
                      data.alias_name = [data.alias_name].filter(item => item !== '');
                    }
                    if (data.former_name?.indexOf(',') !== -1) {
                      data.former_name = data.former_name?.split(',').filter(item => item !== '');
                    } else if (data.former_name === '') {
                      data.former_name = [];
                    } else {
                      data.former_name = [data.former_name].filter(item => item !== '');
                    }
                    // data.hco_type = data.hco_type?.split(',');
                    if (data.longitude !== '') {
                      data.position = `${data.longitude},${data.latitude}`;
                    }
                    if (data.longitude === '') {
                      data.position = '';
                    }
                    data.hco_org = data.hco_name;
                    data.parent_org = data.parent_hco;

                    if (
                      data.establishment_date === '0001-01-01T00:00:00Z' ||
                      data.establishment_date === null
                    ) {
                      data.establishment_date = '';
                    }
                    if (data.establishment_date !== '') {
                      data.establishment_date = dateFormat(
                        new Date(data.establishment_date),
                        'YYYY-MM-dd',
                      );
                    }
                    if (data.nhc_issue_date === '0001-01-01T00:00:00Z') {
                      data.nhc_issue_date = '';
                    }
                    if (data.nhc_issue_date !== '') {
                      data.nhc_issue_date = dateFormat(new Date(data.nhc_issue_date), 'YYYY-MM-dd');
                    }
                    if (this.$route.query.source === 'doctor') {
                      const standardDeptAll = [];
                      if (data.standard_dept1 !== '' && data.standard_dept_name1 !== '') {
                        standardDeptAll.push(`${data.standard_dept1},${data.standard_dept_name1}`);
                      }
                      if (data.standard_dept2 !== '' && data.standard_dept_name2 !== '') {
                        standardDeptAll.push(`${data.standard_dept2},${data.standard_dept_name2}`);
                      }
                      if (data.standard_dept3 !== '' && data.standard_dept_name3 !== '') {
                        standardDeptAll.push(`${data.standard_dept3},${data.standard_dept_name3}`);
                      }
                      data.standard_dept_all = standardDeptAll;
                    }
                    data.source = `${data.source}`;
                    // 二级分类
                    data.hco_type = data.hco_type_name2;
                    // 机构类型名
                    const typeNameClump = [];
                    if (data.hco_type3 !== '' && data.hco_type_name3 !== '') {
                      typeNameClump.push(`${data.hco_type_name3},${data.hco_type3}`);
                    }
                    if (data.hco_type4 !== '' && data.hco_type_name4 !== '') {
                      typeNameClump.push(`${data.hco_type_name4},${data.hco_type4}`);
                    }
                    if (data.hco_type5 !== '' && data.hco_type_name5 !== '') {
                      typeNameClump.push(`${data.hco_type_name5},${data.hco_type5}`);
                    }
                    data.type_name_clump = typeNameClump;
                    this.historyFromData = JSON.parse(JSON.stringify(data));
                    console.log('历史版本详情', this.historyFromData);

                    for (const value in this.formData) {
                      if (this.formData[value] === null) {
                        this.formData[value] = '';
                      }
                    }
                    for (const v in this.historyFromData) {
                      if (this.historyFromData[v] === null) {
                        this.historyFromData[v] = '';
                      }
                    }
                    // console.log('格式化null后',this.formData,this.historyFromData);

                    // 对比出变化字段
                    for (const [item, value] of Object.entries(this.formData)) {
                      for (const [ite, val] of Object.entries(this.historyFromData)) {
                        if (typeof val === 'object') {
                          if (item === ite && JSON.stringify(value) !== JSON.stringify(val)) {
                            this.contrastHistory.push(item);
                          }
                        }
                        if (typeof val === 'string') {
                          if (item === ite && value !== val) {
                            this.contrastHistory.push(item);
                          }
                          if (
                            item === ite &&
                            ite === 'record_version' &&
                            Number(value) !== Number(val)
                          ) {
                            this.contrastHistory.push(item);
                          }
                        }
                        if (typeof val === 'number' || typeof value === 'number') {
                          if (
                            item === ite &&
                            ite === 'record_version' &&
                            Number(value) !== Number(val)
                          ) {
                            this.contrastHistory.push(item);
                          }
                          if (item === ite && Number(value) !== Number(val)) {
                            this.contrastHistory.push(item);
                          }
                        }
                      }
                    }
                    console.log('比较历史版本的数据', this.contrastHistory);
                    this.loading = false;
                  })
                  .catch(err => {
                    this.$message.error(err.message);
                  });
              }
              this.loading = false;
            })
            .catch(err => {
              console.log(err);
            });
        }
      }
      if (this.$route.query.type === 'new') {
        this.config.forEach(block => {
          if (block.title !== '系统字段' && block.title !== '清洗备注') {
            block.itemList.forEach(item => {
              // 新建模式下默认值设为空
              if (item.editType === 'tag' || item.editType === 'formerNameTag') {
                this.$set(this.formData, item.property, []);
              } else if (item.property === 'hco_type') {
                this.$set(
                  this.formData,
                  item.property,
                  REQUEST_TITLE_TYPE[this.$route.query.source],
                );
              } else if (item.property === 'hcp_status' || item.property === 'hcp_type') {
                this.$set(this.formData, item.property, item.data);
              } else {
                this.$set(this.formData, item.property, '');
              }
              // 配置校验规则
              if (item.required) {
                this.rules[item.property] = [
                  { required: true, message: `${item.name}不可为空`, trigger: 'blur' },
                ];
              }
            });
          }
        });
      }
    },
    submit(property, val) {
      // console.log(property);
      console.log(val);
      if (JSON.stringify(val.new) === JSON.stringify(val.old)) {
        this.$message.error('您未修改任何数据');
        return false;
      }
      this.detailEdit.push({
        field_name: property,
        before_val: val.old,
        after_val: val.new,
      });

      if (
        property === 'address' ||
        property === 'business_status' ||
        property === 'name' ||
        property === 'hco_org' ||
        property === 'hcp_status' ||
        property === 'listed_dept_name'
      ) {
        if (val.new === '') {
          this.$message.error('该字段为必填字段，不可为空！');
          return;
        }
      }
      if (property === 'postcode' && val.new !== '') {
        const reg = /^[0-9]{6}$/;
        if (!reg.test(val.new)) {
          this.$message.error('邮编不合法，请重新输入！');
          return;
        }
      }
      if (property === 'website' && val.new !== '') {
        const reg = /^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/;
        if (!reg.test(val.new)) {
          this.$message.error('输入的地址不合法，请重新输入！');
          this.formData[property] = val.old;
          return;
        }
      }

      // 给formData赋值
      if (property === 'hco_org' && val.new !== '') {
        this.formData[property] = val.new.split(',')[0];
      } else if (property === 'parent_org' && val.new !== '') {
        this.formData[property] = val.new.split(',')[0];
      } else {
        this.formData[property] = val.new;
      }

      if (property === 'hco_org') {
        if (val.old === '') {
          this.detailEdit.push({
            field_name: 'hco_id',
            before_val: '',
            after_val: val.new.split(',')[1] || '',
          });
          this.detailEdit.push({
            field_name: 'hco_name',
            before_val: '',
            after_val: val.new.split(',')[0] || '',
          });
        } else {
          this.detailEdit.push({
            field_name: 'hco_id',
            before_val: val.old.split(',')[1],
            after_val: val.new.split(',')[1],
          });
          this.detailEdit.push({
            field_name: 'hco_name',
            before_val: val.old.split(',')[0],
            after_val: val.new.split(',')[0],
          });
        }
      }
      if (property === 'parent_org') {
        if (val.old === '') {
          this.detailEdit.push({
            field_name: 'parent_id',
            before_val: '',
            after_val: val.new.split(',')[1],
          });
          this.detailEdit.push({
            field_name: 'parent_hco',
            before_val: '',
            after_val: val.new.split(',')[0],
          });
        } else {
          this.detailEdit.push({
            field_name: 'parent_id',
            before_val: val.old.split(',')[1],
            after_val: val.new.split(',')[1],
          });
          this.detailEdit.push({
            field_name: 'parent_hco',
            before_val: val.old.split(',')[0],
            after_val: val.new.split(',')[0],
          });
        }
      }
      if (property === 'establishment_date') {
        if (val.old !== '') {
          this.detailEdit.push({
            field_name: 'establishment_date',
            // before_val: dateFormat(new Date(val.old), 'YYYY-MM-dd'),
            // after_val: dateFormat(new Date(val.new), 'YYYY-MM-dd'),
            before_val: val.old,
            after_val: val.new,
          });
        } else {
          this.detailEdit.push({
            field_name: 'establishment_date',
            before_val: '',
            // after_val: dateFormat(new Date(val.new), 'YYYY-MM-dd'),
            after_val: val.new,
          });
        }
      }
      // 处理证书颁发日期
      if (property === 'nhc_issue_date') {
        if (val.old !== '') {
          this.detailEdit.push({
            field_name: 'nhc_issue_date',
            // before_val: dateFormat(new Date(val.old), 'YYYY-MM-dd'),
            // after_val: dateFormat(new Date(val.new), 'YYYY-MM-dd'),
            before_val: val.old,
            after_val: val.new,
          });
        } else {
          this.detailEdit.push({
            field_name: 'nhc_issue_date',
            before_val: '',
            // after_val: dateFormat(new Date(val.new), 'YYYY-MM-dd'),
            after_val: val.new,
          });
        }
      }
      if (property === 'type_name_clump') {
        this.detailEdit.push({
          field_name: 'hco_type3',
          before_val: val.old[0] ? `${val.old[0].split(',')[1]}` : '',
          after_val: val.new[0] ? `${val.new[0].split(',')[1]}` : '',
        });
        this.detailEdit.push({
          field_name: 'hco_type_name3',
          before_val: val.old[0] ? `${val.old[0].split(',')[0]}` : '',
          after_val: val.new[0] ? `${val.new[0].split(',')[0]}` : '',
        });

        this.detailEdit.push({
          field_name: 'hco_type4',
          before_val: val.old[1] ? `${val.old[1].split(',')[1]}` : '',
          after_val: val.new[1] ? `${val.new[1].split(',')[1]}` : '',
        });
        this.detailEdit.push({
          field_name: 'hco_type_name4',
          before_val: val.old[1] ? `${val.old[1].split(',')[0]}` : '',
          after_val: val.new[1] ? `${val.new[1].split(',')[0]}` : '',
        });

        this.detailEdit.push({
          field_name: 'hco_type5',
          before_val: val.old[2] ? `${val.old[2].split(',')[1]}` : '',
          after_val: val.new[2] ? `${val.new[2].split(',')[1]}` : '',
        });
        this.detailEdit.push({
          field_name: 'hco_type_name5',
          before_val: val.old[2] ? `${val.old[2].split(',')[0]}` : '',
          after_val: val.new[2] ? `${val.new[2].split(',')[0]}` : '',
        });
      }
      // 处理医生标准科室
      if (property === 'standard_dept_all') {
        if (val.old !== []) {
          this.detailEdit.push({
            field_name: 'standard_dept1',
            before_val: val.old[0] ? val.old[0]?.split(',')[0] : '',
            after_val: val.new[0] ? val.new[0]?.split(',')[0] : '',
          });
          this.detailEdit.push({
            field_name: 'standard_dept_name1',
            before_val: val.old[0] ? val.old[0]?.split(',')[1] : '',
            after_val: val.new[0] ? val.new[0]?.split(',')[1] : '',
          });
          this.detailEdit.push({
            field_name: 'standard_dept2',
            before_val: val.old[1] ? val.old[1]?.split(',')[0] : '',
            after_val: val.new[1] ? val.new[1]?.split(',')[0] : '',
          });
          this.detailEdit.push({
            field_name: 'standard_dept_name2',
            before_val: val.old[1] ? val.old[1]?.split(',')[1] : '',
            after_val: val.new[1] ? val.new[1]?.split(',')[1] : '',
          });
          this.detailEdit.push({
            field_name: 'standard_dept3',
            before_val: val.old[2] ? val.old[2]?.split(',')[0] : '',
            after_val: val.new[2] ? val.new[2]?.split(',')[0] : '',
          });
          this.detailEdit.push({
            field_name: 'standard_dept_name3',
            before_val: val.old[2] ? val.old[2]?.split(',')[1] : '',
            after_val: val.new[2] ? val.new[2]?.split(',')[1] : '',
          });
          this.detailEdit.push({
            field_name: 'standard_dept_id ',
            before_val: val.old[val.old.length - 1]
              ? val.old[val.old.length - 1]?.split(',')[0]
              : '',
            after_val: val.new[val.new.length - 1]
              ? val.new[val.new.length - 1]?.split(',')[0]
              : '',
          });
          this.detailEdit.push({
            field_name: 'standard_dept_name ',
            before_val: val.old[val.old.length - 1]
              ? val.old[val.old.length - 1]?.split(',')[1]
              : '',
            after_val: val.new[val.new.length - 1]
              ? val.new[val.new.length - 1]?.split(',')[1]
              : '',
          });
        } else {
          this.detailEdit.push({
            field_name: 'standard_dept1',
            before_val: '',
            after_val: val.new[0] ? val.new[0]?.split(',')[0] : '',
          });
          this.detailEdit.push({
            field_name: 'standard_dept_name1',
            before_val: '',
            after_val: val.new[0] ? val.new[0]?.split(',')[1] : '',
          });
          this.detailEdit.push({
            field_name: 'standard_dept2',
            before_val: '',
            after_val: val.new[1] ? val.new[1]?.split(',')[0] : '',
          });
          this.detailEdit.push({
            field_name: 'standard_dept_name2',
            before_val: '',
            after_val: val.new[1] ? val.new[1]?.split(',')[1] : '',
          });
          this.detailEdit.push({
            field_name: 'standard_dept3',
            before_val: '',
            after_val: val.new[2] ? val.new[2]?.split(',')[0] : '',
          });
          this.detailEdit.push({
            field_name: 'standard_dept_name3',
            before_val: '',
            after_val: val.new[2] ? val.new[2]?.split(',')[1] : '',
          });
          this.detailEdit.push({
            field_name: 'standard_dept_id ',
            before_val: '',
            after_val: val.new[val.new.length - 1]
              ? val.new[val.new.length - 1]?.split(',')[0]
              : '',
          });
          this.detailEdit.push({
            field_name: 'standard_dept_name ',
            before_val: '',
            after_val: val.new[val.new.length - 1]
              ? val.new[val.new.length - 1]?.split(',')[1]
              : '',
          });
        }
      }
      if (this.$route.query.source === 'doctor') {
        if (property === 'name') {
          this.formData.pinyin = pinyin(val.new);
          this.detailEdit.push({
            field_name: 'pinyin',
            before_val: pinyin(val.old),
            after_val: pinyin(val.new),
          });
          this.detailEdit.push({
            field_name: 'name',
            before_val: val.old,
            after_val: val.new,
          });
        }
      }
      if (!this.isOpenDcr || (this.isOpenDcr && this.role === 1 && this.dcrRole === 2)) {
        const req = {
          obj_name: this.$route.query.source !== 'doctor' ? 't_hco' : 't_hcp',
          data_id: this.$route.query.id,
          content:
            this.detailEdit.length > 0
              ? this.detailEdit
              : [{ field_name: property, before_val: val.old, after_val: val.new }],
          data_confirm: 0,
        };
        this.$store.state.user.breadcrumb = this.$store.state.user.breadcrumb.slice(0, 1);
        EditMainDataDetail(req)
          .then(res => {
            if (res.code === '0') {
              this.$message.success('编辑成功');
              this.detailEdit = [];
              this.init();
            }
            if (res.code === '1') {
              res.conflict_list.slice(0, 10).forEach(item => {
                this.repeatData.push(JSON.parse(item));
              });
              this.repeatVisible = true;
              this.detailEdit = [];
            }
            if (res.code === '2') {
              res.conflict_list.slice(0, 10).forEach(item => {
                this.repeatData.push(JSON.parse(item));
              });
              this.repeatVisible2 = true;
            }
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      } else {
        const req = {
          data_id: this.$route.query.id,
          dcr_type: 2,
          data_code:
            this.$route.query.source !== 'doctor' ? this.formData.hco_code : this.formData.hcp_code,
          dcr_data_type: this.$route.query.source !== 'doctor' ? 2 : 1,
          name: this.formData.name,
          data_detail_type: REQUEST_TITLE_TYPE[this.$route.query.source],
          content: this.detailEdit,
        };
        if (this.$route.query.source === 'doctor') {
          req.hcp_hco_name = this.formData.hco_org.split(',')[0] || '';
          req.hcp_city_name = this.formData.nhc_location;
          req.hcp_dept_name = this.formData.standard_dept_all[
            this.formData.standard_dept_all.length - 1
          ]?.split(',')[1];
        }
        CreateDcr(req)
          .then(() => {
            this.$message.success('编辑请求提交成功');
          })
          .catch(err => {
            this.$message.error(err.message);
            this.detailEdit = [];
          });
      }
    },

    test: _.throttle(
      function() {
        this.$refs.form.validate(valid => {
          if (valid) {
            if (this.$route.query.type === 'new') {
              for (const [key, value] of Object.entries(this.formData)) {
                if (key === 'admin_division') {
                  for (let i = 0; i < 5; i++) {
                    switch (i) {
                      case 0:
                        this.creatData.push({
                          field_name: 'province_code',
                          before_val: '',
                          after_val: value[0] ? `${value[0].split(',')[0]}` : '',
                        });
                        this.creatData.push({
                          field_name: 'province_name',
                          before_val: '',
                          after_val: value[0] ? `${value[0].split(',')[1]}` : '',
                        });
                        break;
                      case 1:
                        this.creatData.push({
                          field_name: 'city_code',
                          before_val: '',
                          after_val: value[1] ? `${value[1].split(',')[0]}` : '',
                        });
                        this.creatData.push({
                          field_name: 'city_name',
                          before_val: '',
                          after_val: value[1] ? `${value[1].split(',')[1]}` : '',
                        });
                        break;
                      case 2:
                        this.creatData.push({
                          field_name: 'district_code',
                          before_val: '',
                          after_val: value[2] ? `${value[2].split(',')[0]}` : '',
                        });
                        this.creatData.push({
                          field_name: 'district_name',
                          before_val: '',
                          after_val: value[2] ? `${value[2].split(',')[1]}` : '',
                        });
                        break;
                      case 3:
                        this.creatData.push({
                          field_name: 'town_code',
                          before_val: '',
                          after_val: value[3] ? `${value[3].split(',')[0]}` : '',
                        });
                        this.creatData.push({
                          field_name: 'town_name',
                          before_val: '',
                          after_val: value[3] ? `${value[3].split(',')[1]}` : '',
                        });
                        break;
                      case 4:
                        this.creatData.push({
                          field_name: 'village_code',
                          before_val: '',
                          after_val: value[4] ? `${value[4].split(',')[0]}` : '',
                        });
                        this.creatData.push({
                          field_name: 'village_name',
                          before_val: '',
                          after_val: value[4] ? `${value[4].split(',')[1]}` : '',
                        });
                        break;
                    }
                  }
                } else if (key === 'position' && value !== '') {
                  const [longitude, latitude] = value.split(',');
                  this.creatData.push({
                    field_name: 'longitude',
                    before_val: '',
                    after_val: longitude,
                  });
                  this.creatData.push({
                    field_name: 'latitude',
                    before_val: '',
                    after_val: latitude,
                  });
                }
                // 处理医生所属机构
                else if (key === 'hco_org' && value !== '') {
                  const [hcoName, hcoId] = value.split(',');
                  this.creatData.push({
                    field_name: 'hco_name',
                    before_val: '',
                    after_val: hcoName,
                  });
                  this.creatData.push({
                    field_name: 'hco_id',
                    before_val: '',
                    after_val: hcoId,
                  });
                }
                // 处理机构的上级机构名称
                else if (key === 'parent_org') {
                  if (value !== '') {
                    const [parenthco, parentId] = value.split(',');
                    this.creatData.push({
                      field_name: 'parent_id',
                      before_val: '',
                      after_val: parentId,
                    });
                    this.creatData.push({
                      field_name: 'parent_hco',
                      before_val: '',
                      after_val: parenthco,
                    });
                  } else {
                    this.creatData.push({
                      field_name: 'parent_id',
                      before_val: '',
                      after_val: '',
                    });
                    this.creatData.push({
                      field_name: 'parent_hco',
                      before_val: '',
                      after_val: '',
                    });
                  }
                }

                // // 处理机构类型名
                else if (key === 'type_name_clump') {
                  if (value !== '') {
                    this.creatData.push({
                      field_name: 'hco_type2',
                      before_val: '',
                      after_val: this.firstType.type_id,
                    });
                    this.creatData.push({
                      field_name: 'hco_type_name2',
                      before_val: '',
                      after_val: this.firstType.type_name,
                    });
                    this.creatData.push({
                      field_name: 'hco_type3',
                      before_val: '',
                      after_val: value[0] ? `${value[0]?.split(',')[1]}` : '',
                    });
                    this.creatData.push({
                      field_name: 'hco_type_name3',
                      before_val: '',
                      after_val: value[0] ? `${value[0]?.split(',')[0]}` : '',
                    });
                    this.creatData.push({
                      field_name: 'hco_type4',
                      before_val: '',
                      after_val: value[1] ? `${value[1]?.split(',')[1]}` : '',
                    });
                    this.creatData.push({
                      field_name: 'hco_type_name4',
                      before_val: '',
                      after_val: value[1] ? `${value[1]?.split(',')[0]}` : '',
                    });
                    this.creatData.push({
                      field_name: 'hco_type5',
                      before_val: '',
                      after_val: value[2] ? `${value[2]?.split(',')[1]}` : '',
                    });
                    this.creatData.push({
                      field_name: 'hco_type_name5',
                      before_val: '',
                      after_val: value[2] ? `${value[2]?.split(',')[0]}` : '',
                    });
                  } else {
                    this.creatData.push({
                      field_name: 'hco_type2',
                      before_val: '',
                      after_val: this.firstType.type_id,
                    });
                    this.creatData.push({
                      field_name: 'hco_type_name2',
                      before_val: '',
                      after_val: this.firstType.type_name,
                    });
                  }
                }
                // 处理医生标准科室
                else if (key === 'standard_dept_all') {
                  if (value !== []) {
                    this.creatData.push({
                      field_name: 'standard_dept1',
                      before_val: '',
                      after_val: value[0] ? value[0]?.split(',')[0] : '',
                    });
                    this.creatData.push({
                      field_name: 'standard_dept_name1',
                      before_val: '',
                      after_val: value[0] ? value[0]?.split(',')[1] : '',
                    });
                    this.creatData.push({
                      field_name: 'standard_dept2',
                      before_val: '',
                      after_val: value[1] ? value[1]?.split(',')[0] : '',
                    });
                    this.creatData.push({
                      field_name: 'standard_dept_name2',
                      before_val: '',
                      after_val: value[1] ? value[1]?.split(',')[1] : '',
                    });
                    this.creatData.push({
                      field_name: 'standard_dept3',
                      before_val: '',
                      after_val: value[2] ? value[2]?.split(',')[0] : '',
                    });
                    this.creatData.push({
                      field_name: 'standard_dept_name3',
                      before_val: '',
                      after_val: value[2] ? value[2]?.split(',')[1] : '',
                    });
                    this.creatData.push({
                      field_name: 'standard_dept_id',
                      before_val: '',
                      after_val: value[value.length - 1]
                        ? value[value.length - 1]?.split(',')[0]
                        : '',
                    });
                    this.creatData.push({
                      field_name: 'standard_dept_name',
                      before_val: '',
                      after_val: value[value.length - 1]
                        ? value[value.length - 1]?.split(',')[1]
                        : '',
                    });
                  } else {
                    this.creatData.push({
                      field_name: 'standard_dept1',
                      before_val: '',
                      after_val: '',
                    });
                    this.creatData.push({
                      field_name: 'standard_dept_name1',
                      before_val: '',
                      after_val: '',
                    });
                    this.creatData.push({
                      field_name: 'standard_dept2',
                      before_val: '',
                      after_val: '',
                    });
                    this.creatData.push({
                      field_name: 'standard_dept_name2',
                      before_val: '',
                      after_val: '',
                    });
                    this.creatData.push({
                      field_name: 'standard_dept3',
                      before_val: '',
                      after_val: '',
                    });
                    this.creatData.push({
                      field_name: 'standard_dept_name3',
                      before_val: '',
                      after_val: '',
                    });
                    this.creatData.push({
                      field_name: 'standard_dept_id ',
                      before_val: '',
                      after_val: '',
                    });
                    this.creatData.push({
                      field_name: 'standard_dept_name ',
                      before_val: '',
                      after_val: '',
                    });
                  }
                }
                // 处理别名
                else if (key === 'alias_name') {
                  if (value.length > 0) {
                    const val = value.toString();
                    this.creatData.push({
                      field_name: 'alias_name',
                      before_val: '',
                      after_val: val,
                    });
                  }
                }
                // 处理曾用名
                else if (key === 'former_name') {
                  if (value.length > 0) {
                    const val = value.toString();
                    this.creatData.push({
                      field_name: 'former_name',
                      before_val: '',
                      after_val: val,
                    });
                  }
                } else {
                  this.creatData.push({
                    field_name: `${key}`,
                    before_val: '',
                    after_val: `${value}`, // 避免数字类型导致无法创建
                    // after_val: value,
                  });
                }
              }
              // 给source默认赋值
              this.creatData.push({
                field_name: 'source',
                before_val: '',
                after_val: '8',
              });
              for (const value of this.creatData) {
                if (value.field_name === 'city_code' || value.field_name === 'city_name') {
                  if (value.after_val === '') {
                    this.$message.error('行政区划至少选择到市');
                    this.creatData = [];
                    return false;
                  }
                }
              }

              for (let i = 0; i < this.editLicenseItem.length; i++) {
                if (this.editLicenseItem[i].edit) {
                  this.$message.error('证照信息存在编辑未保存的数据，请确认是否需要保存后再提交');
                  return false;
                }
              }
              // 证照信息
              if (this.newLicenseList.length) {
                this.creatData.push({
                  field_name: 'license_info',
                  before_val: '',
                  after_val: JSON.stringify(
                    this.newLicenseList.map(item => {
                      return {
                        ...item,
                        edit: undefined,
                      };
                    }),
                  ),
                });
              }

              if (!this.isOpenDcr || (this.isOpenDcr && this.role === 1 && this.dcrRole === 2)) {
                const req = {
                  obj_name: this.$route.query.source !== 'doctor' ? 't_hco' : 't_hcp',
                  content: this.creatData.filter(item => item.after_val !== ''),
                  alter_time: +new Date(),
                  data_confirm: 0,
                };
                console.log(req.content);
                CreateMainDataDetail(req)
                  .then(res => {
                    if (res.code === '0') {
                      this.$message.success('新建成功');
                      this.handleBack();
                    }
                    if (res.code === '1') {
                      res.conflict_list.slice(0, 10).forEach(item => {
                        this.repeatData.push(JSON.parse(item));
                      });
                      this.repeatVisible = true;
                    }
                    if (res.code === '2') {
                      res.conflict_list.slice(0, 10).forEach(item => {
                        this.repeatData.push(JSON.parse(item));
                      });
                      this.repeatVisible2 = true;
                    }
                  })
                  .catch(err => {
                    this.$message.error(err.message);
                    this.creatData = [];
                  });
              } else {
                const req = {
                  dcr_type: 1,
                  data_code:
                    this.$route.query.source !== 'doctor'
                      ? this.formData.hco_code
                      : this.formData.hcp_code,
                  dcr_data_type: this.$route.query.source !== 'doctor' ? 2 : 1,
                  name: this.formData.name,
                  data_detail_type: REQUEST_TITLE_TYPE[this.$route.query.source],
                  content: this.creatData.filter(item => item.after_val !== ''),
                };
                if (this.$route.query.source === 'doctor') {
                  req.hcp_hco_name = this.formData.hco_org.split(',')[0] || '';
                  req.hcp_city_name = this.formData.nhc_location;
                  req.hcp_dept_name = this.formData.standard_dept_all[
                    this.formData.standard_dept_all.length - 1
                  ]?.split(',')[1];
                }
                console.log('创建dcr参数', req);
                CreateDcr(req)
                  .then(() => {
                    this.$message.success('新建请求提交成功');
                    this.handleBack();
                  })
                  .catch(err => {
                    this.$message.error(err.message);
                    this.creatData = [];
                  });
              }
            }
            if (this.$route.query.type === 'edit') {
              if (this.formData.in_doubt === 1) {
                if (this.formData.doubt_reason === '' || this.formData.doubt_reason == null) {
                  this.$message.error('请填写存疑原因');
                  return;
                }
              }
              if (this.$route.query.source !== 'doctor') {
                // 格式化数字类型的
                this.initial.hospital_sickbed_count = Number(this.initial.hospital_sickbed_count);
                this.initial.hospital_outpatient_count = Number(
                  this.initial.hospital_outpatient_count,
                );
                this.initial.hospital_department_count = Number(
                  this.initial.hospital_department_count,
                );
                this.initial.hospital_doctor_count = Number(this.initial.hospital_doctor_count);
                this.initial.nhc_multi_sites = Number(this.initial.nhc_multi_sites);
              }
              for (const item in this.formData) {
                for (const val in this.initial) {
                  if (
                    item === val &&
                    item !== 'admin_division' &&
                    item !== 'standard_dept_all' &&
                    item !== 'alias_name' &&
                    item !== 'former_name' &&
                    item !== 'type_name_clump' &&
                    item !== 'hco_org' &&
                    item !== 'parent_org' &&
                    item !== 'position'
                  ) {
                    if (JSON.stringify(this.formData[item]) !== JSON.stringify(this.initial[val])) {
                      this.content.push({
                        field_name: item,
                        before_val: `${this.initial[val]}`,
                        after_val: `${this.formData[item]}`,
                      });
                    }
                  }
                }
              }
              if (this.formData.admin_division.length < 2) {
                this.$message.error('行政区划至少选择到市');
                this.formData.admin_division = this.initial.admin_division;
                return false;
              }
              if (
                this.formData.admin_division.length > 0 &&
                JSON.stringify(this.formData.admin_division) !==
                  JSON.stringify(this.initial.admin_division)
              ) {
                for (let i = 0; i < 5; i++) {
                  if (i === 0) {
                    this.content.push({
                      field_name: 'province_code',
                      before_val: this.initial.admin_division[0]
                        ? `${this.initial.admin_division[0].split(',')[0]}`
                        : '',
                      after_val: this.formData.admin_division[0]
                        ? `${this.formData.admin_division[0].split(',')[0]}`
                        : '',
                    });
                    this.content.push({
                      field_name: 'province_name',
                      before_val: this.initial.admin_division[0]
                        ? `${this.initial.admin_division[0].split(',')[1]}`
                        : '',
                      after_val: this.formData.admin_division[0]
                        ? `${this.formData.admin_division[0].split(',')[1]}`
                        : '',
                    });
                  }
                  if (i === 1) {
                    this.content.push({
                      field_name: 'city_code',
                      before_val: this.initial.admin_division[1]
                        ? `${this.initial.admin_division[1].split(',')[0]}`
                        : '',
                      after_val: this.formData.admin_division[1]
                        ? `${this.formData.admin_division[1].split(',')[0]}`
                        : '',
                    });
                    this.content.push({
                      field_name: 'city_name',
                      before_val: this.initial.admin_division[1]
                        ? `${this.initial.admin_division[1].split(',')[1]}`
                        : '',
                      after_val: this.formData.admin_division[1]
                        ? `${this.formData.admin_division[1].split(',')[1]}`
                        : '',
                    });
                  }
                  if (i === 2) {
                    this.content.push({
                      field_name: 'district_code',
                      before_val: this.initial.admin_division[2]
                        ? `${this.initial.admin_division[2].split(',')[0]}`
                        : '',
                      after_val: this.formData.admin_division[2]
                        ? `${this.formData.admin_division[2].split(',')[0]}`
                        : '',
                    });
                    this.content.push({
                      field_name: 'district_name',
                      before_val: this.initial.admin_division[2]
                        ? `${this.initial.admin_division[2].split(',')[1]}`
                        : '',
                      after_val: this.formData.admin_division[2]
                        ? `${this.formData.admin_division[2].split(',')[1]}`
                        : '',
                    });
                  }
                  if (i === 3) {
                    this.content.push({
                      field_name: 'town_code',
                      before_val: this.initial.admin_division[3]
                        ? `${this.initial.admin_division[3].split(',')[0]}`
                        : '',
                      after_val: this.formData.admin_division[3]
                        ? `${this.formData.admin_division[3].split(',')[0]}`
                        : '',
                    });
                    this.content.push({
                      field_name: 'town_name',
                      before_val: this.initial.admin_division[3]
                        ? `${this.initial.admin_division[3].split(',')[1]}`
                        : '',
                      after_val: this.formData.admin_division[3]
                        ? `${this.formData.admin_division[3].split(',')[1]}`
                        : '',
                    });
                  }
                  if (i === 4) {
                    this.content.push({
                      field_name: 'village_code',
                      before_val: this.initial.admin_division[4]
                        ? `${this.initial.admin_division[4].split(',')[0]}`
                        : '',
                      after_val: this.formData.admin_division[4]
                        ? `${this.formData.admin_division[4].split(',')[0]}`
                        : '',
                    });
                    this.content.push({
                      field_name: 'village_name',
                      before_val: this.initial.admin_division[4]
                        ? `${this.initial.admin_division[4].split(',')[1]}`
                        : '',
                      after_val: this.formData.admin_division[4]
                        ? `${this.formData.admin_division[4].split(',')[1]}`
                        : '',
                    });
                  }
                }
              }
              // 处理经纬度逻辑
              if (
                this.formData.position !== '' &&
                JSON.stringify(this.initial.position) !== JSON.stringify(this.formData.position)
              ) {
                if (this.initial.position !== '') {
                  const [oldlongitude, oldlatitude] = this.initial.position?.split(',');
                  const [newlongitude, newlatitude] = this.formData.position?.split(',');
                  this.content.push({
                    field_name: 'longitude',
                    before_val: oldlongitude,
                    after_val: newlongitude,
                  });
                  this.content.push({
                    field_name: 'latitude',
                    before_val: oldlatitude,
                    after_val: newlatitude,
                  });
                } else {
                  const [newlongitude, newlatitude] = this.formData.position?.split(',');
                  this.content.push({
                    field_name: 'longitude',
                    before_val: '',
                    after_val: newlongitude,
                  });
                  this.content.push({
                    field_name: 'latitude',
                    before_val: '',
                    after_val: newlatitude,
                  });
                }
              }

              // 处理机构上机构名称
              if (
                this.formData.parent_org !== '' &&
                JSON.stringify(this.initial.parent_org) !==
                  JSON.stringify(this.formData.parent_org?.split(',')[0])
              ) {
                if (this.initial.parent_org !== '') {
                  this.content.push({
                    field_name: 'parent_hco',
                    before_val: this.initial.parent_hco,
                    after_val: this.formData.parent_org.split(',')[0],
                  });
                  this.content.push({
                    field_name: 'parent_id',
                    before_val: this.initial.parent_id,
                    after_val: this.formData.parent_org.split(',')[1],
                  });
                } else {
                  this.content.push({
                    field_name: 'parent_hco',
                    before_val: '',
                    after_val: this.formData.parent_org.split(',')[0],
                  });
                  this.content.push({
                    field_name: 'parent_id',
                    before_val: '',
                    after_val: this.formData.parent_org.split(',')[1],
                  });
                }
              }

              // 处理医生所属机构名称逻辑
              if (
                this.formData.hco_org !== '' &&
                JSON.stringify(this.initial.hco_org) !==
                  JSON.stringify(this.formData.hco_org?.split(',')[0])
              ) {
                if (this.initial.hco_org !== '') {
                  this.content.push({
                    field_name: 'hco_name',
                    before_val: this.initial.hco_name,
                    after_val: this.formData.hco_org.split(',')[0],
                  });
                  this.content.push({
                    field_name: 'hco_id',
                    before_val: this.initial.hco_id,
                    after_val: this.formData.hco_org.split(',')[1],
                  });
                } else {
                  this.content.push({
                    field_name: 'hco_name',
                    before_val: '',
                    after_val: this.formData.hco_org.split(',')[0],
                  });
                  this.content.push({
                    field_name: 'hco_id',
                    before_val: '',
                    after_val: this.formData.hco_org.split(',')[1],
                  });
                }
              }

              // 处理机构类型名
              if (
                JSON.stringify(this.formData.type_name_clump) !==
                JSON.stringify(this.initial.type_name_clump)
              ) {
                this.content.push({
                  field_name: 'hco_type3',
                  before_val: this.initial.type_name_clump[0]
                    ? `${this.initial.type_name_clump[0].split(',')[1]}`
                    : '',
                  after_val: this.formData.type_name_clump[0]
                    ? `${this.formData.type_name_clump[0].split(',')[1]}`
                    : '',
                });
                this.content.push({
                  field_name: 'hco_type_name3',
                  before_val: this.initial.type_name_clump[0]
                    ? `${this.initial.type_name_clump[0].split(',')[0]}`
                    : '',
                  after_val: this.formData.type_name_clump[0]
                    ? `${this.formData.type_name_clump[0].split(',')[0]}`
                    : '',
                });

                this.content.push({
                  field_name: 'hco_type4',
                  before_val: this.initial.type_name_clump[1]
                    ? `${this.initial.type_name_clump[1].split(',')[1]}`
                    : '',
                  after_val: this.formData.type_name_clump[1]
                    ? `${this.formData.type_name_clump[1].split(',')[1]}`
                    : '',
                });
                this.content.push({
                  field_name: 'hco_type_name4',
                  before_val: this.initial.type_name_clump[1]
                    ? `${this.initial.type_name_clump[1].split(',')[0]}`
                    : '',
                  after_val: this.formData.type_name_clump[1]
                    ? `${this.formData.type_name_clump[1].split(',')[0]}`
                    : '',
                });

                this.content.push({
                  field_name: 'hco_type5',
                  before_val: this.initial.type_name_clump[2]
                    ? `${this.initial.type_name_clump[2].split(',')[1]}`
                    : '',
                  after_val: this.formData.type_name_clump[2]
                    ? `${this.formData.type_name_clump[2].split(',')[1]}`
                    : '',
                });
                this.content.push({
                  field_name: 'hco_type_name5',
                  before_val: this.initial.type_name_clump[2]
                    ? `${this.initial.type_name_clump[2].split(',')[0]}`
                    : '',
                  after_val: this.formData.type_name_clump[2]
                    ? `${this.formData.type_name_clump[2].split(',')[0]}`
                    : '',
                });
              }
              // 处理别名
              if (
                this.formData.alias_name?.length > 0 &&
                JSON.stringify(this.formData.alias_name) !== JSON.stringify(this.initial.alias_name)
              ) {
                if (this.initial.alias_name.length > 0) {
                  const oldval = this.initial.alias_name.toString();
                  const newval = this.formData.alias_name.toString();
                  this.content.push({
                    field_name: 'alias_name',
                    before_val: oldval,
                    after_val: newval,
                  });
                } else {
                  const newval = this.formData.alias_name.toString();
                  this.content.push({
                    field_name: 'alias_name',
                    before_val: '',
                    after_val: newval,
                  });
                }
              }
              // 处理曾用名
              if (
                this.formData.former_name?.length > 0 &&
                JSON.stringify(this.formData.former_name) !==
                  JSON.stringify(this.initial.former_name)
              ) {
                if (this.initial.former_name.length > 0) {
                  const oldval = this.initial.former_name.toString();
                  const newval = this.formData.former_name.toString();
                  this.content.push({
                    field_name: 'former_name',
                    before_val: oldval,
                    after_val: newval,
                  });
                } else {
                  const newval = this.formData.former_name.toString();
                  this.content.push({
                    field_name: 'former_name',
                    before_val: '',
                    after_val: newval,
                  });
                }
              }
              // 处理医生标准科室
              if (this.$route.query.source === 'doctor') {
                if (
                  this.formData.standard_dept_all !== [] &&
                  JSON.stringify(this.formData.standard_dept_all) !==
                    JSON.stringify(this.initial.standard_dept_all)
                ) {
                  this.content.push({
                    field_name: 'standard_dept1',
                    before_val: this.initial.standard_dept_all[0]
                      ? this.initial.standard_dept_all[0].split(',')[0]
                      : '',
                    after_val: this.formData.standard_dept_all[0]
                      ? this.formData.standard_dept_all[0].split(',')[0]
                      : '',
                  });
                  this.content.push({
                    field_name: 'standard_dept_name1',
                    before_val: this.initial.standard_dept_all[0]
                      ? this.initial.standard_dept_all[0].split(',')[1]
                      : '',
                    after_val: this.formData.standard_dept_all[0]
                      ? this.formData.standard_dept_all[0].split(',')[1]
                      : '',
                  });
                  this.content.push({
                    field_name: 'standard_dept2',
                    before_val: this.initial.standard_dept_all[1]
                      ? this.initial.standard_dept_all[1].split(',')[0]
                      : '',
                    after_val: this.formData.standard_dept_all[1]
                      ? this.formData.standard_dept_all[1].split(',')[0]
                      : '',
                  });
                  this.content.push({
                    field_name: 'standard_dept_name2',
                    before_val: this.initial.standard_dept_all[1]
                      ? this.initial.standard_dept_all[1].split(',')[1]
                      : '',
                    after_val: this.formData.standard_dept_all[1]
                      ? this.formData.standard_dept_all[1].split(',')[1]
                      : '',
                  });
                  this.content.push({
                    field_name: 'standard_dept3',
                    before_val: this.initial.standard_dept_all[2]
                      ? this.initial.standard_dept_all[2].split(',')[0]
                      : '',
                    after_val: this.formData.standard_dept_all[2]
                      ? this.formData.standard_dept_all[2].split(',')[0]
                      : '',
                  });
                  this.content.push({
                    field_name: 'standard_dept_name3',
                    before_val: this.initial.standard_dept_all[2]
                      ? this.initial.standard_dept_all[2].split(',')[1]
                      : '',
                    after_val: this.formData.standard_dept_all[2]
                      ? this.formData.standard_dept_all[2].split(',')[1]
                      : '',
                  });
                  this.content.push({
                    field_name: 'standard_dept_id',
                    before_val: this.initial.standard_dept_id ? this.initial.standard_dept_id : '',
                    after_val: this.formData.standard_dept_all[
                      this.formData.standard_dept_all.length - 1
                    ].split(',')[0]
                      ? this.formData.standard_dept_all[
                          this.formData.standard_dept_all.length - 1
                        ].split(',')[0]
                      : '',
                  });
                  this.content.push({
                    field_name: 'standard_dept_name',
                    before_val: this.initial.standard_dept_name
                      ? this.initial.standard_dept_name
                      : '',
                    after_val: this.formData.standard_dept_all[
                      this.formData.standard_dept_all.length - 1
                    ].split(',')[1]
                      ? this.formData.standard_dept_all[
                          this.formData.standard_dept_all.length - 1
                        ].split(',')[1]
                      : '',
                  });
                }
              }
              for (let i = 0; i < this.editLicenseItem.length; i++) {
                if (this.editLicenseItem[i].edit) {
                  this.$message.error('证照信息存在编辑未保存的数据，请确认是否需要保存后再提交');
                  return false;
                }
              }
              if (JSON.stringify(this.editLicenseData) !== JSON.stringify(this.newLicenseList)) {
                this.content.push({
                  field_name: 'license_info',
                  before_val: JSON.stringify(
                    this.editLicenseData.map(item => {
                      return { ...item, edit: undefined };
                    }),
                  ),
                  after_val: JSON.stringify(
                    this.newLicenseList.map(item => {
                      return { ...item, edit: undefined };
                    }),
                  ),
                });
              }
              // console.log('修改',this.initial,this.formData);
              console.log('编辑提交', this.content);
              if (this.content.length < 1) {
                this.$message.error('您未修改任何数据');
                return false;
              }
              if (!this.isOpenDcr || (this.isOpenDcr && this.role === 1 && this.dcrRole === 2)) {
                const req = {
                  obj_name: this.$route.query.source !== 'doctor' ? 't_hco' : 't_hcp',
                  data_id: this.$route.query.id,
                  content: this.content,
                  data_confirm: 0,
                };
                EditMainDataDetail(req)
                  .then(res => {
                    if (res.code === '0') {
                      this.$message.success('编辑成功');
                      this.$route.query.type = 'preview';
                      this.$store.state.user.breadcrumb = this.$store.state.user.breadcrumb.slice(
                        0,
                        1,
                      );
                      this.content = [];
                      this.init();
                    }
                    if (res.code === '1') {
                      res.conflict_list.slice(0, 10).forEach(item => {
                        this.repeatData.push(JSON.parse(item));
                      });
                      this.repeatVisible = true;
                      this.content = [];
                    }
                    if (res.code === '2') {
                      res.conflict_list.slice(0, 10).forEach(item => {
                        this.repeatData.push(JSON.parse(item));
                      });
                      this.repeatVisible2 = true;
                    }
                  })
                  .catch(err => {
                    this.$message.error(err.message);
                  });
              } else {
                const req = {
                  data_id: this.$route.query.id,
                  dcr_type: 2,
                  data_code:
                    this.$route.query.source !== 'doctor'
                      ? this.formData.hco_code
                      : this.formData.hcp_code,
                  dcr_data_type: this.$route.query.source !== 'doctor' ? 2 : 1,
                  name: this.formData.name,
                  data_detail_type: REQUEST_TITLE_TYPE[this.$route.query.source],
                  content: this.content,
                };
                if (this.$route.query.source === 'doctor') {
                  req.hcp_hco_name = this.formData.hco_org.split(',')[0] || '';
                  req.hcp_city_name = this.formData.nhc_location;
                  req.hcp_dept_name = this.formData.standard_dept_all[
                    this.formData.standard_dept_all.length - 1
                  ]?.split(',')[1];
                }
                CreateDcr(req)
                  .then(() => {
                    this.$message.success('编辑请求提交成功');
                    this.$router.push({
                      name: 'data-list',
                      query: { type: this.$route.query.source },
                    });
                  })
                  .catch(err => {
                    this.$message.error(err.message);
                    this.content = [];
                  });
              }
            }

            // dcr提交处理逻辑
            if (this.$route.query.editDCR === '新建') {
              if (this.formData.in_doubt === 1) {
                if (this.formData.doubt_reason === '' || this.formData.doubt_reason == null) {
                  this.$message.error('请填写存疑原因');
                  return;
                }
              }
              if (this.$route.query.source !== 'doctor') {
                // 格式化数字类型的
                this.initial.hospital_sickbed_count = Number(this.initial.hospital_sickbed_count);
                this.initial.hospital_outpatient_count = Number(
                  this.initial.hospital_outpatient_count,
                );
                this.initial.hospital_department_count = Number(
                  this.initial.hospital_department_count,
                );
                this.initial.hospital_doctor_count = Number(this.initial.hospital_doctor_count);
                this.initial.nhc_multi_sites = Number(this.initial.nhc_multi_sites);
              }
              if (this.$route.query.source === 'doctor') {
                this.initial.gender = String(this.initial.gender);
              }

              for (const item in this.formData) {
                for (const val in this.initial) {
                  if (
                    item === val &&
                    item !== 'admin_division' &&
                    item !== 'standard_dept_all' &&
                    item !== 'alias_name' &&
                    item !== 'former_name' &&
                    item !== 'type_name_clump' &&
                    item !== 'hco_org' &&
                    item !== 'parent_org'
                  ) {
                    if (JSON.stringify(this.formData[item]) !== JSON.stringify(this.initial[val])) {
                      this.dcrContent.push({
                        field_name: item,
                        before_value: '',
                        after_value: `${this.initial[val]}`,
                        final_value: `${this.formData[item]}`,
                        is_modify_final: 1,
                      });
                    }
                  }
                }
              }
              if (this.$route.query.source !== 'doctor') {
                if (this.formData.admin_division.length < 2) {
                  this.$message.error('行政区划至少选择到市');
                  this.formData.admin_division = this.initial.admin_division;
                  return false;
                }
              }

              if (this.$route.query.source !== 'doctor') {
                if (
                  this.formData.admin_division.length > 0 &&
                  JSON.stringify(this.formData.admin_division) !==
                    JSON.stringify(this.initial.admin_division)
                ) {
                  for (let i = 0; i < 5; i++) {
                    if (i === 0) {
                      this.dcrContent.push({
                        field_name: 'province_code',
                        before_value: '',
                        after_value: this.initial.admin_division[0]
                          ? `${this.initial.admin_division[0].split(',')[0]}`
                          : '',
                        final_value: this.formData.admin_division[0]
                          ? `${this.formData.admin_division[0].split(',')[0]}`
                          : '',
                        is_modify_final: 1,
                      });
                      this.dcrContent.push({
                        field_name: 'province_name',
                        before_value: '',
                        after_value: this.initial.admin_division[0]
                          ? `${this.initial.admin_division[0].split(',')[1]}`
                          : '',
                        final_value: this.formData.admin_division[0]
                          ? `${this.formData.admin_division[0].split(',')[1]}`
                          : '',
                        is_modify_final: 1,
                      });
                    }
                    if (i === 1) {
                      this.dcrContent.push({
                        field_name: 'city_code',
                        before_value: '',
                        after_value: this.initial.admin_division[1]
                          ? `${this.initial.admin_division[1].split(',')[0]}`
                          : '',
                        final_value: this.formData.admin_division[1]
                          ? `${this.formData.admin_division[1].split(',')[0]}`
                          : '',
                        is_modify_final: 1,
                      });
                      this.dcrContent.push({
                        field_name: 'city_name',
                        before_value: '',
                        after_value: this.initial.admin_division[1]
                          ? `${this.initial.admin_division[1].split(',')[1]}`
                          : '',
                        final_value: this.formData.admin_division[1]
                          ? `${this.formData.admin_division[1].split(',')[1]}`
                          : '',
                        is_modify_final: 1,
                      });
                    }
                    if (i === 2) {
                      this.dcrContent.push({
                        field_name: 'district_code',
                        before_value: '',
                        after_value: this.initial.admin_division[2]
                          ? `${this.initial.admin_division[2].split(',')[0]}`
                          : '',
                        final_value: this.formData.admin_division[2]
                          ? `${this.formData.admin_division[2].split(',')[0]}`
                          : '',
                        is_modify_final: 1,
                      });
                      this.dcrContent.push({
                        field_name: 'district_name',
                        before_value: '',
                        after_value: this.initial.admin_division[2]
                          ? `${this.initial.admin_division[2].split(',')[1]}`
                          : '',
                        final_value: this.formData.admin_division[2]
                          ? `${this.formData.admin_division[2].split(',')[1]}`
                          : '',
                        is_modify_final: 1,
                      });
                    }
                    if (i === 3) {
                      this.dcrContent.push({
                        field_name: 'town_code',
                        before_value: '',
                        after_value: this.initial.admin_division[3]
                          ? `${this.initial.admin_division[3].split(',')[0]}`
                          : '',
                        final_value: this.formData.admin_division[3]
                          ? `${this.formData.admin_division[3].split(',')[0]}`
                          : '',
                        is_modify_final: 1,
                      });
                      this.dcrContent.push({
                        field_name: 'town_name',
                        before_value: '',
                        after_value: this.initial.admin_division[3]
                          ? `${this.initial.admin_division[3].split(',')[1]}`
                          : '',
                        final_value: this.formData.admin_division[3]
                          ? `${this.formData.admin_division[3].split(',')[1]}`
                          : '',
                        is_modify_final: 1,
                      });
                    }
                    if (i === 4) {
                      this.dcrContent.push({
                        field_name: 'village_code',
                        before_value: '',
                        after_value: this.initial.admin_division[4]
                          ? `${this.initial.admin_division[4].split(',')[0]}`
                          : '',
                        final_value: this.formData.admin_division[4]
                          ? `${this.formData.admin_division[4].split(',')[0]}`
                          : '',
                        is_modify_final: 1,
                      });
                      this.dcrContent.push({
                        field_name: 'village_name',
                        before_value: '',
                        after_value: this.initial.admin_division[4]
                          ? `${this.initial.admin_division[4].split(',')[1]}`
                          : '',
                        final_value: this.formData.admin_division[4]
                          ? `${this.formData.admin_division[4].split(',')[1]}`
                          : '',
                        is_modify_final: 1,
                      });
                    }
                  }
                }
              }
              // 处理经纬度逻辑
              if (
                this.formData.position !== '' &&
                JSON.stringify(this.initial.position) !== JSON.stringify(this.formData.position)
              ) {
                if (this.initial.position !== '') {
                  const [oldlongitude, oldlatitude] = this.initial.position?.split(',');
                  const [newlongitude, newlatitude] = this.formData.position?.split(',');
                  this.dcrContent.push({
                    field_name: 'longitude',
                    before_value: '',
                    after_value: oldlongitude,
                    final_value: newlongitude,
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'latitude',
                    before_value: '',
                    after_value: oldlatitude,
                    final_value: newlatitude,
                    is_modify_final: 1,
                  });
                } else {
                  const [newlongitude, newlatitude] = this.formData.position?.split(',');
                  this.dcrContent.push({
                    field_name: 'longitude',
                    before_value: '',
                    after_value: '',
                    final_value: newlongitude,
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'latitude',
                    before_value: '',
                    after_value: '',
                    final_value: newlatitude,
                    is_modify_final: 1,
                  });
                }
              }

              // 处理机构上机构名称
              if (
                this.formData.parent_org !== '' &&
                JSON.stringify(this.initial.parent_org) !==
                  JSON.stringify(this.formData.parent_org?.split(',')[0])
              ) {
                if (this.initial.parent_org !== '') {
                  this.dcrContent.push({
                    field_name: 'parent_hco',
                    before_value: '',
                    after_value: this.initial.parent_hco || '',
                    final_value: this.formData.parent_org.split(',')[0],
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'parent_id',
                    before_value: '',
                    after_value: this.initial.parent_id || '',
                    final_value: this.formData.parent_org.split(',')[1],
                    is_modify_final: 1,
                  });
                } else {
                  this.dcrContent.push({
                    field_name: 'parent_hco',
                    before_value: '',
                    after_value: '',
                    final_value: this.formData.parent_org.split(',')[0],
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'parent_id',
                    before_value: '',
                    after_value: '',
                    final_value: this.formData.parent_org.split(',')[1],
                    is_modify_final: 1,
                  });
                }
              }

              // 处理医生所属机构名称逻辑
              if (
                this.formData.hco_org !== '' &&
                JSON.stringify(this.initial.hco_org) !==
                  JSON.stringify(this.formData.hco_org?.split(',')[0])
              ) {
                if (this.initial.hco_org !== '') {
                  this.dcrContent.push({
                    field_name: 'hco_name',
                    before_value: '',
                    after_value: this.initial.hco_org || '',
                    final_value: this.formData.hco_org.split(',')[0],
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'hco_id',
                    before_value: '',
                    after_value: this.initial.hco_id || '',
                    final_value: this.formData.hco_org.split(',')[1],
                    is_modify_final: 1,
                  });
                } else {
                  this.dcrContent.push({
                    field_name: 'hco_name',
                    before_value: '',
                    after_value: '',
                    final_value: this.formData.hco_org.split(',')[0],
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'hco_id',
                    before_value: '',
                    after_value: '',
                    final_value: this.formData.hco_org.split(',')[1],
                    is_modify_final: 1,
                  });
                }
              }

              // 处理机构类型名
              if (
                JSON.stringify(this.formData.type_name_clump) !==
                JSON.stringify(this.initial.type_name_clump)
              ) {
                this.dcrContent.push({
                  field_name: 'hco_type3',
                  before_value: '',
                  after_value: this.initial.type_name_clump[0]
                    ? `${this.initial.type_name_clump[0].split(',')[1]}`
                    : '',
                  final_value: this.formData.type_name_clump[0]
                    ? `${this.formData.type_name_clump[0].split(',')[1]}`
                    : '',
                  is_modify_final: 1,
                });
                this.dcrContent.push({
                  field_name: 'hco_type_name3',
                  before_value: '',
                  after_value: this.initial.type_name_clump[0]
                    ? `${this.initial.type_name_clump[0].split(',')[0]}`
                    : '',
                  final_value: this.formData.type_name_clump[0]
                    ? `${this.formData.type_name_clump[0].split(',')[0]}`
                    : '',
                  is_modify_final: 1,
                });

                this.dcrContent.push({
                  field_name: 'hco_type4',
                  before_value: '',
                  after_value: this.initial.type_name_clump[1]
                    ? `${this.initial.type_name_clump[1].split(',')[1]}`
                    : '',
                  final_value: this.formData.type_name_clump[1]
                    ? `${this.formData.type_name_clump[1].split(',')[1]}`
                    : '',
                  is_modify_final: 1,
                });
                this.dcrContent.push({
                  field_name: 'hco_type_name4',
                  before_value: '',
                  after_value: this.initial.type_name_clump[1]
                    ? `${this.initial.type_name_clump[1].split(',')[0]}`
                    : '',
                  final_value: this.formData.type_name_clump[1]
                    ? `${this.formData.type_name_clump[1].split(',')[0]}`
                    : '',
                  is_modify_final: 1,
                });

                this.dcrContent.push({
                  field_name: 'hco_type5',
                  before_value: '',
                  after_value: this.initial.type_name_clump[2]
                    ? `${this.initial.type_name_clump[2].split(',')[1]}`
                    : '',
                  final_value: this.formData.type_name_clump[2]
                    ? `${this.formData.type_name_clump[2].split(',')[1]}`
                    : '',
                  is_modify_final: 1,
                });
                this.dcrContent.push({
                  field_name: 'hco_type_name5',
                  before_value: '',
                  after_value: this.initial.type_name_clump[2]
                    ? `${this.initial.type_name_clump[2].split(',')[0]}`
                    : '',
                  final_value: this.formData.type_name_clump[2]
                    ? `${this.formData.type_name_clump[2].split(',')[0]}`
                    : '',
                  is_modify_final: 1,
                });
              }
              // 处理别名
              if (
                this.formData.alias_name?.length > 0 &&
                JSON.stringify(this.formData.alias_name) !== JSON.stringify(this.initial.alias_name)
              ) {
                if (this.initial.alias_name.length > 0) {
                  const oldval = this.initial.alias_name.toString();
                  const newval = this.formData.alias_name.toString();
                  this.dcrContent.push({
                    field_name: 'alias_name',
                    before_value: '',
                    after_value: oldval,
                    final_value: newval,
                    is_modify_final: 1,
                  });
                } else {
                  const newval = this.formData.alias_name.toString();
                  this.dcrContent.push({
                    field_name: 'alias_name',
                    before_value: '',
                    after_value: '',
                    final_value: newval,
                    is_modify_final: 1,
                  });
                }
              }
              // 处理曾用名
              if (
                this.formData.former_name?.length > 0 &&
                JSON.stringify(this.formData.former_name) !==
                  JSON.stringify(this.initial.former_name)
              ) {
                if (this.initial.former_name.length > 0) {
                  const oldval = this.initial.former_name.toString();
                  const newval = this.formData.former_name.toString();
                  this.dcrContent.push({
                    field_name: 'former_name',
                    before_value: '',
                    after_value: oldval,
                    final_value: newval,
                    is_modify_final: 1,
                  });
                } else {
                  const newval = this.formData.former_name.toString();
                  this.dcrContent.push({
                    field_name: 'former_name',
                    before_value: '',
                    after_value: '',
                    final_value: newval,
                    is_modify_final: 1,
                  });
                }
              }
              // 处理医生标准科室
              if (this.$route.query.source === 'doctor') {
                if (
                  this.formData.standard_dept_all !== [] &&
                  JSON.stringify(this.formData.standard_dept_all) !==
                    JSON.stringify(this.initial.standard_dept_all)
                ) {
                  this.dcrContent.push({
                    field_name: 'standard_dept1',
                    before_value: '',
                    after_value: this.initial.standard_dept_all[0]
                      ? this.initial.standard_dept_all[0].split(',')[0]
                      : '',
                    final_value: this.formData.standard_dept_all[0]
                      ? this.formData.standard_dept_all[0].split(',')[0]
                      : '',
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'standard_dept_name1',
                    before_value: '',
                    after_value: this.initial.standard_dept_all[0]
                      ? this.initial.standard_dept_all[0].split(',')[1]
                      : '',
                    final_value: this.formData.standard_dept_all[0]
                      ? this.formData.standard_dept_all[0].split(',')[1]
                      : '',
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'standard_dept2',
                    before_value: '',
                    after_value: this.initial.standard_dept_all[1]
                      ? this.initial.standard_dept_all[1].split(',')[0]
                      : '',
                    final_value: this.formData.standard_dept_all[1]
                      ? this.formData.standard_dept_all[1].split(',')[0]
                      : '',
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'standard_dept_name2',
                    before_value: '',
                    after_value: this.initial.standard_dept_all[1]
                      ? this.initial.standard_dept_all[1].split(',')[1]
                      : '',
                    final_value: this.formData.standard_dept_all[1]
                      ? this.formData.standard_dept_all[1].split(',')[1]
                      : '',
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'standard_dept3',
                    before_value: '',
                    after_value: this.initial.standard_dept_all[2]
                      ? this.initial.standard_dept_all[2].split(',')[0]
                      : '',
                    final_value: this.formData.standard_dept_all[2]
                      ? this.formData.standard_dept_all[2].split(',')[0]
                      : '',
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'standard_dept_name3',
                    before_value: '',
                    after_value: this.initial.standard_dept_all[2]
                      ? this.initial.standard_dept_all[2].split(',')[1]
                      : '',
                    final_value: this.formData.standard_dept_all[2]
                      ? this.formData.standard_dept_all[2].split(',')[1]
                      : '',
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'standard_dept_id',
                    before_value: '',
                    after_value: this.initial.standard_dept_id ? this.initial.standard_dept_id : '',
                    final_value: this.formData.standard_dept_all[
                      this.formData.standard_dept_all.length - 1
                    ].split(',')[0]
                      ? this.formData.standard_dept_all[
                          this.formData.standard_dept_all.length - 1
                        ].split(',')[0]
                      : '',
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'standard_dept_name',
                    before_value: '',
                    after_value: this.initial.standard_dept_name
                      ? this.initial.standard_dept_name
                      : '',
                    final_value: this.formData.standard_dept_all[
                      this.formData.standard_dept_all.length - 1
                    ].split(',')[1]
                      ? this.formData.standard_dept_all[
                          this.formData.standard_dept_all.length - 1
                        ].split(',')[1]
                      : '',
                    is_modify_final: 1,
                  });
                }
              }

              for (let i = 0; i < this.editLicenseItem.length; i++) {
                if (this.editLicenseItem[i].edit) {
                  this.$message.error('证照信息存在编辑未保存的数据，请确认是否需要保存后再提交');
                  return false;
                }
              }
              // 证照信息
              if (JSON.stringify(this.dcrInitLicense) !== JSON.stringify(this.newLicenseList)) {
                this.dcrContent.push({
                  field_name: 'license_info',
                  before_value: '',
                  after_value: JSON.stringify(
                    this.dcrInitLicense.map(item => {
                      return { ...item, edit: undefined, type: item.name };
                    }),
                  ),
                  final_value: JSON.stringify(
                    this.newLicenseList.map(item => {
                      return { ...item, edit: undefined, type: item.name };
                    }),
                  ),
                  is_modify_final: 1,
                });
              }

              console.log('dcr编辑提交', this.dcrContent);
              if (this.dcrContent.length < 1) {
                this.$message.error('您未修改任何数据');
                return false;
              }
              const req = {
                data_type: this.$route.query.source === 'doctor' ? 't_hcp' : 't_hco',
                id: this.$route.query.dcrId,
                data: this.dcrContent,
              };
              ModifyDcrDetails(req)
                .then(res => {
                  console.log(res);
                  this.$message.success('保存成功');
                  this.$router.go(-1);
                })
                .catch(err => {
                  this.$message.error(err.message);
                });
            }

            if (this.$route.query.editDCR === '修改') {
              if (this.formData.in_doubt === 1) {
                if (this.formData.doubt_reason === '' || this.formData.doubt_reason == null) {
                  this.$message.error('请填写存疑原因');
                  return;
                }
              }
              if (this.$route.query.source !== 'doctor') {
                // 格式化数字类型的
                this.initial.hospital_sickbed_count = Number(this.initial.hospital_sickbed_count);
                this.initial.hospital_outpatient_count = Number(
                  this.initial.hospital_outpatient_count,
                );
                this.initial.hospital_department_count = Number(
                  this.initial.hospital_department_count,
                );
                this.initial.hospital_doctor_count = Number(this.initial.hospital_doctor_count);
                this.initial.nhc_multi_sites = Number(this.initial.nhc_multi_sites);
              }
              if (this.$route.query.source === 'doctor') {
                this.initial.gender = String(this.initial.gender);
              }

              for (const item in this.formData) {
                for (const val in this.initial) {
                  if (
                    item === val &&
                    item !== 'admin_division' &&
                    item !== 'standard_dept_all' &&
                    item !== 'alias_name' &&
                    item !== 'former_name' &&
                    item !== 'type_name_clump' &&
                    item !== 'hco_org' &&
                    item !== 'parent_org' &&
                    item !== 'position'
                  ) {
                    if (JSON.stringify(this.formData[item]) !== JSON.stringify(this.initial[val])) {
                      this.dcrContent.push({
                        field_name: item,
                        before_value: `${this.dcrInitData[item]}`,
                        after_value: `${this.initial[val]}`,
                        final_value: `${this.formData[item]}`,
                        is_modify_final: 1,
                      });
                    }
                  }
                }
              }
              if (this.$route.query.source !== 'doctor') {
                if (this.formData.admin_division.length < 2) {
                  this.$message.error('行政区划至少选择到市');
                  this.formData.admin_division = this.initial.admin_division;
                  return false;
                }
              }

              if (this.$route.query.source !== 'doctor') {
                if (
                  this.formData.admin_division.length > 0 &&
                  JSON.stringify(this.formData.admin_division) !==
                    JSON.stringify(this.initial.admin_division)
                ) {
                  for (let i = 0; i < 5; i++) {
                    if (i === 0) {
                      this.dcrContent.push({
                        field_name: 'province_code',
                        before_value: this.dcrInitData.admin_division[0]
                          ? `${this.dcrInitData.admin_division[0].split(',')[0]}`
                          : '',
                        after_value: this.initial.admin_division[0]
                          ? `${this.initial.admin_division[0].split(',')[0]}`
                          : '',
                        final_value: this.formData.admin_division[0]
                          ? `${this.formData.admin_division[0].split(',')[0]}`
                          : '',
                        is_modify_final: 1,
                      });
                      this.dcrContent.push({
                        field_name: 'province_name',
                        before_value: this.dcrInitData.admin_division[0]
                          ? `${this.dcrInitData.admin_division[0].split(',')[1]}`
                          : '',
                        after_value: this.initial.admin_division[0]
                          ? `${this.initial.admin_division[0].split(',')[1]}`
                          : '',
                        final_value: this.formData.admin_division[0]
                          ? `${this.formData.admin_division[0].split(',')[1]}`
                          : '',
                        is_modify_final: 1,
                      });
                    }
                    if (i === 1) {
                      this.dcrContent.push({
                        field_name: 'city_code',
                        before_value: this.dcrInitData.admin_division[1]
                          ? `${this.dcrInitData.admin_division[1].split(',')[0]}`
                          : '',
                        after_value: this.initial.admin_division[1]
                          ? `${this.initial.admin_division[1].split(',')[0]}`
                          : '',
                        final_value: this.formData.admin_division[1]
                          ? `${this.formData.admin_division[1].split(',')[0]}`
                          : '',
                        is_modify_final: 1,
                      });
                      this.dcrContent.push({
                        field_name: 'city_name',
                        before_value: this.dcrInitData.admin_division[1]
                          ? `${this.dcrInitData.admin_division[1].split(',')[1]}`
                          : '',
                        after_value: this.initial.admin_division[1]
                          ? `${this.initial.admin_division[1].split(',')[1]}`
                          : '',
                        final_value: this.formData.admin_division[1]
                          ? `${this.formData.admin_division[1].split(',')[1]}`
                          : '',
                        is_modify_final: 1,
                      });
                    }
                    if (i === 2) {
                      this.dcrContent.push({
                        field_name: 'district_code',
                        before_value: this.dcrInitData.admin_division[2]
                          ? `${this.dcrInitData.admin_division[2].split(',')[0]}`
                          : '',
                        after_value: this.initial.admin_division[2]
                          ? `${this.initial.admin_division[2].split(',')[0]}`
                          : '',
                        final_value: this.formData.admin_division[2]
                          ? `${this.formData.admin_division[2].split(',')[0]}`
                          : '',
                        is_modify_final: 1,
                      });
                      this.dcrContent.push({
                        field_name: 'district_name',
                        before_value: this.dcrInitData.admin_division[2]
                          ? `${this.dcrInitData.admin_division[2].split(',')[1]}`
                          : '',
                        after_value: this.initial.admin_division[2]
                          ? `${this.initial.admin_division[2].split(',')[1]}`
                          : '',
                        final_value: this.formData.admin_division[2]
                          ? `${this.formData.admin_division[2].split(',')[1]}`
                          : '',
                        is_modify_final: 1,
                      });
                    }
                    if (i === 3) {
                      this.dcrContent.push({
                        field_name: 'town_code',
                        before_value: this.dcrInitData.admin_division[3]
                          ? `${this.dcrInitData.admin_division[3].split(',')[0]}`
                          : '',
                        after_value: this.initial.admin_division[3]
                          ? `${this.initial.admin_division[3].split(',')[0]}`
                          : '',
                        final_value: this.formData.admin_division[3]
                          ? `${this.formData.admin_division[3].split(',')[0]}`
                          : '',
                        is_modify_final: 1,
                      });
                      this.dcrContent.push({
                        field_name: 'town_name',
                        before_value: this.dcrInitData.admin_division[3]
                          ? `${this.dcrInitData.admin_division[3].split(',')[1]}`
                          : '',
                        after_value: this.initial.admin_division[3]
                          ? `${this.initial.admin_division[3].split(',')[1]}`
                          : '',
                        final_value: this.formData.admin_division[3]
                          ? `${this.formData.admin_division[3].split(',')[1]}`
                          : '',
                        is_modify_final: 1,
                      });
                    }
                    if (i === 4) {
                      this.dcrContent.push({
                        field_name: 'village_code',
                        before_value: this.dcrInitData.admin_division[4]
                          ? `${this.dcrInitData.admin_division[4].split(',')[0]}`
                          : '',
                        after_value: this.initial.admin_division[4]
                          ? `${this.initial.admin_division[4].split(',')[0]}`
                          : '',
                        final_value: this.formData.admin_division[4]
                          ? `${this.formData.admin_division[4].split(',')[0]}`
                          : '',
                        is_modify_final: 1,
                      });
                      this.dcrContent.push({
                        field_name: 'village_name',
                        before_value: this.dcrInitData.admin_division[4]
                          ? `${this.dcrInitData.admin_division[4].split(',')[1]}`
                          : '',
                        after_value: this.initial.admin_division[4]
                          ? `${this.initial.admin_division[4].split(',')[1]}`
                          : '',
                        final_value: this.formData.admin_division[4]
                          ? `${this.formData.admin_division[4].split(',')[1]}`
                          : '',
                        is_modify_final: 1,
                      });
                    }
                  }
                }
              }
              // 处理经纬度逻辑
              if (
                this.formData.position !== '' &&
                JSON.stringify(this.initial.position) !== JSON.stringify(this.formData.position)
              ) {
                if (this.initial.position !== '') {
                  const [oldlongitude, oldlatitude] = this.initial.position?.split(',');
                  const [newlongitude, newlatitude] = this.formData.position?.split(',');
                  this.dcrContent.push({
                    field_name: 'longitude',
                    before_value:
                      this.dcrInitData.position !== ''
                        ? this.dcrInitData.position.split(',')[0]
                        : '',
                    after_value: oldlongitude,
                    final_value: newlongitude,
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'latitude',
                    before_value:
                      this.dcrInitData.position !== ''
                        ? this.dcrInitData.position.split(',')[1]
                        : '',
                    after_value: oldlatitude,
                    final_value: newlatitude,
                    is_modify_final: 1,
                  });
                } else {
                  const [newlongitude, newlatitude] = this.formData.position?.split(',');
                  this.dcrContent.push({
                    field_name: 'longitude',
                    before_value:
                      this.dcrInitData.position !== ''
                        ? this.dcrInitData.position.split(',')[0]
                        : '',
                    after_value: '',
                    final_value: newlongitude,
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'latitude',
                    before_value:
                      this.dcrInitData.position !== ''
                        ? this.dcrInitData.position.split(',')[1]
                        : '',
                    after_value: '',
                    final_value: newlatitude,
                    is_modify_final: 1,
                  });
                }
              }

              // 处理机构上机构名称
              if (
                this.formData.parent_org !== '' &&
                JSON.stringify(this.initial.parent_org) !==
                  JSON.stringify(this.formData.parent_org?.split(',')[0])
              ) {
                if (this.initial.parent_org !== '') {
                  this.dcrContent.push({
                    field_name: 'parent_hco',
                    before_value: this.dcrInitData.parent_hco || '',
                    after_value: this.initial.parent_hco || '',
                    final_value: this.formData.parent_org.split(',')[0],
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'parent_id',
                    before_value: this.dcrInitData.parent_id || '',
                    after_value: this.initial.parent_id || '',
                    final_value: this.formData.parent_org.split(',')[1],
                    is_modify_final: 1,
                  });
                } else {
                  this.dcrContent.push({
                    field_name: 'parent_hco',
                    before_value: this.dcrInitData.parent_hco || '',
                    after_value: '',
                    final_value: this.formData.parent_org.split(',')[0],
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'parent_id',
                    before_value: this.dcrInitData.parent_id || '',
                    after_value: '',
                    final_value: this.formData.parent_org.split(',')[1],
                    is_modify_final: 1,
                  });
                }
              }

              // 处理医生所属机构名称逻辑
              if (
                this.formData.hco_org !== '' &&
                JSON.stringify(this.initial.hco_org) !==
                  JSON.stringify(this.formData.hco_org?.split(',')[0])
              ) {
                if (this.initial.hco_org !== '') {
                  this.dcrContent.push({
                    field_name: 'hco_name',
                    before_value: this.dcrInitData.hco_org || '',
                    after_value: this.initial.hco_org || '',
                    final_value: this.formData.hco_org.split(',')[0],
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'hco_id',
                    before_value: this.dcrInitData.hco_id || '',
                    after_value: this.initial.hco_id || '',
                    final_value: this.formData.hco_org.split(',')[1],
                    is_modify_final: 1,
                  });
                } else {
                  this.dcrContent.push({
                    field_name: 'hco_name',
                    before_value: this.dcrInitData.hco_org || '',
                    after_value: '',
                    final_value: this.formData.hco_org.split(',')[0],
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'hco_id',
                    before_value: this.dcrInitData.hco_id || '',
                    after_value: '',
                    final_value: this.formData.hco_org.split(',')[1],
                    is_modify_final: 1,
                  });
                }
              }

              // 处理机构类型名
              if (
                JSON.stringify(this.formData.type_name_clump) !==
                JSON.stringify(this.initial.type_name_clump)
              ) {
                this.dcrContent.push({
                  field_name: 'hco_type3',
                  before_value: this.dcrInitData.type_name_clump[0]
                    ? `${this.dcrInitData.type_name_clump[0].split(',')[1]}`
                    : '',
                  after_value: this.initial.type_name_clump[0]
                    ? `${this.initial.type_name_clump[0].split(',')[1]}`
                    : '',
                  final_value: this.formData.type_name_clump[0]
                    ? `${this.formData.type_name_clump[0].split(',')[1]}`
                    : '',
                  is_modify_final: 1,
                });
                this.dcrContent.push({
                  field_name: 'hco_type_name3',
                  before_value: this.dcrInitData.type_name_clump[0]
                    ? `${this.dcrInitData.type_name_clump[0].split(',')[0]}`
                    : '',
                  after_value: this.initial.type_name_clump[0]
                    ? `${this.initial.type_name_clump[0].split(',')[0]}`
                    : '',
                  final_value: this.formData.type_name_clump[0]
                    ? `${this.formData.type_name_clump[0].split(',')[0]}`
                    : '',
                  is_modify_final: 1,
                });

                this.dcrContent.push({
                  field_name: 'hco_type4',
                  before_value: this.dcrInitData.type_name_clump[1]
                    ? `${this.dcrInitData.type_name_clump[1].split(',')[1]}`
                    : '',
                  after_value: this.initial.type_name_clump[1]
                    ? `${this.initial.type_name_clump[1].split(',')[1]}`
                    : '',
                  final_value: this.formData.type_name_clump[1]
                    ? `${this.formData.type_name_clump[1].split(',')[1]}`
                    : '',
                  is_modify_final: 1,
                });
                this.dcrContent.push({
                  field_name: 'hco_type_name4',
                  before_value: this.dcrInitData.type_name_clump[1]
                    ? `${this.dcrInitData.type_name_clump[1].split(',')[0]}`
                    : '',
                  after_value: this.initial.type_name_clump[1]
                    ? `${this.initial.type_name_clump[1].split(',')[0]}`
                    : '',
                  final_value: this.formData.type_name_clump[1]
                    ? `${this.formData.type_name_clump[1].split(',')[0]}`
                    : '',
                  is_modify_final: 1,
                });

                this.dcrContent.push({
                  field_name: 'hco_type5',
                  before_value: this.dcrInitData.type_name_clump[2]
                    ? `${this.dcrInitData.type_name_clump[2].split(',')[1]}`
                    : '',
                  after_value: this.initial.type_name_clump[2]
                    ? `${this.initial.type_name_clump[2].split(',')[1]}`
                    : '',
                  final_value: this.formData.type_name_clump[2]
                    ? `${this.formData.type_name_clump[2].split(',')[1]}`
                    : '',
                  is_modify_final: 1,
                });
                this.dcrContent.push({
                  field_name: 'hco_type_name5',
                  before_value: this.dcrInitData.type_name_clump[2]
                    ? `${this.dcrInitData.type_name_clump[2].split(',')[0]}`
                    : '',
                  after_value: this.initial.type_name_clump[2]
                    ? `${this.initial.type_name_clump[2].split(',')[0]}`
                    : '',
                  final_value: this.formData.type_name_clump[2]
                    ? `${this.formData.type_name_clump[2].split(',')[0]}`
                    : '',
                  is_modify_final: 1,
                });
              }
              // 处理别名
              if (
                this.formData.alias_name?.length > 0 &&
                JSON.stringify(this.formData.alias_name) !== JSON.stringify(this.initial.alias_name)
              ) {
                if (this.initial.alias_name.length > 0) {
                  const oldval = this.initial.alias_name.toString();
                  const newval = this.formData.alias_name.toString();
                  this.dcrContent.push({
                    field_name: 'alias_name',
                    before_value: this.dcrInitData.alias_name.length
                      ? this.dcrInitData.alias_name.join(',')
                      : '',
                    after_value: oldval,
                    final_value: newval,
                    is_modify_final: 1,
                  });
                } else {
                  const newval = this.formData.alias_name.toString();
                  this.dcrContent.push({
                    field_name: 'alias_name',
                    before_value: this.dcrInitData.alias_name.length
                      ? this.dcrInitData.alias_name.join(',')
                      : '',
                    after_value: '',
                    final_value: newval,
                    is_modify_final: 1,
                  });
                }
              }
              // 处理曾用名
              if (
                this.formData.former_name?.length > 0 &&
                JSON.stringify(this.formData.former_name) !==
                  JSON.stringify(this.initial.former_name)
              ) {
                if (this.initial.former_name.length > 0) {
                  const oldval = this.initial.former_name.toString();
                  const newval = this.formData.former_name.toString();
                  this.dcrContent.push({
                    field_name: 'former_name',
                    before_value: this.dcrInitData.former_name.length
                      ? this.dcrInitData.former_name.join(',')
                      : '',
                    after_value: oldval,
                    final_value: newval,
                    is_modify_final: 1,
                  });
                } else {
                  const newval = this.formData.former_name.toString();
                  this.dcrContent.push({
                    field_name: 'former_name',
                    before_value: this.dcrInitData.former_name.length
                      ? this.dcrInitData.former_name.join(',')
                      : '',
                    after_value: '',
                    final_value: newval,
                    is_modify_final: 1,
                  });
                }
              }
              // 处理医生标准科室
              if (this.$route.query.source === 'doctor') {
                if (
                  this.formData.standard_dept_all !== [] &&
                  JSON.stringify(this.formData.standard_dept_all) !==
                    JSON.stringify(this.initial.standard_dept_all)
                ) {
                  this.dcrContent.push({
                    field_name: 'standard_dept1',
                    before_value: this.dcrInitData.standard_dept_all[0]
                      ? this.dcrInitData.standard_dept_all[0].split(',')[0]
                      : '',
                    after_value: this.initial.standard_dept_all[0]
                      ? this.initial.standard_dept_all[0].split(',')[0]
                      : '',
                    final_value: this.formData.standard_dept_all[0]
                      ? this.formData.standard_dept_all[0].split(',')[0]
                      : '',
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'standard_dept_name1',
                    before_value: this.dcrInitData.standard_dept_all[0]
                      ? this.dcrInitData.standard_dept_all[0].split(',')[1]
                      : '',
                    after_value: this.initial.standard_dept_all[0]
                      ? this.initial.standard_dept_all[0].split(',')[1]
                      : '',
                    final_value: this.formData.standard_dept_all[0]
                      ? this.formData.standard_dept_all[0].split(',')[1]
                      : '',
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'standard_dept2',
                    before_value: this.dcrInitData.standard_dept_all[1]
                      ? this.dcrInitData.standard_dept_all[1].split(',')[0]
                      : '',
                    after_value: this.initial.standard_dept_all[1]
                      ? this.initial.standard_dept_all[1].split(',')[0]
                      : '',
                    final_value: this.formData.standard_dept_all[1]
                      ? this.formData.standard_dept_all[1].split(',')[0]
                      : '',
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'standard_dept_name2',
                    before_value: this.dcrInitData.standard_dept_all[1]
                      ? this.dcrInitData.standard_dept_all[1].split(',')[1]
                      : '',
                    after_value: this.initial.standard_dept_all[1]
                      ? this.initial.standard_dept_all[1].split(',')[1]
                      : '',
                    final_value: this.formData.standard_dept_all[1]
                      ? this.formData.standard_dept_all[1].split(',')[1]
                      : '',
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'standard_dept3',
                    before_value: this.dcrInitData.standard_dept_all[2]
                      ? this.dcrInitData.standard_dept_all[2].split(',')[0]
                      : '',
                    after_value: this.initial.standard_dept_all[2]
                      ? this.initial.standard_dept_all[2].split(',')[0]
                      : '',
                    final_value: this.formData.standard_dept_all[2]
                      ? this.formData.standard_dept_all[2].split(',')[0]
                      : '',
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'standard_dept_name3',
                    before_value: this.dcrInitData.standard_dept_all[2]
                      ? this.dcrInitData.standard_dept_all[2].split(',')[1]
                      : '',
                    after_value: this.initial.standard_dept_all[2]
                      ? this.initial.standard_dept_all[2].split(',')[1]
                      : '',
                    final_value: this.formData.standard_dept_all[2]
                      ? this.formData.standard_dept_all[2].split(',')[1]
                      : '',
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'standard_dept_id',
                    before_value: this.dcrInitData.standard_dept_id
                      ? this.dcrInitData.standard_dept_id
                      : '',
                    after_value: this.initial.standard_dept_id ? this.initial.standard_dept_id : '',
                    final_value: this.formData.standard_dept_all[
                      this.formData.standard_dept_all.length - 1
                    ].split(',')[0]
                      ? this.formData.standard_dept_all[
                          this.formData.standard_dept_all.length - 1
                        ].split(',')[0]
                      : '',
                    is_modify_final: 1,
                  });
                  this.dcrContent.push({
                    field_name: 'standard_dept_name',
                    before_value: this.dcrInitData.standard_dept_name
                      ? this.dcrInitData.standard_dept_name
                      : '',
                    after_value: this.initial.standard_dept_name
                      ? this.initial.standard_dept_name
                      : '',
                    final_value: this.formData.standard_dept_all[
                      this.formData.standard_dept_all.length - 1
                    ].split(',')[1]
                      ? this.formData.standard_dept_all[
                          this.formData.standard_dept_all.length - 1
                        ].split(',')[1]
                      : '',
                    is_modify_final: 1,
                  });
                }
              }

              for (let i = 0; i < this.editLicenseItem.length; i++) {
                if (this.editLicenseItem[i].edit) {
                  this.$message.error('证照信息存在编辑未保存的数据，请确认是否需要保存后再提交');
                  return false;
                }
              }
              // 处理证照信息
              const formatLicense = this.newLicenseList.map(item => {
                return {
                  name: item.name,
                  license_code: item.license_code,
                  certification_date: item.certification_date,
                  expire_date: item.expire_date,
                  issuing_authority: item.issuing_authority,
                  id: item.id,
                };
              });
              if (JSON.stringify(this.dcrUpdaeLicense) !== JSON.stringify(formatLicense)) {
                this.dcrContent.push({
                  field_name: 'license_info',
                  before_value: JSON.stringify(this.dcrInitLicense),
                  after_value: JSON.stringify(this.dcrUpdaeLicense),
                  final_value: JSON.stringify(formatLicense),
                  is_modify_final: 1,
                });
              }

              console.log('dcr编辑提交', this.dcrContent);
              if (this.dcrContent.length < 1) {
                this.$message.error('您未修改任何数据');
                return false;
              }
              const req = {
                data_type: this.$route.query.source === 'doctor' ? 't_hcp' : 't_hco',
                id: this.$route.query.dcrId,
                data: this.dcrContent,
              };
              ModifyDcrDetails(req)
                .then(res => {
                  console.log(res);
                  this.$message.success('保存成功');
                  this.$router.go(-1);
                })
                .catch(err => {
                  this.$message.error(err.message);
                });
            }
          } else {
            return false;
          }
        });
      },
      2000,
      {
        leading: true,
        trailing: false,
      },
    ),
    gotoMap() {
      window.open('https://lbs.qq.com/getPoint/');
    },
    handleBack() {
      // this.$router.go(-1);
      if (this.$route.query.form) {
        this.$route.query.type = 'preview';
        this.init();
      } else {
        this.$router.push({
          name: 'data-list',
          query: { type: this.$route.query.source },
        });
      }
      store.commit('user/setBreadcrumb', []);
    },
    handleEdit() {
      this.$route.query.type = 'edit';
      this.$route.query.form = 'detail';
      this.$store.state.user.breadcrumb = this.$store.state.user.breadcrumb.slice(0, 1);
      this.init();
    },
    handleTimeLine(val) {
      this.$router.push({ name: 'action-log', query: { id: val.id } });
    },
    handleMore() {
      this.$router.push({ name: 'action-log' });
    },
    handleDel() {
      this.dialogFormVisible = true;
    },
    handleDelData() {
      if (!this.reason) {
        this.$message.error('请填写删除原因');
        return false;
      }
      if (!this.isOpenDcr || (this.isOpenDcr && this.role === 1 && this.dcrRole === 2)) {
        const req = {
          obj_name: this.$route.query.source !== 'doctor' ? 't_hco' : 't_hcp',
          data_id: this.$route.query.id,
          reason: this.reason,
        };
        DeleteMainDataDetail(req)
          .then(() => {
            this.$message.success('删除成功');
            this.dialogFormVisible = false;
            this.handleBack();
          })
          .catch(() => {
            this.$message.error('删除失败');
          });
      } else {
        const req = {
          data_id: this.$route.query.id,
          dcr_type: 3,
          data_code:
            this.$route.query.source !== 'doctor' ? this.formData.hco_code : this.formData.hcp_code,
          dcr_data_type: this.$route.query.source !== 'doctor' ? 2 : 1,
          name: this.formData.name,
          data_detail_type: REQUEST_TITLE_TYPE[this.$route.query.source],
          // content:this.content
          delete_reason: this.reason,
        };
        if (this.$route.query.source === 'doctor') {
          req.hcp_hco_name = this.formData.hco_org.split(',')[0] || '';
          req.hcp_city_name = this.formData.nhc_location;
          req.hcp_dept_name = this.formData.standard_dept_all[
            this.formData.standard_dept_all.length - 1
          ]?.split(',')[1];
        }
        CreateDcr(req)
          .then(() => {
            this.$message.success('删除请求提交成功');
            this.dialogFormVisible = false;
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      }
      // const req = {
      //   obj_name: this.$route.query.source !== 'doctor' ? 't_hco' : 't_hcp',
      //   data_id: this.$route.query.id,
      //   reason: this.reason,
      // };
      // DeleteMainDataDetail(req)
      //   .then(() => {
      //     this.$message.success('删除成功');
      //     this.dialogFormVisible = false;
      //     this.handleBack();
      //   })
      //   .catch(() => {
      //     this.$message.error('删除失败');
      //   });
      // const req = {
      //   data_id: this.$route.query.id,
      //   dcr_type: 3,
      //   data_code:
      //     this.$route.query.source !== 'doctor' ? this.formData.hco_code : this.formData.hcp_code,
      //   dcr_data_type: this.$route.query.source !== 'doctor' ? 2 : 1,
      //   name: this.formData.name,
      //   data_detail_type: REQUEST_TITLE_TYPE[this.$route.query.source],
      //   // content:this.content
      //   delete_reason: this.reason,
      // };
      // if (this.$route.query.source === 'doctor') {
      //   req.hcp_hco_name = this.formData.hco_org.split(',')[0] || '';
      //   req.hcp_city_name = this.formData.nhc_location;
      //   req.hcp_dept_name = this.formData.standard_dept_all[
      //     this.formData.standard_dept_all.length - 1
      //   ]?.split(',')[1];
      // }
      // CreateDcr(req)
      //   .then(() => {
      //     this.$message.success('删除请求提交成功');
      //     this.dialogFormVisible = false;
      //   })
      //   .catch(err => {
      //     this.$message.error(err.message);
      //   });
    },
    handleGoRepeat(val) {
      const href = this.$router.resolve({
        path: '/dashboard/data-detail',
        query: {
          id: val.id,
          name: val.name,
          type: 'preview',
          source: this.$route.query.source,
        },
      });
      window.open(href.href, '_blank');
    },

    handleSub() {
      this.repeatVisible2 = false;
      let req = {};
      if (this.$route.query.type === 'new') {
        req = {
          obj_name: this.$route.query.source !== 'doctor' ? 't_hco' : 't_hcp',
          content: this.creatData,
          alter_time: +new Date(),
          data_confirm: 1,
        };
        CreateMainDataDetail(req)
          .then(res => {
            if (res.code === '0') {
              this.$message.success('新建成功');
              this.handleBack();
            }
            if (res.code === '1') {
              res.conflict_list.slice(0, 10).forEach(item => {
                this.repeatData.push(JSON.parse(item));
              });
              this.repeatVisible = true;
            }
            if (res.code === '2') {
              res.conflict_list.slice(0, 10).forEach(item => {
                this.repeatData.push(JSON.parse(item));
              });
              this.repeatVisible2 = true;
            }
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      } else if (this.$route.query.type === 'preview') {
        req = {
          data_id: this.$route.query.id,
          obj_name: this.$route.query.source !== 'doctor' ? 't_hco' : 't_hcp',
          content: this.detailEdit,
          alter_time: +new Date(),
          data_confirm: 1,
        };
        EditMainDataDetail(req)
          .then(res => {
            if (res.code === '0') {
              this.$message.success('编辑成功');
              this.handleBack();
            }
            if (res.code === '1') {
              res.conflict_list.slice(0, 10).forEach(item => {
                this.repeatData.push(JSON.parse(item));
              });
              this.repeatVisible = true;
              this.detailEdit = [];
            }
            if (res.code === '2') {
              res.conflict_list.slice(0, 10).forEach(item => {
                this.repeatData.push(JSON.parse(item));
              });
              this.repeatVisible2 = true;
            }
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      } else {
        req = {
          data_id: this.$route.query.id,
          obj_name: this.$route.query.source !== 'doctor' ? 't_hco' : 't_hcp',
          content: this.content,
          alter_time: +new Date(),
          data_confirm: 1,
        };
        EditMainDataDetail(req)
          .then(res => {
            if (res.code === '0') {
              this.$message.success('编辑成功');
              this.handleBack();
            }
            if (res.code === '1') {
              res.conflict_list.slice(0, 10).forEach(item => {
                this.repeatData.push(JSON.parse(item));
              });
              this.repeatVisible = true;
              this.content = [];
            }
            if (res.code === '2') {
              res.conflict_list.slice(0, 10).forEach(item => {
                this.repeatData.push(JSON.parse(item));
              });
              this.repeatVisible2 = true;
            }
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      }
    },
    getAuthorization,
    handleCancel() {
      this.repeatVisible = false;
      this.repeatVisible2 = false;
      this.repeatData = [];
    },
    handleSuperiorOrg(val) {
      const type = {
        医院: 'hospital',
        基层医疗卫生机构: 'hospital-ji',
        专业公共卫生机构: 'hospital-pro',
        其他医疗卫生机构: 'hospital-other',
        经销商: 'dealer',
        药店: 'shop',
        生产企业: 'manufacturer',
        医疗器械: 'medical-machine',
        其他分类: 'other',
        医生: 'doctor',
        中转区机构: 'mid-org',
        中转区医疗专业人士: 'mid-doc',
      };
      if (this.$route.query.source !== 'doctor') {
        const req = {
          obj_name: 't_hco',
          data_id: this.formData.parent_id,
          fields: ['hco_type'],
        };
        GetMainDataDetail(req).then(res => {
          console.log('获取到的上级机构类型', res);
          const source = JSON.parse(res.data_detail).hco_type;
          const href = this.$router.resolve({
            path: '/dashboard/data-detail',
            query: {
              id: this.formData.parent_id,
              name: val,
              type: 'preview',
              source: type[source],
            },
          });
          window.open(href.href, '_blank');
        });
      } else {
        const req = {
          obj_name: 't_hco',
          data_id: this.formData.hco_id,
          fields: ['hco_type'],
        };
        GetMainDataDetail(req).then(res => {
          console.log('获取到的上级机构类型', res);
          const source = JSON.parse(res.data_detail).hco_type;
          const href = this.$router.resolve({
            path: '/dashboard/data-detail',
            query: {
              id: this.formData.hco_id,
              name: val,
              type: 'preview',
              source: type[source],
            },
          });
          window.open(href.href, '_blank');
        });
      }
    },
    handleNewDoc() {
      const routerData = this.$router.resolve({
        path: '/dashboard/data-detail',
        query: { type: 'new', source: 'doctor' },
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },
    handleToDoctorImage() {
      this.g_tourl(
        `/dashboard/doctor-image-detail?id=${this.$route.query.id}&daas_code=${this.formData.daas_code}`,
      );
    },

    restoreTags() {
      this.tagData = this.tagDataOld;
      console.log(this.tagData);
      this.showBtnZK = false;
    },
    cuttingTags() {
      let idx = 0;
      let count = 0;
      let iIndex0 = 0;
      if (this.$route.query.type === 'preview') {
        this.$nextTick(() => {
          // 获取所有标签节点
          const childTags = document.querySelector('#info-tags').childNodes;
          childTags?.forEach((item, index) => {
            // 把第一个标签的左偏移保存，且如果第一个没有值，取下一个
            iIndex0 = iIndex0 ? iIndex0 : item.offsetLeft;
            // 判断有几个标签在最左边，iIndex0判断第一个要不要作为标准来判断
            if (iIndex0 && item.offsetLeft === iIndex0) {
              count += 1;
              if (count === 3) {
                idx = index - 1;
                this.showDivZK = true;
                this.showBtnZK = true;
                this.show = true;
              }
            }
          });
          // 如果idx有值了，就对数组进行切割
          if (idx > 0) {
            this.tagData = [...new Set(this.tagDataOld)].slice(0, idx);
          } else {
            this.showDivZK = false;
            this.showBtnZK = false;
            this.show = false;
          }
        });
      }
    },
    // 历史记录版本选择
    handleSelectionChange(val) {
      console.log(val);
      const arr = [];
      val.forEach(item => {
        arr.push(item.version);
      });
      this.selectHistoryVersion = arr;
    },
    // 点击导出按钮
    handleExport() {
      if (this.selectHistoryVersion.length) {
        this.showDataExportDialog = true;
      } else {
        this.$message.error('请选择要导出的数据');
      }
    },
    handleCloseDialog() {
      this.showDataExportDialog = false;
      this.checkedFields = [];
      this.exportRightFields = [];
      // 重置滚动条
      this.$nextTick(() => {
        this.$refs.exportFieldTransfer.$children[0].$children[1].$el.scrollTop = 0;
        this.$refs.exportFieldTransfer.$children[0].checked = [];
      });
    },
    // 移动后剩下的数据
    changeExportField(value, direction) {
      console.log(value, direction);
      if (direction === 'left') {
        this.checkedFields = [];
      }
    },
    // 右边选中数据
    exportCheckedFields(val) {
      console.log(val);
      this.checkedFields = val;
    },
    // 上移
    handleExportFieldUp() {
      if (this.checkedFields.length === 1) {
        const idx = this.exportRightFields.findIndex(item => item === this.checkedFields[0]);
        // console.log(idx);
        if (idx === 0) {
          this.$message.warning('没有上移空间了');
          return;
        }
        // 取出上移字段
        const changeItem = JSON.parse(JSON.stringify(this.exportRightFields[idx - 1]));
        // 删除字段
        this.exportRightFields.splice(idx - 1, 1);
        // 插入字段
        this.exportRightFields.splice(idx, 0, changeItem);
      } else {
        this.$message.error('一次只能移动一个字段');
      }
    },
    // 下移
    handleExportFieldDown() {
      if (this.checkedFields.length === 1) {
        const idx = this.exportRightFields.findIndex(item => item === this.checkedFields[0]);
        // console.log(idx);
        if (idx === this.exportRightFields.length - 1) {
          this.$message.warning('没有下移空间了');
          return;
        }
        // 取出下移字段
        const changeItem = JSON.parse(JSON.stringify(this.exportRightFields[idx]));
        // 删除字段
        this.exportRightFields.splice(idx, 1);
        // 插入字段
        this.exportRightFields.splice(idx + 1, 0, changeItem);
      } else {
        this.$message.error('一次只能移动一个字段');
      }
    },
    // 跳转到操作日志
    handleToLog(val) {
      console.log(val);
      this.g_tourl(`/dashboard/action-log?id=${val.log_id}`);
    },
    handleExprotVersion() {
      const loading = this.$loading({
        lock: true,
        text: '正在导出请稍等',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const req = {
        main_data: this.$route.query.source === 'doctor' ? 't_hcp' : 't_hco',
        data_id: this.$route.query.id,
        record_versions: this.selectHistoryVersion,
        fields: this.exportRightFields,
      };
      req.fields.push('版本');
      console.log(req);
      GetDataHistory(req)
        .then(res => {
          console.log('要导出的数据', res);
          const dep = res.datas.map(item => {
            return { 版本: item.record_version, ...JSON.parse(item.data) };
          });
          console.log('格式化后的数据', dep);
          const exportData = {};
          const obj = dep[0];
          res.header.forEach(item => {
            for (const val in obj) {
              if (item === val) {
                exportData[val] = obj[val];
              }
            }
          });
          console.log('排序', exportData);
          dep.shift();
          dep.unshift(exportData);
          jsonToXlsx([{ sheet: dep }], `${this.$route.query.name}历史版本数据`);
          loading.close();
          this.showDataExportDialog = false;
        })
        .catch(err => {
          this.$message.error(err.message);
          loading.close();
        });
    },
    handleToHistory(val) {
      console.log(val);
      this.drawer = false;
      this.g_tourl(
        `/dashboard/data-detail?id=${this.$route.query.id}&type=history&source=${this.$route.query.source}&name=${val.data_name}&version=${val.version}`,
      );
    },
    rowKey(row) {
      return row.log_id;
    },
    // 回到最新版本
    handleToCurrentVersion() {
      this.g_tourl(
        `/dashboard/data-detail?id=${this.$route.query.id}&type=preview&source=${this.$route.query.source}&name=${this.$route.query.name}`,
      );
    },
    // 右边弹出历史版本窗口
    HandleDrawer() {
      this.drawer = true;
      const request = {
        data_id: this.$route.query.id,
        index: 1,
        page_size: 99999,
      };
      GetDataOperationLogList(request)
        .then(res => {
          if (res.data_operation_log_list.length) {
            this.historyData = res.data_operation_log_list.map(item => {
              return {
                ...item,
                ope_time: dateFormat(new Date(item.ope_time * 1000), 'YYYY-MM-dd HH:mm:SS'),
              };
            });
          } else {
            this.historyData = [
              {
                content: '',
                data_name: this.$route.query.name,
                log_id: '',
                ope_time: this.formData.create_time,
                ope_type: '',
                uin: '',
                user_name: this.formData.create_user,
                version: this.formData.record_version,
              },
            ];
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import './data-detail.scss';
.tag {
  display: flex;
  flex-wrap: wrap;
  // align-items: center;
  // width: 60%;
  .tags {
    margin: 0 5px 5px 0;
  }
  .button-new-tag {
    margin-bottom: 5px;
  }
}
::v-deep .input-new-tag {
  width: 200px;
}
// ::v-deep .el-form-item__content {
//   display: flex;
//   flex-wrap: wrap;
//   margin-left: 0 !important;
// }
.el-icon-location-outline {
  position: absolute;
  top: 15px;
  right: -20px;
  color: #1f2329 !important;
}
.data-name {
  display: flex;
  font-size: 14px;
  color: #1c2028;
  line-height: 22px;
}
.data-name-content {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  margin-top: 10px;
  color: rgb(64, 158, 255);
  cursor: pointer;
  overflow-y: auto;
}
.content-link {
  margin-bottom: 10px;
}
.loading {
  width: 100%;
  height: 1200px;
  background: #fff;
}
.datadetail-head-tit {
  // margin-left: 24px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}
#info-tags {
  // flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 24px;
  // max-width: 800px;
  .item {
    padding: 0;
    margin-right: 12px;
    height: 24px;
    margin-bottom: 10px;
    .tag_btn_show {
      ::v-deep span {
        height: 24px;
        line-height: 24px;
      }
    }
    .tag_contents {
      display: inline-block;
      max-width: 260px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 400;
      .el-button {
        font-weight: 400;
        color: #1d2129;
      }
    }
    ::v-deep .el-tooltip {
      display: inline-block;
      max-width: 260px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        display: inline-block;
        max-width: 260px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    ::v-deep .el-button {
      background: #e1eefa;
      height: 24px;
      line-height: 24px;
      display: flex;
      align-items: center;
    }
    ::v-deep.el-popover__reference-wrapper {
      height: 24px;
    }
  }
}
.detail-item {
  display: flex;
  align-items: center;
  height: 22px;
  margin-bottom: 4px;
  .title {
    font-size: 14px;
    line-height: 22px;
    color: #667082;
  }
  .data {
    font-size: 14px;
    line-height: 22px;
    color: #1c2028;
  }
}
.expansion,
.up {
  font-size: 14px;
  color: #0069fa;
  cursor: pointer;
}
.dialog-tit {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  i {
    font-size: 24px;
    color: #fa9600;
  }
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1c2028;
    margin-left: 10px;
  }
}
</style>
