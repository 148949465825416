export default [
  {
    title: '基础信息',
    itemList: [
      {
        name: '机构名称',
        property: 'name',
        data: 'name',
        edit: false,
        editType: '',
        required: true,
      },
      {
        name: '机构编码',
        property: 'hco_code',
        data: 'hco_code',
        edit: false,
        editType: '',
        // required: true,
        disable: true,
      },
      {
        name: '二级分类',
        property: 'hco_type',
        data: '药店',
        edit: false,
        editType: 'input',
        disable: true,
        children: 'type_name_clump',
        required: true,
      },
      {
        name: '类型细分',
        property: 'type_name_clump',
        data: 'type_name',
        edit: true,
        editType: 'orgCascade',
        parent: 'hco_type',
        // required: true,
      },
      {
        name: '上级机构名称',
        property: 'parent_org',
        data: 'parent_org',
        edit: true,
        editType: 'lenovo',
        showType: 'link',
      },
      // {
      //   name: '是否医保',
      //   property: 'is_medical_insurance',
      //   data: 'is_medical_insurance',
      //   edit: true,
      //   editType: 'select',
      // },
      {
        name: '机构简介',
        property: 'org_description',
        data: 'org_description',
        edit: true,
        editType: 'textarea',
      },
    ],
  },
  {
    title: '医院业务',
    itemList: [
      {
        name: '是否医保',
        property: 'is_medical_insurance',
        data: 'is_medical_insurance',
        edit: true,
        editType: 'select',
      },
    ],
  },
  {
    title: '别名',
    itemList: [
      {
        name: '曾用名',
        property: 'former_name',
        data: [],
        edit: false,
        editType: 'formerNameTag',
      },
      { name: '英文名', property: 'en_name', data: 'en_name', edit: true, editType: 'input' },
      { name: '别名', property: 'alias_name', data: ['a', 'b'], edit: false, editType: 'tag' },
    ],
  },
  {
    title: '地理位置',
    itemList: [
      {
        name: '行政区划',
        property: 'admin_division',
        data: 'admin_division',
        edit: false,
        editType: 'cascade',
        required: true,
      },
      {
        name: '地址',
        property: 'address',
        data: 'address',
        edit: true,
        editType: 'input',
        required: true,
      },
      {
        name: '经纬度',
        property: 'position',
        data: 'position',
        edit: false,
        editType: '',
        extra: 'map',
      },
      { name: '邮编', property: 'postcode', data: 'postcode', edit: true, editType: 'input' },
      // { name: '主页', property: 'website', data: 'website', edit: true, editType: 'input' },
      // { name: '电话', property: 'phone1', data: 'phone1', edit: false, editType: '' },
      // { name: '邮箱', property: 'email1', data: 'email1', edit: false, editType: '' },
      // { name: '微信公众号', property: 'wx_gzh1', data: 'wx_gzh1', edit: false, editType: '' },
    ],
  },
  {
    title: '联系方式',
    itemList: [
      { name: '主页', property: 'website', data: 'website', edit: true, editType: 'input' },
      { name: '电话', property: 'phone1', data: 'phone1', edit: false, editType: '' },
      { name: '邮箱', property: 'email1', data: 'email1', edit: false, editType: '' },
      { name: '微信公众号', property: 'wx_gzh1', data: 'wx_gzh1', edit: false, editType: '' },
    ],
  },
  {
    title: '经营信息',
    itemList: [
      {
        name: '注册号',
        property: 'registration_code',
        data: 'registration_code',
        edit: false,
        editType: '',
      },
      {
        name: '注册地址',
        property: 'registered_address',
        data: 'registered_address',
        edit: true,
        editType: 'input',
      },
      {
        name: '注册资金',
        property: 'registered_capital',
        data: 'registered_capital',
        edit: true,
        editType: 'input',
      },
      {
        name: '组织机构代码',
        property: 'organization_code',
        data: 'organization_code',
        edit: false,
        editType: '',
        // required: true,
      },
      {
        name: '经营方式',
        property: 'operation_mode',
        data: 'operation_mode',
        edit: true,
        editType: 'input',
      },
      {
        name: '经营范围',
        property: 'business_scope',
        data: 'business_scope',
        edit: true,
        editType: 'input',
      },
      {
        name: '所属行业',
        property: 'business_type',
        data: 'business_type',
        edit: true,
        editType: 'input',
      },
      {
        name: '经营状态',
        property: 'business_status',
        data: 'business_status',
        edit: true,
        editType: 'select',
        // required: true,
      },
      {
        name: '公司类型',
        property: 'company_type',
        data: 'company_type',
        edit: true,
        editType: 'input',
      },
      {
        name: '统一社会信用代码',
        property: 'unified_social_credit_code',
        data: 'unified_social_credit_code',
        edit: false,
        editType: '',
      },
      {
        name: '纳税人识别号',
        property: 'taxpayer_identification_code',
        data: 'taxpayer_identification_code',
        edit: false,
        editType: '',
      },
      {
        name: '成立日期',
        property: 'establishment_date',
        data: 'establishment_date',
        edit: true,
        editType: 'date',
      },
    ],
  },
  {
    title: '证照信息',
    itemList: [],
  },
  // {
  //   title: '部门归一',
  //   itemList: [
  //     {
  //       name: '未名企鹅编码',
  //       property: 'wmqe_number',
  //       data: 'wmqe_number',
  //       edit: false,
  //       editType: '',
  //     },
  //   ],
  // },
  {
    title: '清洗备注',
    itemList: [
      {
        name: '是否存疑',
        property: 'in_doubt',
        data: 'in_doubt',
        edit: false,
        editType: 'select',
      },
      {
        name: '存疑原因',
        property: 'doubt_reason',
        data: 'doubt_reason',
        edit: false,
        editType: '',
      },
      { name: '数据来源', property: 'source', data: 'source', edit: false, editType: 'select' },
      { name: '备注评论', property: 'comment', data: 'comment', edit: true, editType: 'input' },
      { name: '质量负责人', property: 'qa_persons', data: 'qa_persons', edit: false, editType: '' },
    ],
  },
  {
    title: '系统字段',
    itemList: [
      {
        name: '版本',
        property: 'record_version',
        data: 'record_version',
        edit: false,
        editType: '',
      },
      { name: '创建人', property: 'create_user', data: 'create_user', edit: false, editType: '' },
      { name: '创建时间', property: 'create_time', data: 'create_time', edit: false, editType: '' },
      { name: '更新人', property: 'update_user', data: 'update_user', edit: false, editType: '' },
      { name: '更新时间', property: 'update_time', data: 'update_time', edit: false, editType: '' },
    ],
  },
];
