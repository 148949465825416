export default [
  {
    title: '姓名',
    width: 300,
    property: 'name',
    search: 'input',
    sort: false,
    // edit: false,
  },
  {
    title: '姓名拼音',
    width: 300,
    property: 'pinyin',
    search: 'input',
    sort: false,
    // edit: true,
  },
  {
    title: '性别',
    width: 300,
    property: 'gender',
    search: 'select',
    sort: false,
    // edit: true,
  },
  {
    title: '所属机构名称',
    width: 300,
    property: 'hco_name',
    search: 'input',
    sort: false,
    // edit: false,
  },
  {
    title: 'HCP类型',
    width: 300,
    property: 'hcp_type',
    search: 'select',
    sort: false,
    // edit: true,
  },
  {
    title: 'HCP状态',
    width: 300,
    property: 'hcp_status',
    search: 'select',
    sort: false,
    // edit: true,
  },
  {
    title: '挂牌科室',
    width: 300,
    property: 'listed_dept_name',
    search: 'input',
    sort: false,
    // edit: true,
  },
  {
    title: '标准科室',
    width: 300,
    property: 'standard_dept_name',
    search: 'cascader',
    sort: false,
    // edit: true,
  },
  {
    title: '专业职称',
    width: 300,
    property: 'professional_title',
    search: 'select',
    sort: false,
    // edit: true,
  },
  {
    title: '医师级别',
    width: 300,
    property: 'nhc_professional_level',
    search: 'input',
    sort: false,
    // edit: true,
  },
  {
    title: '执业类别',
    width: 300,
    property: 'nhc_professional_type',
    search: 'input',
    sort: false,
    // edit: true,
  },
  {
    title: '执业地点',
    width: 300,
    property: 'nhc_location',
    search: 'input',
    sort: false,
    // edit: true,
  },
  {
    title: '执业范围',
    width: 300,
    property: 'nhc_scope',
    search: 'input',
    sort: false,
    // edit: true,
  },
  {
    title: '是否存疑',
    width: 300,
    property: 'in_doubt',
    search: 'select',
    sort: false,
    // edit: false,
  },
  {
    title: '数据来源',
    width: 300,
    property: 'source',
    search: 'select',
    sort: false,
    // edit: false,
  },
  {
    title: '质量负责人',
    width: 300,
    property: 'qa_persons',
    search: 'input',
    sort: false,
    // edit: false,
  },
  {
    title: '创建人',
    width: 300,
    property: 'create_user',
    search: 'input',
    sort: false,
    // edit: false,
  },
  {
    title: '创建时间',
    width: 300,
    property: 'create_time',
    search: 'date',
    sort: true,
    // edit: false,
    format: true,
  },
  {
    title: '更新人',
    width: 300,
    property: 'update_user',
    search: 'input',
    sort: false,
    // edit: false,
  },
  {
    title: '更新时间',
    width: 300,
    property: 'update_time',
    search: 'date',
    sort: true,
    // edit: false,
    format: true,
  },
];
