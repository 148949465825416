export default [
  {
    title: '证书名称',
    property: 'name',
    width: 240,
  },
  {
    title: '证书编号',
    property: 'license_code',
    width: 240,
  },
  {
    title: '认证日期',
    property: 'certification_date',
    width: 240,
  },
  {
    title: '有效期',
    property: 'expire_date',
    width: 240,
  },
  {
    title: '认证机构',
    property: 'issuing_authority',
    width: 240,
  },
];
